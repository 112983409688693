import React, { useEffect, useState } from "react";
import { ImCross } from 'react-icons/im';
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader";
import axios from "axios";
import { ApiUrl, GetId, getToken, removeUserSession } from "../../utils/common";
import { Modal } from "react-bootstrap";

const Portfolio = () => {
    const [show, setShow] = useState(false);
    const togglePopup = () => {
        setIsOpen(!isOpen);
    }
    const [projectBrochure, setProjectBrochure] = useState('');
    const [projectBrochureSubmit, setProjectBrochureSubmit] = useState('');
    // project brochure
    const ProjectBrochure = (e) => {
        setProjectBrochure(URL.createObjectURL(e?.target?.files[0]));
        setProjectBrochureSubmit(e?.target?.files[0])
    }
    const { state } = useLocation()
    const PropertyId = state?.item?.id;
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState([]);
    const [imageData, setImageData] = useState([])
    const [selectedVideo, SetSelectedVideo] = useState('');

    const [videoPreview, setVideoPreview] = useState()
    console.log(videoPreview, 'videoPreview');
    const [mediaType, setMediaType] = useState();
    const [loading, setLoading] = useState(false)

    const [description, setDescription] = useState();
    console.log(description, 'description');
    const [desc_video, setDescription_video] = useState();
    const [desc_pdf, setDescription_pdf] = useState();
    const ImageHandler = (e) => {
        const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(2);
        if (size > 2) {
            toast.error("Please select image size less than 2 MB")
            return;
        }
        if (e?.target?.files) {
            const fileArray = Array.from(e?.target?.files).map((file) => URL.createObjectURL(file))
            const fileImage = Array.from(e?.target?.files).map((file) => file)


            if (fileArray?.length > 10) {
                toast.error("You Can Maximum 10 Images Send")
                return;
            }

            setSelectedImage((prevImages) => prevImages.concat(fileArray))
            setImageData((prevImages) => prevImages.concat(fileImage))
            Array.from(e?.target?.files).map(
                (file) => URL.revokeObjectURL(file)

            )
        }
    }
    const VideoHandler = (e) => {
        const videoSize = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(20);
        if (videoSize > 20) {
            toast.error("Please select Video size less than 20 MB")
            return;
        }
        SetSelectedVideo(URL.createObjectURL(e?.target?.files[0]))
        setVideoPreview(e?.target?.files[0])
    }
    const renderPhotos = (source) => {

        return source.map((photo) => {
            return (
                <>
                    <div className="previewimgmuploader">
                        <img src={photo} key={photo} />

                        <span className="services-add-preview"
                            onClick={() => onRemove(photo)}><ImCross className="cross-icon-service" /></span>
                    </div>
                </>
            )
        })
    }

    // image remove function
    const onRemove = (val) => {
        let arr = [...selectedImage]

        const Index = arr.indexOf(val);
        if (Index > -1) {
            arr.splice(Index, 1);
        }
        setSelectedImage(arr)
    }

    // video remove function
    const OnVideoRemove = (val) => {
        SetSelectedVideo()
    }

    // pdf remove function
    const OnPDFremove = (val) => {
        setProjectBrochure()
    }


    const ValidateFields = () => {
        if (!imageData.length) {
            toast.error("Please Select Images")
            return false;
        }
        if (!description || description.trim() === "") {
            toast.error("Please Enter Image Description")
            return false
        }
        return true;
    }

    // images post API
    const ImageSubmitPortfolio = () => {
        if (!ValidateFields()) return
        imageData?.map((item) => {
            const data = {}
            const formData = new FormData()
            formData.append('media_type', 1)
            formData.append('description', description)
            if (selectedImage) {
                formData.append('media_path', item)
            }
            const option = {
                method: 'POST', headers: {
                    'access-control-allow-origin': '*',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${getToken()}`
                },
                data: formData,
                url: `${ApiUrl()}/auth/user/business-portfolio/${GetId()}`
            };
            axios(option)
                .then(e => {
                    toast.success("Images Added Sucessfully")
                })
                .catch(err => {
                    if (err?.request?.status === 401) {
                        removeUserSession();
                        navigate('/')
                    }
                })
            // navigate('/developerprofile')
        })
    }
    // video api hit
    const VideoSubmit = () => {
        if (!videoPreview) {
            toast.error("Please Select Video")
            return;
        }
        if (!desc_video || desc_video.trim() === "") {
            toast.error("Please Enter Video Description")
            return;
        }
        setShow(true)
        const formData = new FormData();
        formData.append('media_type', 2);
        formData.append('description', desc_video)
        if (selectedVideo) {
            formData.append('media_path', videoPreview);
        }
        const option = {
            method: 'POST', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${getToken()}`
            },
            data: formData,
            url: `${ApiUrl()}/auth/user/business-portfolio/${GetId()}`
        };
        axios(option)
            .then(e => {
                if (e?.status === 200) {
                    setShow(false);
                    toast.success("Video Added Sucessfully");
                    // navigate('/developerprofile')
                }
                console.log(e, 'e185');

            })
            .catch(err => {
                if (err?.request?.status === 401) {
                    removeUserSession();
                    navigate('/')
                }
            })
        // navigate('/otheruserprofile')
    }

    // pdf api hit
    const PdfSubmit = () => {
        if (!projectBrochureSubmit) {
            toast.error("Please Select Brochure")
            return
        }
        if (!desc_pdf || desc_pdf.trim() === "") {
            toast.error("Please Enter Brochure Description")
            return
        }
        const formData = new FormData();
        formData.append('media_type', 3);
        formData.append('description', desc_pdf)
        if (projectBrochure) {
            formData.append('media_path', projectBrochureSubmit);
        }

        const option = {
            method: 'POST', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${getToken()}`
            },
            data: formData,
            url: `${ApiUrl()}/auth/user/business-portfolio/${GetId()}`
        };
        axios(option)
            .then(e => {
                if (e?.status === 200) {
                    toast.success("Brochure Added Sucessfully")
                    navigate('/developerprofile')
                }
            })
            .catch(err => {
                if (err?.request?.status === 401) {
                    removeUserSession();
                    navigate('/')
                }
            })

    }
    return (
        <>
            <div className="overflow-hidden">
                <div className="agent-row margin-re-top">
                    <div className="container">
                        <h1 className="fs-32" style={{ width: '100%', margin: '0 auto', textAlign: 'center', marginBottom: '20px' }}>Upgrade Your Portfolio</h1>
                        {/* responsive content */}
                        <div className="header-r-logout ">
                            <div className="row">
                                <div className="col-sm-4 col-lg-3 margin-r-bottom-20">
                                    <div className="card mb-2">
                                        <div className="card-body ">
                                            <p className="text-heading fs-22 lh-15 mb-4">Browse Gallery Images</p>
                                            <div className="edit-prfl btn-sub">Upload Images
                                                <input type="file" className="input-file-profile" multiple accept="image/*" onChange={(e) => ImageHandler(e)}></input></div>
                                            <p className="fs-12">Photos must be JPEG or PNG and Max Size 2MB</p>
                                            <label htmlFor="description"
                                                className="text-heading fs-22">Description</label>
                                            <textarea rows="3" cols="50"
                                                className="form-control form-control-lg border-0"
                                                id="description" name="description" onChange={(e) => setDescription(e?.target?.value)} value={description} />
                                        </div>
                                    </div>
                                    <div className="finalresult">
                                        {
                                            selectedImage ?
                                                renderPhotos(selectedImage)
                                                : null
                                        }
                                    </div>
                                    <button type="button" className="btn bg-blue text-white hover-saffron btn-sub" onClick={() => ImageSubmitPortfolio()} style={{ width: '110px', marginLeft: '5px', marginTop: '10px' }}>Submit</button>
                                </div>

                                <div className="col-sm-4 col-lg-3 margin-r-bottom-20">
                                    <div className="card mb-2">
                                        <div className="card-body ">
                                            <p className="text-heading fs-22 lh-15 mb-4">Browse Portfolio Video
                                            </p>
                                            <div className="edit-prfl btn-sub">Upload Video
                                                <input type="file" name="name" className="input-file-profile" accept="video/*" onClick={(e) => VideoHandler(e)} ></input></div>

                                            <p className="fs-12">Video must be Max Size
                                                20MB</p>
                                            <label htmlFor="description"
                                                className="fs-22 text-heading">Description</label>
                                            <textarea rows="3" cols="50"
                                                className="form-control form-control-lg border-0"
                                                id="description" name="desc_video" onChange={(e) => setDescription_video(e?.target?.value)} value={desc_video} />
                                        </div>
                                    </div>
                                    <div className="finalresult">
                                        {
                                            selectedVideo ?
                                                <div className="previewimgmuploader">
                                                    <iframe className="w-100 add-border-other" src={selectedVideo} style={{ width: '300px', height: '200px' }}></iframe>
                                                    <span className="services-add-preview" onClick={() => OnVideoRemove(selectedVideo)}><ImCross className="cross-icon-service" /></span>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    <button type="button" className="btn bg-blue text-white hover-saffron btn-sub" onClick={() => VideoSubmit()} style={{ width: '110px', marginLeft: '5px', marginTop: '10px' }}>Submit</button>
                                </div>
                                <div className="col-sm-4 col-lg-3 margin-r-bottom-20">
                                    <div className="card mb-2">
                                        <div className="card-body ">
                                            <p className="text-heading fs-22 lh-15 mb-4">Browse Your Brochure PDF
                                            </p>
                                            <div className="edit-prfl btn-sub">Upload Brochure
                                                <input type="file" name="file" className="input-file-profile" accept="application/pdf" onClick={(e) => ProjectBrochure(e)}  ></input></div>

                                            <p className="fs-12">Brochure must be PDF and in good quality</p>
                                            <label htmlFor="description"
                                                className="text-heading fs-22">Description</label>
                                            <textarea rows="3" cols="50"
                                                className="form-control form-control-lg border-0"
                                                id="description" name="desc_pdf" onChange={(e) => setDescription_pdf(e?.target?.value)} value={desc_pdf} />
                                        </div>
                                    </div>
                                    <div className="finalresult">
                                        {
                                            projectBrochure ?
                                                <div className="previewimgmuploader">
                                                    <iframe src={projectBrochure} alt="image" className="w-100 add-border-other" style={{ width: '250px', height: '200px' }}></iframe>
                                                    <span className="services-add-preview" onClick={() => OnPDFremove(projectBrochure)}><ImCross className="cross-icon-service" /></span>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    <button type="button" className="btn bg-blue text-white hover-saffron btn-sub" onClick={() => PdfSubmit()} style={{ width: '110px', marginLeft: '5px', marginTop: '10px' }}>Submit</button>
                                </div>
                            </div>
                        </div>


                        {/* end */}






                        <div className="col-lg-12 header-user-icon">
                            <div className="card mb-6">
                                <div className="card-body ">
                                    <div className="collapse-tabs mb-10">
                                        <ul className="nav nav-tabs text-uppercase  d-md-inline-flex agent-details-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a href="#uploadimages" className="nav-link active  fs-13"
                                                    data-bs-toggle="tab" role="tab">
                                                    Upload Images
                                                </a>
                                            </li>
                                            <li className="nav-item ml-0">
                                                <a href="#uploadvideo" className="nav-link  fs-13"
                                                    data-bs-toggle="tab" role="tab">
                                                    Upload Video
                                                </a>
                                            </li>
                                            <li className="nav-item ml-0">
                                                <a href="#uploadpdf" className="nav-link  fs-13"
                                                    data-bs-toggle="tab" role="tab">
                                                    Upload Brochure
                                                </a>
                                            </li>
                                        </ul>

                                        <div className="row">
                                            <div className="tab-content shadow-none py-7 px-6 bg-white">
                                                {/* images section */}
                                                <div className="tab-pane tab-pane-parent fade show active" id="uploadimages"
                                                    role="tabpanel">
                                                    <div className="col-md-4">
                                                        <div className="dropzone upload-file text-center py-5">
                                                            <p className="text-heading fs-22 lh-15 mb-4">Browse Gallery Images</p>
                                                            <div className="edit-prfl btn-sub">Upload Images
                                                                <input type="file" className="input-file-profile" multiple accept="image/*" onChange={(e) => ImageHandler(e)}></input></div>
                                                            <p className="fs-12">Photos must be JPEG or PNG and Max Size 2MB</p>
                                                            <label htmlFor="description"
                                                                className="text-heading fs-22">Description</label>
                                                            <textarea rows="3" cols="50"
                                                                className="form-control form-control-lg border-0"
                                                                id="description" name="description" onChange={(e) => setDescription(e?.target?.value)} value={description} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="finalresult">
                                                            {
                                                                selectedImage ?
                                                                    renderPhotos(selectedImage)
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>

                                                    <button type="button" className="btn bg-blue text-white hover-saffron btn-sub" onClick={() => ImageSubmitPortfolio()} style={{ width: '110px', marginLeft: '15px', marginTop: '20px' }}>Submit</button>

                                                </div>
                                                {/* video section */}

                                                <div className="tab-pane tab-pane-parent fade show " id="uploadvideo"
                                                    role="tabpanel">

                                                    <div className="col-lg-4">
                                                        <div className="dropzone upload-file text-center py-5">
                                                            <p className="text-heading fs-22 lh-15 mb-4">Browse Portfolio Video
                                                            </p>
                                                            <div className="edit-prfl btn-sub">Upload Video
                                                                <input type="file" name="name" className="input-file-profile" accept="video/*" onClick={(e) => VideoHandler(e)} ></input></div>

                                                            <p className="fs-12">Video must be Max Size
                                                                20MB</p>
                                                            <label htmlFor="description"
                                                                className="fs-22 text-heading">Description</label>
                                                            <textarea rows="3" cols="50"
                                                                className="form-control form-control-lg border-0"
                                                                id="description" name="desc_video" onChange={(e) => setDescription_video(e?.target?.value)} value={desc_video} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="finalresult">
                                                            {
                                                                selectedVideo ?
                                                                    <div className="previewimgmuploader">
                                                                        <iframe className="w-100 add-border-other" src={selectedVideo} style={{ width: '300px', height: '200px' }}></iframe>
                                                                        <span className="services-add-preview" onClick={() => OnVideoRemove(selectedVideo)}><ImCross className="cross-icon-service" /></span>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>

                                                    <button type="button" className="btn bg-blue text-white hover-saffron btn-sub" onClick={() => VideoSubmit()} style={{ width: '110px', marginLeft: '15px', marginTop: '20px' }}>Submit</button>

                                                </div>
                                                {/* pdf */}


                                                <div className="tab-pane tab-pane-parent fade show " id="uploadpdf"
                                                    role="tabpanel">

                                                    <div className="col-lg-4">

                                                        <div className="dropzone upload-file text-center py-5">

                                                            <p className="text-heading fs-22 lh-15 mb-4">Browse Your Brochure PDF
                                                            </p>
                                                            <div className="edit-prfl btn-sub">Upload Brochure
                                                                <input type="file" name="file" className="input-file-profile" accept="application/pdf" onClick={(e) => ProjectBrochure(e)}  ></input></div>

                                                            <p className="fs-12">Brochure must be PDF and in good quality</p>
                                                            <label htmlFor="description"
                                                                className="text-heading fs-22">Description</label>
                                                            <textarea rows="3" cols="50"
                                                                className="form-control form-control-lg border-0"
                                                                id="description" name="desc_pdf" onChange={(e) => setDescription_pdf(e?.target?.value)} value={desc_pdf} />
                                                        </div>


                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="finalresult">
                                                            {
                                                                projectBrochure ?
                                                                    <div className="previewimgmuploader">
                                                                        <iframe src={projectBrochure} alt="image" className="w-100 add-border-other" style={{ width: '250px', height: '200px' }}></iframe>
                                                                        <span className="services-add-preview" onClick={() => OnPDFremove(projectBrochure)}><ImCross className="cross-icon-service" /></span>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>

                                                    <button type="button" className="btn bg-blue text-white hover-saffron btn-sub" onClick={() => PdfSubmit()} style={{ width: '110px', marginLeft: '15px', marginTop: '20px' }}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <Modal className="modal-add-contact"
                show={show} >
                <Modal.Body>
                    <h3 className="submit-property-modal-heading">Media Uploading…</h3>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Portfolio;
