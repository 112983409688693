import React from 'react'
import SubscriptionComponent from '../Components/Subscription/SubscriptionComponent'
import Header from '../../Components/header/header'

function Subscription() {
  return (
     
    <>
    <Header />
    <SubscriptionComponent />
    </>
  )
}

export default Subscription