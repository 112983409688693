import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader";
import OBSidebar from "../../Components/OBsidebar/obsidebar";
import { ApiUrl, getToken } from "../../utils/common";
import OBHeader from "./obheader";
import moment from "moment";

const OtherInvoice = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [invoiceData, setInvoiceData] = useState();
    console.log(invoiceData, 'invoiceData');

    // modal states
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const InvoiceData = () => {
        setLoading(true)
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${getToken()}`
                
            },
            url: `${ApiUrl()}/auth/subscription/invoice/user/`
        };
        axios(option)
            .then(e => {
                if (e?.data?.data?.length == "0") {
                    setShow(true)
                }
                setLoading(false)
                setInvoiceData(e?.data?.data)
            })
            .catch(err => {
                setLoading(false)
                console.log(err);
            })
    }

    useEffect(() => {
        InvoiceData()
    }, [])

    const ViewInvoice = (item) => {
        window.open(item?.hosted_invoice_url, '_blank');
    }

    const Redirect = () => {
        navigate('/subpackages')
    }
    return (
        <>

            {
                loading ? <Loader /> : null
            }
            <div className="wrapper dashboard-wrapper">
                <div className="d-flex flex-wrap flex-xl-nowrap">

                    <OBSidebar />


                    <div className="page-content">
                        <OBHeader />

                        <main id="content" className="bg-gray-01">
              <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 add-new-invoice">
                <div className="d-flex flex-wrap flex-md-nowrap mb-6">
                  <div className="mr-0 mr-md-auto">
                    <h2 className="mt-5 mb-0 text-heading pl-0 fs-22 lh-15">My Invoices

                    </h2>

                  </div>

                </div>
               {/*  { invoiceData?.length? (
                    <> */}
                <div className="table-responsive">
                  <table className="table table-hover bg-white border rounded-lg">
                    <thead className="thead-sm thead-black">
                      <tr>
                        <th scope="col" className="border-top-0 px-6 pt-5 pb-4">Invoice ID</th>

                        <th scope="col" className="border-top-0 pt-5 pb-4">Invoice Date</th>

                        <th scope="col" className="border-top-0 pt-5 pb-4">Invoice</th>

                      </tr>
                    </thead>
                    <tbody>
                    
                      {
                        invoiceData?.map((item) => {
                          return (
                            <tr className=" shadow-hover-xs-2 bg-hover-white">

                              <td className="align-middle pt-6 pb-4 px-3 table-td">
                                <span className="px-5 text-heading">{item?.number}</span>
                              </td>
                              <td className="align-middle table-td">
                                <span className="px-5 text-heading padding-left-0">{moment(item?.period_start).format('DD-MMM-YYYY')}</span>
                              </td>

                              <td className="align-middle">
                                <a className="btn bg-blue text-white hover-saffron" onClick={() => ViewInvoice(item)}>View Invoice</a>

                              </td>
                            </tr>
                          )
                        })
                      }
                    

                    </tbody>
                  </table>
                </div>
            {/*     </> ):(
                     <> */}
                     <Modal show={show} className="modal-add-contact">
                      <Modal.Body>
                        <h3 className="message-invoice">Subscribe to Plan to get leads</h3>
                      </Modal.Body>
                  <Modal.Footer>
                    <Button className="btn bg-blue text-white hover-saffron" onClick={() => { handleClose(); Redirect() }}>
                      Subscribe
                    </Button>
                  </Modal.Footer>
                </Modal>
               {/*  </>)} */}
              </div>
            </main>
                    </div>
                </div>
            </div>
        </>
    )
}
export default OtherInvoice;