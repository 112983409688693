import React from 'react'
import './ClubMemberPage.css';

function ClubMemberComponent() {
  const members = [
    {
      id: 1,
      name: 'John Doe',
      title: 'President',
      description: 'Leader of the club, coordinating events and ensuring smooth operations.',
      imageUrl: 'https://via.placeholder.com/150',
    },
    {
      id: 2,
      name: 'Jane Smith',
      title: 'Vice President',
      description: 'Assisting the President with planning and decision-making.',
      imageUrl: 'https://via.placeholder.com/150',
    },
    {
      id: 3,
      name: 'Alice Johnson',
      title: 'Secretary',
      description: 'Managing the club’s records, meetings, and communications.',
      imageUrl: 'https://via.placeholder.com/150',
    },
    {
      id: 4,
      name: 'Bob Williams',
      title: 'Treasurer',
      description: 'Overseeing financial aspects and managing club funds responsibly.',
      imageUrl: 'https://via.placeholder.com/150',
    },
    // Add more members as needed
  ];

  return (
    <div className="club-member-container">
      <header className="club-member-header">
        <h1>Club Members</h1>
        <p>Meet our team of dedicated club members</p>
      </header>

      <section className="member-cards-section">
        <div className="member-cards-container">
          {members.map((member) => (
            <div className="member-card" key={member.id}>
              <img src={member.imageUrl} alt={`${member.name}`} className="member-image" />
              <h2 className="member-name">{member.name}</h2>
              <p className="member-title">{member.title}</p>
              <p className="member-description">{member.description}</p>
            </div>
          ))}
        </div>
      </section>

      <footer className="club-member-footer">
        <p>Our members are committed to making a positive impact through collaboration and teamwork.</p>
      </footer>
    </div>
  );
};

export default ClubMemberComponent