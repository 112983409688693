import React, { useState } from "react";
import "./SubscriptionPage.css"; // Custom CSS for styles

const SubscriptionComponent = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
    alert(`You have selected the ${plan === 500 ? "$500" : "$3100"} plan`);
  };

  return (
    <div className="subscription-container margin-top-109">
      <header className="subscription-header">
        <h1>Choose Your Subscription Plan</h1>
        <p>Unlock the best features for your needs</p>
      </header>

      <section className="container">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-4 mb-3">
              <div className="plan-card">
                <h2 className="plan-title">Rs. 500 Subscription</h2>
                <p className="plan-price">Rs. 500 / Year</p>
                <ul className="plan-features">
                  <li>Access to basic features</li>
                  <li>Support via email</li>
                  <li>Community access</li>
                  <li>5 Projects Included</li>
                </ul>
                <button
                  className={`select-plan-btn ${
                    selectedPlan === 500 ? "selected" : ""
                  }`}
                  onClick={() => handleSelectPlan(500)}
                >
                  Choose Rs. 500 Plan
                </button>
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="plan-card">
                <h2 className="plan-title">Rs. 3100 Subscription</h2>
                <p className="plan-price">Rs. 3100 / Year</p>
                <ul className="plan-features">
                  <li>All features of the Rs. 500 plan</li>
                  <li>Priority customer support</li>
                  <li>Unlimited projects</li>
                  <li>Access to premium tools</li>
                  <li>Advanced analytics & insights</li>
                </ul>
                <button
                  className={`select-plan-btn ${
                    selectedPlan === 3100 ? "selected" : ""
                  }`}
                  onClick={() => handleSelectPlan(3100)}
                >
                  Choose Rs. 3100 Plan
                </button>
              </div>
            </div>
          </div>
        </div>
      

      <footer className="subscription-footer">
        <p>
          <strong>Note:</strong>The person joining will have to take a package
          of Rs. 3100/- If he does not have Rs. 3100 then he can join by paying
          Rs. 500 in advance booking of that package and after earning he can
          get the full joining facility by paying the remaining amount of Rs.
          2600. And in both the conditions one can earn by taking free joining
          and registering for free. Only one who will join for free will donate.
          The customer will be online. The company's working, non-working
          leadership etc. will not be able to get any benefit from that.
        </p>
      </footer>
      </section>
    </div>
  );
};

export default SubscriptionComponent;
