import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ApiUrl, GetId, getToken, GetUserType, removeUserSession } from "../../../utils/common";



const Sidebar = (props ) => {
  const userType = GetUserType()  
  const navigate = useNavigate();
  const profileData=props.profileData
  const UserDesgnation=props.Desgnation
  const Logout=props.Logout


  const sidebarRef = useRef(null);

  const handleLinkClick = () => {
    if (sidebarRef.current) {
      const collapse = new window.bootstrap.Collapse(sidebarRef.current, {
        toggle: false
      });
      collapse.hide();  // This will hide the sidebar
    }
  };





  return (
   

      <div className="db-sidebar bg-white sb-side">

        <nav className="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0 add-overflow-scroll">
          <div className="sticky-side sticky-area shadow-xs-1 py-3  ps-fixed">
            <div className="d-flex px-3 px-xl-6 w-100">
              <a className="navbar-brand " href="/">
                <img className="logo-size"src="/images/amtimelogo.png" alt="HomeID"  />
              </a>
              <div className="ml-auto d-flex align-items-center ">
                {/* <div className="d-flex align-items-center d-xl-none">
                  <div className="dropdown px-3">
                    <Link to="/dashboard" className=" d-flex align-items-center text-heading">
                      <div className="w-48px ">
                        <img src={profileData?.profile_image || "images/agency-list-01.jpg"}
                          alt="Ronald Hunter" className="rounded-circle" style={{ width: '45px', height: '45px' }} />
                      </div>
                      
                    </Link>
                  </div>
                  
                </div> */}
                <button className="navbar-toggler border-0 px-0" type="button" data-bs-toggle="collapse"
                  data-bs-target="#primaryMenuSidebar"
                  aria-controls="primaryMenuSidebar" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
            </div>
            <div className="collapse navbar-collapse bg-white" id="primaryMenuSidebar" ref={sidebarRef}>
             
             <ul className="list-group list-group-flush w-100">
                <li className="list-group-item pt-6 pb-4">
                  {/* <h5 className="fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3">Main</h5> */}
                  <ul className="list-group list-group-no-border rounded-lg">
                    <li className="px-3 px-xl-4 py-2 ml-add-35 bg-blue hover-saffron" style={{ borderRadius: '5px', listStyle: 'none' }}>
                      <Link to="/dashboard" className="text-white lh-1 " onClick={handleLinkClick}>
                        <span className="sidebar-item-text">
                          {UserDesgnation} Dashboard
                        </span>
                      </Link>
                    </li>
                
                  </ul>
                </li>
                <li className="list-group-item pt-6 pb-4">
                  {/* <h5 className="fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3">Manage Listings</h5> */}
                  <ul className="list-group list-group-no-border rounded-lg">
                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/mlm/graph/"
                        className="text-heading lh-1 sidebar-link" onClick={handleLinkClick}>
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20 fs-20">
                          <svg className="icon icon-add-new"><use
                            xlinkHref="#icon-add-new"></use></svg></span>
                        <span className="sidebar-item-text">Graph</span>
                      </Link>
                    </li>
                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/mlm/pending-payment"
                      onClick={handleLinkClick}
                        className="text-heading lh-1 sidebar-link d-flex align-items-center">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-heart"><use xlinkHref="#icon-heart"></use></svg>
                        </span>
                        <span className="sidebar-item-text">Payment Status</span>

                      </Link>
                    </li>
                   
                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to='/mlm/non-working-plan/' onClick={handleLinkClick}>
                        <a
                          className="text-heading lh-1 sidebar-link d-flex align-items-center">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                          </span>
                          <span className="sidebar-item-text">Non Working Rewards</span>
                          {/* <span className="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">29</span> */}
                        </a>
                      </Link>
                    </li>

                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to='/mlm/my-level/' onClick={handleLinkClick}>
                        <a
                          className="text-heading lh-1 sidebar-link d-flex align-items-center">
                          <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                            <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                          </span>
                          <span className="sidebar-item-text">Member Ship Rewards</span>
                          {/* <span className="sidebar-item-number ml-auto text-primary fs-15 font-weight-bold">29</span> */}
                        </a>
                      </Link>
                    </li>

                    {/* <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/mlm/club-member/"
                        className="text-heading lh-1 sidebar-link d-flex align-items-center">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-review"><use xlinkHref="#icon-review"></use></svg>
                        </span>
                        <span className="sidebar-item-text">Club Member</span>

                      </Link>
                    </li> */}

                  </ul>

                </li>


                <li className="list-group-item pt-6 pb-4">
                  <h5 className="fs-13 letter-spacing-087 text-muted mb-3 text-uppercase px-3" style={{ marginLeft: '28px' }}>Manage Account</h5>
                  <ul className="list-group list-group-no-border rounded-lg">



                  <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/mlm/subscription/"
                        className="text-heading lh-1 sidebar-link" onClick={handleLinkClick}>
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-profile"><use xlinkHref="#icon-my-profile"></use></svg>
                        </span>
                        <span className="sidebar-item-text">Subscription</span>
                      </Link>
                    </li>


                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item" >
                      <Link to="/mlm/lottery/" onClick={handleLinkClick}
                        className="text-heading lh-1 sidebar-link">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-profile"><use xlinkHref="#icon-my-profile"></use></svg>
                        </span>
                        <span className="sidebar-item-text">Crowd Funding</span>
                      </Link>
                    </li>


                    <li className="list-group-item px-3 px-xl-4 py-2 sidebar-item">
                      <Link to="/mlm/donation/" onClick={handleLinkClick}
                        className="text-heading lh-1 sidebar-link">
                        <span className="sidebar-item-icon d-inline-block mr-3 text-muted fs-20">
                          <svg className="icon icon-my-profile"><use xlinkHref="#icon-my-profile"></use></svg>
                        </span>
                        <span className="sidebar-item-text">Donation</span>
                      </Link>
                    </li>


                  </ul>
                </li>


              </ul>
              
            


            </div>
          </div>
        </nav>
      </div>
   
  )
}
export default Sidebar; 