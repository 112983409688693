import React from 'react'
import NonWorkingComponent from '../Components/NonWorkingPages/NonWorkingComponent'

function NonWorking() {
  return (
  <>
  
  <NonWorkingComponent />
  </>
  )
}

export default NonWorking