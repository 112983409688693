import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../../Components/header/header";
import Footer from "../../Components/footer/footer";
import axios from "axios";

const BlogSinglePage = () => {
  
  const API_URL = process.env.REACT_APP_API_URL;
  const IMG_URL = process.env.REACT_APP_BASE_API_PATH
  const { id } = useParams(); // Get blog ID from URL
  const [blogData, setBlogData] = useState(null); // State to hold the blog data
  const [loading, setLoading] = useState(true); // State to handle loading status
  const [error, setError] = useState(null); // State to handle any error

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/blog/list/${id}/`
        );
        setBlogData(response.data); 
        setLoading(false); 
      } catch (err) {
        setError(err.message); 
        setLoading(false);
      }
    };

    fetchBlogData();
  }, [id]); // Runs when the id from URL changes

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <Header />
      <main id="content" className="mt-100">
        <section className="pt-13 pb-12">
          <div className="container">
            <div className="row ml-xl-0 mr-xl-n6">
              <div className="col-lg-8 mb-6 mb-lg-0 pr-xl-6 pl-xl-0 ">
                <h3 className="fs-md-32 text-heading lh-141 mb-5">
                  {blogData.title}
                </h3>
                <div className="position-relative">
                  <img
                    className="rounded-lg d-block"
                    src={blogData.image} // Display the blog image
                    alt={blogData.title}
                  />
                </div>
                <ul className="list-inline mt-4 ">
                  <li className="list-inline-item mr-4">
                    <img
                      className="mr-1"
                      src="../images/userG.png" style={{width:"25px"}}// Default avatar if no image
                      
                    />{" "}
                    {blogData.user_name || "Admin"}
                  </li>
                  <li className="list-inline-item mr-4">
                    <i className="far fa-calendar mr-1"></i>{" "}
                    {new Date(blogData.create_at).toLocaleDateString()}
                  </li>
                </ul>

                <div
                  className="lh-214 mb-9 border-bottom fs-18"
                  dangerouslySetInnerHTML={{ __html: blogData.desc }} // Display the blog description
                ></div>
              </div>

              {/* Sidebar can remain unchanged */}
              <div className="col-lg-4 pl-xl-6 pr-xl-0 primary-sidebar sidebar-sticky" id="sidebar">
                <div className="primary-sidebar-inner">
                  {/* Sidebar Content */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default BlogSinglePage;
