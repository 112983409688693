import React from 'react'
import MyLevelComponent from '../Components/MyLevelPages/MyLevelComponent'

function MyLevel() {
  return (
    <>


    <MyLevelComponent />
    </>

  )
}

export default MyLevel