import React from 'react'
import DonationComponent from '../Components/DonationPages/DonationComponent'
import Header from '../../Components/header/header'

function Donation() {
  return (
    <>
    <br />
    <Header />

    <DonationComponent />
    
    </>


  )
}

export default Donation