// return the user data from the local storage
export const getUser = () => {
    const userStr = localStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
}

// return the token from the local storage
export const getToken = () => {
    return localStorage.getItem('token') || null;
}
export const getAccessToken = () => {
    return localStorage.getItem('accessToken') || null;
}




export const SetUserType = (type) => {
    localStorage.setItem('userType', type);
}

export const GetUserType = () => {
    return localStorage.getItem('userType') || null;
}
export const getRefreshToken = () => {
    return localStorage.getItem('refreshToken') || null;
}

// export const Getsaltyeml = () =>
// {
//     return JSON.parse(window.atob( `${getToken()}`.split('.')[1])).email;

// }

export const googleMapsApiKey = () => {
    return 'AIzaSyAqVBbzZmrRV7LzReXe_bvQ4I-PuzP1GQ0';
}

// remove the token and user from the local storage
export const removeUserSession = () => {
    
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('DevType');
    localStorage.removeItem('ObType');
    localStorage.removeItem('planId');
    localStorage.removeItem('AdminType');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('isSuperUser');
    localStorage.removeItem('AgeType');
    localStorage.removeItem('id');
}

// set the token and user from the local storage
export const setUserSession = (token) => {
    localStorage.setItem('token', token);
}

// global api url 
// export const ApiUrl = () => {
//     return ApiHost() + '/auth';
// }

export const ApiUrl = () => {
    return ApiHost();
}


/* export const ApiHost = () => {
    return 'https://api.dollarperfoot.in';
} */
export const ApiHost = () => {
    return process.env.REACT_APP_BASE_URL;
} 

// login user id
export const AddId = (id) => {
    localStorage.setItem('id', id);
}
export const SetAccessToken = (data) => {
    localStorage.setItem('accessToken', data);
}

export const SetRefreshToken = (data) => {
    localStorage.setItem('refreshToken', data);
}

export const AdminData = (data) => {
    localStorage.setItem('isSuperUser', data);
}
export const GetId = () => {
    return localStorage.getItem('id') || null;
}

// developer id
export const DeveloperId = (devid) => {
    localStorage.setItem('devid', devid);

}
export const GetDeveloperId = () => {
    return localStorage.getItem('devid') || null;
}
// developer type set  get
export const DeveloperType = (DevType) => {
    localStorage.setItem('DevType', DevType);
}
export const GetDeveloperType = () => {
    return localStorage.getItem('DevType') || null;
}
// agent type set get
export const AgentType = (AgeType) => {
    localStorage.setItem('AgeType', AgeType);
}
export const GetAgentType = () => {
    return localStorage.getItem('AgeType') || null;
}
// other business type set get
export const OtherBusinessType = (ObType) => {
    localStorage.setItem('ObType', ObType);
}
export const GetOtherBusinessType = () => {
    return localStorage.getItem('ObType') || null;
}

// stripe plan id store
export const PlanId = (planId) => {
    localStorage.setItem('planId', planId)
}

export const GetPlanId = () => {
    return localStorage.getItem('planId') || null;
}

// publishable key
export const PubliShableKey = (publishableKey) => {
    localStorage.setItem('publishableKey', publishableKey)
}

export const GetPubliShableKey = () => {
    return localStorage.getItem('publishableKey') || null;
}

// session id
export const SessionId = (sessionId) => {
    localStorage.setItem('sessionId', sessionId)
}

export const GetSessionId = () => {
    return localStorage.getItem('sessionId') || null;
}

// address object store used in landing page

export const AddressObject = (addressObject) => {
    console.log(addressObject, 'addressObject');
    localStorage.setItem('addressObject', addressObject)
    // console.log(addressObject,'addressObject');
}



