import React, { useEffect, useState } from 'react';
import axiosInstance from '../../interceptor/axiosInstance';

function AllJoinedMember() {
  const API_URL = process.env.REACT_APP_API_URL;

  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // Fetch data from the API with pagination
    axiosInstance.get(`${API_URL}/account/get/all/?page=${currentPage}`)
      .then(response => {
        setData(response.data.results); // Update data with results
        setCount(response.data.count);  // Total number of entries
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [API_URL, currentPage]);

  // Calculate total pages based on data count (assuming 10 items per page)
  const itemsPerPage = 10;
  const totalPages = Math.ceil(count / itemsPerPage);

  // Handlers for pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  return (

    <>
    <br />
    <div className="container mt-4">
      <h2 className="text-center mb-4">All Joined Users</h2>

    <div className="table-responsive">
      <table className="table table-hover table-striped table-bordered creative-table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Parent User</th>
            <th scope="col">Branch User Name</th>
            <th scope="col">Branch User Email</th>
            <th scope="col">Payment Status</th>
            <th scope="col">Joined Date</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((item, index) => (
              <tr key={index}>
                <th scope="row">{(currentPage - 1) * itemsPerPage + index + 1}</th>
                <td>{item.parent_user}</td>
                <td>{item.branch_user_name}</td>
                <td>{item.branch_user}</td>
                <td>{item.payment_status ? 'Completed' : 'Pending'}</td>
                <td>{new Date(item.joined_date).toLocaleString()}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">No completed payments</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="pagination-controls d-flex justify-content-between mt-3">
        <button
          className="btn btn-primary"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          className="btn btn-primary"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
    </div>
    <br />
    </>
  );
}

export default AllJoinedMember;
