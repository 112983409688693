import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from "../../../interceptor/axiosInstance";
import { toast } from "react-toastify";
import CountdownTimer from "./CountDown";

const LotteryComponent = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [investmentAmount, setInvestmentAmount] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [userInvestment, setUserInvestment] = useState("");
  const [selectedCardName, setSelectedCardName] = useState("");
  const [countTimer, setCountTimer] = useState("");
  const [timerStatus, setTimerStatus] = useState("");
  
  
  console.log(timerStatus,"timerStatus")
  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await axios.get(`${API_URL}/lottery/card/list/`);
        setCards(response.data);
      } catch (error) {
        toast.error("Error fetching the cards:", error);
      }
    };
    fetchCards();
  }, []);
  useEffect(() => {
    const fetchCount = async () => {
      try {
        const response = await axios.get(`${API_URL}/lottery/card/timer/`);
        setCountTimer(response.data);
      } catch (error) {
        toast.error("Error fetching the count:", error);
      }
    };
    fetchCount();
  }, []);

  const handleStatusChange = (newStatus) => {
    setTimerStatus(newStatus);
  };

  const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);

  const handleCardClick = (card) => {
    if (!card.is_active || card.is_hide) return; // Skip if card is not active or hidden

    setSelectedCard(card); // Set selected card

    // Set the investment amount based on card type (winning or regular)
    setInvestmentAmount(
      card.is_winning
        ? card.winning_pricing
        : shuffleArray([card.invest_pricing])[0]
    );
  };

  const handleReset = () => {
    setSelectedCard(null);
    setInvestmentAmount(null);
    setUserInvestment("");
  };

  const handleInvestClick = (cardName) => {
    setSelectedCardName(cardName); // Set the selected card name
    setShowModal(true); // Show the modal when Invest is clicked
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedCardName("");
    setUserInvestment(""); // Clear input when closing the modal
  };

  const handleInvestmentSubmit = async () => {
    const investmentData = {
      lottery_card: selectedCard.id, // Use selected card ID
      investment_pricing: userInvestment, // Use user input
    };

    try {
      const response = await axiosInstance.post(
        `${API_URL}/lottery/card/investment/`,
        investmentData
      );
      toast.success("Donate successful");
    } catch (error) {
      console.error("Error submitting investment:", error.error);
      // Display a custom error message or the one from the server response
    const errorMessage = error?.response?.data?.error || "Error submitting investment";
    toast.error(errorMessage);
    }

    handleModalClose();
    handleReset();
  };

  return (
    <div className="container margin-top-153">
      <header className="text-center my-4">
        <h1 className="display-4">Donate to Crowd Funding</h1>
        <h1 className="display-4">Top 10 circle income prize!</h1>
        <p className="lead">
          Select one of the box to reveal your Donation amount
        </p>
      </header>
      <CountdownTimer startTime={countTimer.start_time} endTime={countTimer.end_time} onStatusChange={handleStatusChange}/>
      <div className="row justify-content-center my-4 position-relative px-5">
        {cards
          .filter((card) => card.is_active && !card.is_hide)
          .map((card) => (
            <div
              key={card.id}
              className={`col-md-2 m-2 text-center rounded p-4`}
              onClick={() => handleCardClick(card)}
              style={{
                cursor: "pointer",
                background: selectedCard?.id === card.id ? "green" : "#b91928",
                position: selectedCard?.id === card.id ? "relative" : "static", // Change position for the selected card
                zIndex: selectedCard?.id === card.id ? 1 : 0, // Bring the selected card to the front
                transform:
                  selectedCard?.id === card.id ? "scale(1)" : "scale(0.9)", // Scale the selected card
                transition: "transform 0.3s ease", // Smooth transition for scaling
              }}
            >
              <p
                className="m-0 "
                style={{ color: "#fff", textTransform: "uppercase" }}
              >
                {card.card_name}
              </p>

              {selectedCard?.id === card.id && (
                <button
                  className="btn btn-warning mt-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleInvestClick(card.card_name); // Pass the card name here
                  }}
                >
                  Donate
                </button>
              )}
            </div>
          ))}
      </div>

      {/* Modal for Investment */}
      {showModal && (
        <>
        {timerStatus==="active" ? 
        <div
          className="modal show d-block"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Confirm Your Donation for {selectedCardName} and Minimum
                  Donation {investmentAmount}
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={handleModalClose}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Please enter your Donation amount:</p>
                <input
                  type="number"
                  className="form-control"
                  value={userInvestment}
                  onChange={(e) => setUserInvestment(e.target.value)}
                  placeholder="Donate Amount"
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleModalClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleInvestmentSubmit}
                >
                  Confirm Donation
                </button>
              </div>
            </div>
          </div>
        </div>
        :
        <div
        className="modal show d-block"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
               Thankyou for showing your Interest
              </h5>
              <button
                type="button"
                className="close"
                onClick={handleModalClose}
              >
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Timer has not Started Yet. Please wait...</p>
              
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleModalClose}
              >
                Cancel
              </button>
             
            </div>
          </div>
        </div>
      </div>
        }
        </>
      )}

      <footer className="text-center text-muted my-4">
        <p>
          All Donation are randomly assigned. Donate wisely and come back to try
          your luck again!
        </p>
      </footer>
    </div>
  );
};

export default LotteryComponent;
