
import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import CurrencyFormat from "react-currency-format";
import Slider from "react-slick";
const BarChartOptions = {
  chart: {
    type: 'column'
  },
  title: {
    text: 'Property Leads and Sales'
  },
  xAxis: {
    categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  },
  colors: ['#FB8833', '#17A8F5'],
  series: [
    {
      name: 'Sales',
      data: [21, 35, 75, 51, 41, 47, 34, 54, 67, 65, 89, 90]
    },
    {
      name: 'Leads',
      data: [41, 79, 57, 47, 63, 71, 23, 43, 65, 76, 87, 89]
    }
  ],
  credits: {
    enabled: false
  }
}
export default function AdminDdashBaord() {
  var settings = {
    autoplay:true,
    arrows:false,
    vertical: true,
    autoplaySpeed:4000,
    slidesToShow:5,
    swipeToSlide:true
  };

  return (

    <>

      <main id="content" className="bg-gray-01">
        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
          {/*  <div className="d-flex flex-wrap flex-md-nowrap mb-6">
                <div className="mr-0 mr-md-auto">
                  <h2 className="mb-0 text-heading fs-22 lh-15">Welcome back, Ronald Hunter!</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. At iusto, laboriosam. Atque</p>
                </div>
                <div>
                  <a href="dashboard-add-new-property.html" className="btn btn-primary btn-lg">
                    <span>Add New Property</span>
                    <span className="d-inline-block ml-1 fs-20 lh-1"><svg className="icon icon-add-new"><use
                        xlinkHref="#icon-add-new"></use></svg></span>
                  </a>
                </div>
              </div> */}
          <div className="row">
            <div className="col-sm-3 col-xxl-3 mb-6">
              <div className="card">
                <div className="card-body row align-items-center px-6 py-7">
                  <div className="col-5">
                    <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-blue badge-circle">
                      <img style={{ width: '50px' }} src="./images/building-icon-1.png" alt="property-icon" />
                    </span>
                  </div>
                  <div className="col-7 text-center">
                    <p className="fs-42 lh-12 mb-0 counterup" data-start="0"
                      data-end="29" data-decimals="0"
                      data-duration="0" data-separator="">29</p>
                    <p>Total Properties</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3 col-xxl-3 mb-6">
              <div className="card">
                <div className="card-body row align-items-center px-6 py-7">
                  <div className="col-5">
                    <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-green badge-circle">
                      <img src="./images/total-subscriber.png" alt="property-rating" style={{ width: "56px", height: "56px" }} />
                    </span>
                  </div>
                  <div className="col-7 text-center">
                    <p className="fs-42 lh-12 mb-0 counterup" data-start="0"
                      data-end="1730" data-decimals="0"
                      data-duration="0" data-separator="">1730</p>
                    <p>Total Subscribers</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3 col-xxl-3 mb-6">
              <div className="card">
                <div className="card-body row align-items-center px-6 py-7">
                  <div className="col-4">
                    <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-yellow badge-circle">
                      <img src="./images/proprating.png" alt="property-rating" style={{ width: "56px", height: "56px" }} />
                    </span>
                  </div>
                  <div className="col-8 text-center">
                    <p className="fs-42 lh-12 mb-0 counterup" data-start="0"
                      data-end="329" data-decimals="0"
                      data-duration="0" data-separator="">329</p>
                    <p>Total Visitor Reviews</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3 col-xxl-3 mb-6">
              <div className="card">
                <div className="card-body row align-items-center px-6 py-7">
                  <div className="col-5">
                    <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-pink badge-circle">
                      <img src='./images/proplead.png' alt="property-leads" style={{ width: "56px", height: "56px" }} />
                    </span>
                  </div>
                  <div className="col-7 text-center">
                    <p className="fs-42 lh-12 mb-0 counterup" data-start="0"
                      data-end="914" data-decimals="0"
                      data-duration="0" data-separator="">914</p>
                    <p>Total Property Leads</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className="section col-md-6">
              <div className="card">
                <div className="card-body row align-items-center px-6 py-7">
                  <h3 className="section-title">View statistics</h3>
                  <div className="section-content">
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={BarChartOptions}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="section col-md-6">
              <div className="card">
                <div className="card-body row align-items-center px-6 py-5">
                  <h3 className="section-title mb-2">Tracked Properties
                  <span className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">5</span>
                  </h3>
                  <Slider {...settings}>
                  <div className="section-content-admin d-flex px-2 py-2 mb-1">

                    <div className='px-2 ' style={{ width: '100px' }}>
                      <img className="rounded-circle" src="images/officepng.png" />
                    </div>
                    <div className='property-content px-2 mt-1 w-100'>
                      <h2 className='fs-16  '>property Name</h2>
                      <span className='text-blue font-weight-bold'>
                        <CurrencyFormat displayType="text" thousandSeparator={true} value="25252" prefix="$" />
                      </span>
                    </div>
                    <span className='badge mt-2 badge-orange justify-content-center' style={{ height: 'fit-content' }}>
                      For Sale
                    </span>
                  </div>
                  <div className="section-content-admin d-flex px-2 py-2 mb-1">

                    <div className='px-2 ' style={{ width: '100px' }}>
                      <img className="rounded-circle" src="images/officepng.png" />
                    </div>
                    <div className='property-content px-2 mt-1 w-100'>
                      <h2 className='fs-16  '>property Name</h2>
                      <span className='text-blue font-weight-bold'>
                        <CurrencyFormat displayType="text" thousandSeparator={true} value="25252" prefix="$" />
                      </span>
                    </div>
                    <span className='badge mt-2 badge-orange justify-content-center' style={{ height: 'fit-content' }}>
                      For Sale
                    </span>
                  </div>
                  <div className="section-content-admin d-flex px-2 py-2 mb-1">

                    <div className='px-2 ' style={{ width: '100px' }}>
                      <img className="rounded-circle" src="images/officepng.png" />
                    </div>
                    <div className='property-content px-2 mt-1 w-100'>
                      <h2 className='fs-16  '>property Name</h2>
                      <span className='text-blue font-weight-bold'>
                        <CurrencyFormat displayType="text" thousandSeparator={true} value="25252" prefix="$" />
                      </span>
                    </div>
                    <span className='badge mt-2 badge-orange justify-content-center' style={{ height: 'fit-content' }}>
                      For Sale
                    </span>
                  </div>
                  <div className="section-content-admin d-flex px-2 py-2 mb-1">

                    <div className='px-2 ' style={{ width: '100px' }}>
                      <img className="rounded-circle" src="images/officepng.png" />
                    </div>
                    <div className='property-content px-2 mt-1 w-100'>
                      <h2 className='fs-16  '>property Name</h2>
                      <span className='text-blue font-weight-bold'>
                        <CurrencyFormat displayType="text" thousandSeparator={true} value="25252" prefix="$" />
                      </span>
                    </div>
                    <span className='badge mt-2 badge-orange justify-content-center' style={{ height: 'fit-content' }}>
                      For Sale
                    </span>
                  </div>
                  <div className="section-content-admin d-flex px-2 py-2 mb-1">

                    <div className='px-2 ' style={{ width: '100px' }}>
                      <img className="rounded-circle" src="images/officepng.png" />
                    </div>
                    <div className='property-content px-2 mt-1 w-100'>
                      <h2 className='fs-16  '>property Name</h2>
                      <span className='text-blue font-weight-bold'>
                        <CurrencyFormat displayType="text" thousandSeparator={true} value="25252" prefix="$" />
                      </span>
                    </div>
                    <span className='badge mt-2 badge-orange justify-content-center' style={{ height: 'fit-content' }}>
                      For Sale
                    </span>
                  </div>
                  <div className="section-content-admin d-flex px-2 py-2 mb-1">

                    <div className='px-2 ' style={{ width: '100px' }}>
                      <img className="rounded-circle" src="images/officepng.png" />
                    </div>
                    <div className='property-content px-2 mt-1 w-100'>
                      <h2 className='fs-16  '>property Name</h2>
                      <span className='text-blue font-weight-bold'>
                        <CurrencyFormat displayType="text" thousandSeparator={true} value="25252" prefix="$" />
                      </span>
                    </div>
                    <span className='badge mt-2 badge-orange justify-content-center' style={{ height: 'fit-content' }}>
                      For Sale
                    </span>
                  </div>
                  <div className="section-content-admin d-flex px-2 py-2 mb-1">

                    <div className='px-2 ' style={{ width: '100px' }}>
                      <img className="rounded-circle" src="images/officepng.png" />
                    </div>
                    <div className='property-content px-2 mt-1 w-100'>
                      <h2 className='fs-16  '>property Name</h2>
                      <span className='text-blue font-weight-bold'>
                        <CurrencyFormat displayType="text" thousandSeparator={true} value="25252" prefix="$" />
                      </span>
                    </div>
                    <span className='badge mt-2 badge-orange justify-content-center' style={{ height: 'fit-content' }}>
                      For Sale
                    </span>
                  </div>
                  <div className="section-content-admin d-flex px-2 py-2 mb-1">

                    <div className='px-2 ' style={{ width: '100px' }}>
                      <img className="rounded-circle" src="images/officepng.png" />
                    </div>
                    <div className='property-content px-2 mt-1 w-100'>
                      <h2 className='fs-16  '>property Name</h2>
                      <span className='text-blue font-weight-bold'>
                        <CurrencyFormat displayType="text" thousandSeparator={true} value="25252" prefix="$" />
                      </span>
                    </div>
                    <span className='badge mt-2 badge-orange justify-content-center' style={{ height: 'fit-content' }}>
                      For Sale
                    </span>
                  </div>
                  <div className="section-content-admin d-flex px-2 py-2 mb-1">

                    <div className='px-2 ' style={{ width: '100px' }}>
                      <img className="rounded-circle" src="images/officepng.png" />
                    </div>
                    <div className='property-content px-2 mt-1 w-100'>
                      <h2 className='fs-16  '>property Name</h2>
                      <span className='text-blue font-weight-bold'>
                        <CurrencyFormat displayType="text" thousandSeparator={true} value="25252" prefix="$" />
                      </span>
                    </div>
                    <span className='badge mt-2 badge-orange justify-content-center' style={{ height: 'fit-content' }}>
                      For Sale
                    </span>
                  </div>
                  </Slider>
                </div>
              </div>
            </div>

          </div>
          
        </div>
      </main>


    </>
  )
}
