import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Loader from '../../Components/Loader';
import { ApiUrl, getToken } from '../../utils/common';
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { GetId, removeUserSession } from "../../utils/common";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
const OBAdminDash = () => {
  const [loading, setLoading] = useState(false)
  const [counts, setCounts] = useState();
  const [profileData, setProfileData] = useState();
  const [planData, setPlanData] = useState();
  console.log(planData, 'planData');
  const navigate = useNavigate();
  // get profile api
  const GetProfile = () => {
    setLoading(true);
    const data = "";
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      data: { "data": data },
      url: `${ApiUrl()}/auth/user/otherbusiness-profile-get/${GetId()}`
    };

    axios(option)
      .then(e => {
        setLoading(false);
        setProfileData(e?.data?.[0])
      })
      .catch(err => {
        if (err?.request?.status === 401) {
          removeUserSession();
          navigate('/')
        }
        setLoading(false);
      })
  }
  const GetCounts = () => {
    setLoading(true)
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/api/dashboard/counts`
    };
    axios(option)
      .then(e => {
        setLoading(false)
        setCounts(e?.data)
      })
      .catch(err => {
        setLoading(false)
      })
  }


  const GetDataPlan = () => {
    setLoading(true)
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/auth/subscription/current_plan/`
    };
    axios(option)
      .then(e => {
        setLoading(false);
        setPlanData(e?.data?.[0]);

      })
      .catch(err => {
        setLoading(false)
      })
  }
  useEffect(() => {
    GetCounts();
    GetProfile();
    GetDataPlan();
  }, [])
  return (
    <>
      {
        loading ? <Loader /> : null
      }
      <main id="content" className="bg-gray-01 margin-re-top-80">
        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
          {/* <div className="d-flex flex-wrap flex-md-nowrap mb-6">
            <div className="mr-0 mr-md-auto">
             

            </div>
            <div>
              <Link to="/other-admin-profile" className="btn btn-primary btn-lg ml-2">
                <span>Edit Profile</span>
                <span className="d-inline-block ml-1 fs-20 lh-1"></span>
              </Link>
            </div>
          </div> */}

          <div className='row'>
            <div className='col-lg-8'>
              {
                planData?.plan_name ? <div className='row'>
                  <div className='col-lg-12 mb-5'>
                    <div className="card round-30 bg-blue">
                      <div className="card-body row align-items-center px-6">

                        <div className="col-2">
                          <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-light badge-circle ">
                            <img src="images/subscribe.png" alt="property-icon" />
                          </span>
                        </div>
                        <div className="col-7 margin-re-left-60">
                          <p className='text-white mt-2 mb-0'>Plan</p>
                          <h2 className='text-white fs-36 mb-0'>{planData.plan_name}</h2>
                          <p className="text-white mb-0">Expiry Date</p>
                          <p className=" text-white">{moment(planData.exp_date).format("DD-MMM-YYYY")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> : null
              }

              <div className="row">

                <div className="col-lg-6  mb-6">
                  <div className="card">
                    <Link to="/obuserreviews">
                      <div className="card-body row align-items-center px-6 py-7">
                        <div className="col-4">
                          <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-yellow badge-circle">
                            <img src="images/proprating.png" alt="property-rating" style={{ width: "56px", height: "56px" }} />
                          </span>
                        </div>
                        <div className="col-8 text-center">
                          <p className="text-blue fs-42 lh-12 mb-0 counterup" data-start="0"
                            data-end="329" data-decimals="0"
                            data-duration="0" data-separator="">{counts?.user_review_count}</p>
                          <p className='text-blue'>My Reviews</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6  mb-6">
                  <div className="card">
                    <Link to="/obuserleads">
                      <div className="card-body row align-items-center px-6 py-7">
                        <div className="col-5">
                          <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-pink badge-circle">
                            <img src='images/proplead.png' alt="property-leads" style={{ width: "56px", height: "56px" }} />
                          </span>
                        </div>
                        <div className="col-7 text-center">
                          <p className="text-blue fs-42 lh-12 mb-0 counterup" data-start="0"
                            data-end="914" data-decimals="0"
                            data-duration="0" data-separator="">{counts?.user_lead_count}</p>
                          <p className='text-blue'>My Leads</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {
              profileData?.profile_image ?
                <div className='col-md-4 display-re-none'>
                  <div className="card  bg-blue border-0 px-6 pt-6 pb-10 add-pb-full round-30">
                    <img className="rounded-lg border card-img h-275-img-myprofile" src={profileData?.profile_image || "images/upload-dummy-image.png"}
                      alt="Upload-profile image" />
                    <div className="card-body p-0">
                      <h2 className="card-title fs-22 lh-15 mb-1 text-white">
                        {profileData?.first_name} {profileData?.last_name}

                      </h2>
                      <p className="card-text mb-1 text-white">
                        {profileData?.designation}
                      </p>
                      <ul className="list-group list-group-no-border extra-hover">
                        <li className="list-group-item bg-blue d-flex align-items-sm-center lh-214 row mx-n1 p-0 mb-2 mb-sm-0">
                          <span className="col-sm-3 text-white px-1">Office</span>
                          <span className="col-sm-9 px-1 text-white font-weight-500">{profileData?.office_no}</span>
                        </li>
                        <li className="list-group-item bg-blue d-flex align-items-sm-center lh-214 row mx-n1 p-0 mb-2 mb-sm-0">
                          <span className="col-sm-3 text-white px-1">Mobile</span>
                          <span className="col-sm-9 px-1 text-white font-weight-500">{profileData?.mobile_no}</span>
                        </li>
                        <li className="list-group-item bg-blue d-flex align-items-sm-center lh-214 row mx-n1 p-0 mb-2 mb-sm-0">
                          <span className="col-sm-3 text-white px-1">Email</span>
                          <span className="col-sm-9 px-1 text-white">{profileData?.email}</span>
                        </li>
                        <li className="list-group-item bg-blue d-flex align-items-sm-center lh-214 row mx-n1 p-0 mb-2 mb-sm-0">
                          <span className="col-sm-3 text-white px-1">Website</span>
                          <span className="col-sm-9 px-1 "><a className='text-white' target="_blank" href={profileData?.website}>{profileData?.website}</a>{/* {profileData?.website} */}</span>
                        </li>
                        <li className="list-group-item bg-blue d-flex align-items-sm-center lh-214 row mb-0 mt-3 mx-n1 p-0 ">
                          <span className="col-sm-3 text-white px-1">Social</span>
                          <ul className="col-sm-9 list-inline text-gray-lighter m-0 px-1">
                            <li className="list-inline-item m-0">
                              <a href={profileData?.twitter_url || "#"}
                                className="w-32px h-32 rounded bg-hover-primary bg-white hover-white text-body d-flex align-items-center justify-content-center border border-hover-primary">
                                <BsTwitter />
                              </a>
                            </li>
                            <li className="list-inline-item mr-0 ml-2">
                              <a href={profileData?.facebook_url || "#"}
                                className="w-32px h-32 rounded bg-hover-primary bg-white hover-white text-body d-flex align-items-center justify-content-center border border-hover-primary">
                                <FaFacebookF />
                              </a>
                            </li>
                            <li className="list-inline-item mr-0 ml-2">
                              <a href={profileData?.instagram_url || "#"}
                                className="w-32px h-32 rounded bg-hover-primary bg-white hover-white text-body d-flex align-items-center justify-content-center border border-hover-primary">
                                <BsInstagram /></a>
                            </li>

                          </ul>
                        </li>
                      </ul>
                      <div className='set-align'>
                        <Link to="/other-admin-profile" className="edit-pro-icon">
                          <img src='images/edit-pro.png' />
                        </Link>
                        <Link to="/otheruserprofile" className='edit-pro-icon text-white bg-blue'>View Full Profile</Link>
                      </div>
                    </div>
                  </div>
                </div> : null
            }
          </div>
        </div>
      </main>


      {/*  <!---end main---> */}

    </>
  )
}
export default OBAdminDash;