import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ImCross } from "react-icons/im";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AgentSidebar from "../../Components/AgentSidebar/agentsidebar";
import Loader from "../../Components/Loader";
import SearchDropdown from "../../Components/SearchDropdown";
import { ApiUrl, getToken, removeUserSession } from "../../utils/common";
import AgentHeader from "./agentheader";

const AgentPropAddContact = () => {
    const [isAddNew, setIsAddNew] = useState(false)
    const HandleClose = () => setIsAddNew(false)
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [designation, setDesignation] = useState();
    const navigate = useNavigate()
    //  get api data
    const [getDataContact, setGetDataContact] = useState([]);
   // console.log(getDataContact,'AllContact')
    const [builder, setBuilder] = useState([])
  //  console.log(builder,'developer')
    const [architect, setArchitect] = useState([])
    const [marketingCompany, setMarketingCompany] = useState([])
    const [salesCompany, setSalesCompany] = useState([])
    const [interiorDesigner, setInteriorDesigner] = useState([])
    
    const [typeModal, setTypeModal] = useState('');
    const state = useLocation()
    const PropertyId = state?.state?.item?.id
    //console.log(PropertyId,'PropertyID')
    //console.log(interiorDesigner,'interiorDesigner');
    const allDropDownData = [...builder, ...architect, ...marketingCompany, ...salesCompany, ...interiorDesigner]
   // console.log(allDropDownData,'allDropDownData');
    // add property Contact api
    const ContactProperty = () => {
        if (!builder?.length && !architect?.length && !marketingCompany?.length && !salesCompany?.length && !interiorDesigner?.length) {
            toast.error("Please Add Atleast One Contact To Submit");
            return
        }
        
        // if (!ValidateFields()) return;
        const body = allDropDownData?.map(item => ({
            name: `${item?.first_name}`,
            // email: item?.email,
            // ${item?.last_name}
            email: item?.email || email,
            builder: item?.type?.includes('Builder'),
            architect: item?.type?.includes('Architect'),
            marketing_company: item?.type?.includes('Marketing'),
            sales_company: item?.type?.includes('Sales'),
            interior_designer: item?.type?.includes('interior'),
            designation: item?.designation,
            property_id: PropertyId
        }))
        const option = {
            method: 'POST', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            data: JSON.stringify(body),
            url: `${ApiUrl()}/api/property/add-property-team`


        };
        axios(option)
            .then(e => {
                if (e?.status === 200) {
                    toast.success("Property Contact Add Successfully");
                    GetProperty();
                    setBuilder([]);
                    setArchitect([]);
                    setMarketingCompany([]);
                    setSalesCompany([]);
                    setInteriorDesigner([]);
                    window.location.reload()
                }
            })
            .catch(err => {
                toast.error(err?.response?.data?.[0].email?.[0])
                if (err?.response?.status === 401) {
                    removeUserSession();
                    navigate('/')
                }


            })

    }
    // const ValidateFields = () => {
    //     if (!name || name.trim() === "") {
    //         toast.error('Please Enter Your Name')
    //         return false
    //     }
    //     if (!designation || designation.trim() === "") {
    //         toast.error('Please Enter Your Designation')
    //         return false
    //     }
    //     return true;
    // }

    // get property contact api
    const GetProperty = () => {
        setLoading(true)

        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/api/property/add-property-team/${PropertyId}`
        }

        axios(option)
            .then(e => {
                setLoading(false)
                setGetDataContact(e?.data)
                
            })
            .catch(err => {
                setLoading(false)
            })
    }

    useEffect(() => {
        GetProperty()
    }, [])


    // delete property contact api
    const DeleteProperty = (id) => {
        const option = {
            method: 'DELETE', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            },
            url: `${ApiUrl()}/api/property/add-property-team/${id}`
        }

        axios(option)
            .then(e => {
                if (e?.data === "") {
                    toast.success("Contact Delete Successfully");
                    GetProperty()
                }
            })
            .catch(err => {
            })
    }

    const ValidateFields = () => {
        if(!name || name.trim() === "") {
            toast.error("Please Enter Name")
            return false
        }
        if(!designation || designation?.trim() === ""){
            toast.error("Please Enter Designation")
            return false
        }

        if(!email) {
            toast.error("Please Enter Email")
            return false
        }
        return true

    }

    const createUser = () => {
        if (!ValidateFields()) return;
        const data = {
            first_name: name,
            email: email,
            designation: designation,
            type: typeModal,
        }
        if (typeModal === 'Builder') {
            setBuilder([...builder, data]);
        }
        else if (typeModal === 'Architect') {
            setArchitect([...architect, data])
        }
        else if (typeModal === 'Marketing') {
            setMarketingCompany([...marketingCompany, data])
        }
        else if (typeModal === 'Sales') {
            setSalesCompany([...salesCompany, data])
        }
        else {
            setInteriorDesigner([...interiorDesigner, data])
        }
        // else if ()
        setIsAddNew(false)
        setName('')
        setEmail('')
        setDesignation('')
    }


    const [valueInput,setValueInput] = useState([])
    //console.log(valueInput,'valueInput');

    return (
        <>

            {
                loading ? <Loader /> : null
            }
            <div className="wrapper dashboard-wrapper">
                <div className="d-flex flex-wrap flex-xl-nowrap">

                    <AgentSidebar />
                    <div className="page-content">
                        <AgentHeader />

                        <div className="agent-row margin-re-top">

                          
                            <div className="col-md-12">
                                <div className="card mb-6">
                                    <div className="card-body px-6 pt-6 pb-5">
                                        <h3 className="card-title mb-0 text-heading fs-22 lh-15 pb-3">Property Contact</h3>
                                        <div className="row ">
                                            <div className="col-md-12 mb-5">
                                                <label htmlFor="Builder" className="text-heading">Builder</label>
                                                <SearchDropdown type="Builder" setData={(e) => setBuilder([...e])}
                                                    onButtonClick={() => {
                                                        setTypeModal('Builder')
                                                        setIsAddNew(true)
                                                    }} value={builder} />
                                            </div>


                                            <div className="col-md-12 mb-5">
                                                <label htmlFor="Architect" className="text-heading">Architect</label>
                                                <SearchDropdown type="Architect" setData={(e) => setArchitect([...e])}
                                                    onButtonClick={() => {
                                                        setTypeModal('Architect')
                                                        setIsAddNew(true)
                                                    }} value={architect} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mb-5">
                                                <label htmlFor="Marketing Company" className="text-heading">Marketing Company</label>
                                                <SearchDropdown type="Marketing Company" setData={(e) => setMarketingCompany([...e])}
                                                    onButtonClick={() => {
                                                        setTypeModal('Marketing')
                                                        setIsAddNew(true)
                                                    }} value={marketingCompany} />
                                            </div>

                                            <div className="col-md-12 mb-5">
                                                <label htmlFor="Sales Company" className="text-heading">Sales Company</label>
                                                <SearchDropdown type="Sales Company" setData={(e) => setSalesCompany([...e])}
                                                    onButtonClick={() => {
                                                        setTypeModal('Sales')
                                                        setIsAddNew(true)
                                                    }} value={salesCompany} />
                                            </div>

                                            <div className="col-md-12 mb-5">
                                                <label htmlFor="Interior Designer" className="text-heading">Interior Designer</label>
                                                <SearchDropdown type="interior Designer" setData={(e) => {
                                                    console.log(e,'e');
                                                    setInteriorDesigner([...e])}}
                                                    onButtonClick={() => {
                                                        setTypeModal('interior')
                                                        setIsAddNew(true)
                                                    }} value={interiorDesigner} />
                                                    
                                            </div>


                                        </div>


                                        {
                                            isAddNew ?
                                                <Modal show={isAddNew} className="modal-add-contact">
                                                    <Modal.Header>
                                                        <ImCross onClick={HandleClose} className="button-cross-modal-add-contact" />
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className="form-row mx-n4">
                                                            <div className="form-group col-md-6 px-4 mb-5">
                                                                <label htmlFor="firstName" className="text-heading">Name</label>
                                                                <input type="text" className="form-control form-control-lg border-0" id="firstName"
                                                                    name="firstname" placeholder="Enter Name" onChange={(e) => setName(e?.target?.value)} value={name} />
                                                            </div>

                                                            <div className="form-group col-md-6 px-4 mb-5">
                                                                <label htmlFor="role" className="text-heading">Designation</label>
                                                                <input type="text" className="form-control form-control-lg border-0" id="role"
                                                                    name="role" placeholder="Enter Designation" onChange={(e) => setDesignation(e?.target?.value)} value={designation} />
                                                            </div>

                                                            <div className="form-group col-md-6  mb-5 px-4">
                                                                <label htmlFor="email" className="text-heading">Email</label>
                                                                <input type="email" className="form-control form-control-lg border-0" id="email"
                                                                    name="email" placeholder="Enter Email" onChange={(e) => setEmail(e?.target?.value)} value={email} />
                                                            </div>
                                                        </div>
                                                        <button type="button" className="btn bg-blue text-white hover-saffron" onClick={() => createUser()}>Submit</button>
                                                    </Modal.Body>
                                                </Modal>
                                                : null
                                        }




                                       
                                    </div>
                                </div>
                                <button type="button" className="btn bg-blue text-white hover-saffron mr-bo-20" onClick={ContactProperty}>Submit</button>
                            </div>

                          
                            <div className="row">
                                {
                                    getDataContact?.map((item) => {
                                        return (
                                            <div className="col-sm-6 col-md-4 col-lg-3 mb-6">

                                                <div className="card shadow-hover-xs-4 agent-3 remove-bg min-height-240">

                                                    <div className="card-header text-center pt-6 pb-3 bg-transparent text-center">

                                                        <a className="d-block fs-16 lh-2 text-dark mb-0 font-weight-500 hover-primary">{item?.name}</a>
                                                        <p className="mb-2">{item?.designation}</p>

                                                    </div>
                                                    <div className="card-body text-center pt-2  px-0">
                                                        <a href="" className="text-body">{item?.email}</a>

                                                    </div>
                                                    <div className="card-footer px-0 text-center hover-image border-0">
                                                        <button className="btn btn-danger" onClick={() => DeleteProperty(item?.id)} >Delete</button>
                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    })
                                }

                            </div>

                        </div>
                    </div>
                </div >
            </div >




        </>
    )
}
export default AgentPropAddContact;