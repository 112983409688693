import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BsFillBookmarkHeartFill } from "react-icons/bs";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader";
import { ApiUrl, getToken, removeUserSession } from "../../utils/common";
import CurrencyFormat from "react-currency-format";
// import { MainApiProtectedVersion } from "../../utils/axios/requests";

const UserPropertyPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [floorAdd, setfloorAdd] = useState(false)
  const [floorProperty, setProperty] = useState([])

  const PropertyId = floorProperty.id
  const [getData, setGetData] = useState([]);
  const [favouriteProperty, setFavouriteProperty] = useState([])
  const [getDropwonData, setGetDropdownData] = useState();
  const [deleteData, setDeleteData] = useState()
  // modal state
  const [show, setShow] = useState(false);
  const OnhideModal = () => setShow(false)
  // get all properties
  // console.log("############################",deleteData);
  const [floorNo, setFloorNo] = useState(1)
  const [description, setDescription] = useState('');
  const [imgFile, setImageFile] = useState();
  const [isMyProfile, setIsMyProfile] = useState('');
  const [buildingId, setBuildingId] = useState()

  // append array in building type start
  const [inputList, setInputList] = useState([])
  const [numberBuilding, setNumberBuilding] = useState();
  const [buildingName, setBuildingName] = useState('');
  const [dataCheckInput, setDataCheckInput] = useState();

  // append array in building type end
  const PorfileImgHandler = (e) => {
    const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(2);
    if (size > 2) {
      toast.error("Please select image size less than 2 MB")
      return;
    }
    // if (e?.target?.files.length !== 0) {
    setIsMyProfile(URL.createObjectURL(e?.target?.files[0]));
    setImageFile(e?.target?.files[0]);
    // }

  }

  const HandleInputChange = (e, index, file) => {
    // setBuildingName(e?.target?.value)
    // setFloorNo(e?.target?.value)
    // setDescription(e?.target?.value)
    // setDataCheckInput((e?.target?.value))
    console.log(e, 'e');
    const { name, value } = e?.target
    let imageFile
    if (file) {
      imageFile = e?.target?.files?.[0]
    }
    let list = Array.from(inputList);
    list[index] = {
      ...list[index],
      [name]: file ? imageFile : value
    };
    console.log(list, 'list59');
    setInputList(list);
  }
  const HandleSelect = (e) => {
    setNumberBuilding(e?.target?.value)
    setBuildingId(e?.target?.value)
    // setFloorNo(e?.target?.value)
    // setDescription(e?.target?.value)
    const finalArray = Array(Number(1)).fill({ floorNo: '', description: '', file: '' })
    setInputList(finalArray)

  }

  // floor plan api
  const SubmitPlan = () => {
    if (!ValidateFields()) return
    const data = {}
    const formData = new FormData()
    // formData?.append('building_no', numberBuilding);
    // formData?.append('building_name', buildingName);
    formData.append('description', inputList?.[0]?.description)
    formData.append('floor', inputList?.[0]?.floorNo)
    formData.append('property_id', PropertyId)
    formData.append('building_id', buildingId)
    if (imgFile) {
      formData.append('media_path', imgFile)
    }

    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${getToken()}`
      },
      data: formData,
      url: `${ApiUrl()}/api/property/property-floor-plan/${PropertyId}`
    };

    axios(option)
      .then(e => {
        toast.success("Floor Plan Added Successfull");
        window.location.reload();
        setfloorAdd(false)
      })
      .catch(err => {

      })


  }

  const ValidateFields = () => {

    if (!numberBuilding) {
      toast.error("Please Select Building")
      return false;
    }
    if (!inputList?.[0]?.floorNo) {
      toast.error("Please Add Floor Number")
      return false;
    }
    if (!imgFile) {
      toast.error("Please Select Image")
      return false;
    }
    return true;
  }

  // async function GetMyProperties() {
  //   setLoading(true);
  //   const obj = new MainApiProtectedVersion();
  //   const res = await obj.requstGetProperty()
  //   console.log(res)
  //   if (res.status = "200") {
  //     setLoading(false);
  //     setGetData(res.data)
  //     toast.warning("You can add media, floor plans and contacts");

  //   }
  //   else {
  //     setLoading(false);

  //   }
  // }

  // useEffect(() => {
  //   GetMyProperties()
  // }, [])


  const state = useLocation();
  const UploadMediaData = (item) => {
    navigate('/devimageuploader', { state: { item } })
  }

  const UploadFloorPlan = (item) => {
    setfloorAdd(true)
    setProperty(item)
    // navigate('/floorplan', { state: { item } })
  }

  const EditProperty = (item) => {
    // navigate('/devaddproperty', { state: { item, getDropwonData: getDropwonData } })
    navigate('/property/edit/', { state: { item, getDropwonData: getDropwonData } })

  }

  const AddContacts = (item) => {

    navigate('/property/contact', { state: { item } })
  }


  // async function PropertyDropdowns() {
  //   setLoading(true);
  //   const obj = new MainApiProtectedVersion();
  //   const res = await obj.requstConfigProperty()
  //   if (res.status = "200") {
  //     console.log(res.data)
  //     // setLoading(false);
  //     setGetDropdownData(res.data)
  //   }
  //   else {
  //     console.log('erro')

  //   }
  // }
  // const PropertyDropdowns = () => {
  //   const data = {}
  //   const option = {
  //     method: 'GET', headers: {
  //       'access-control-allow-origin': '*',
  //       'content-type': 'application/json',
  //     },
  //     url: `${ApiUrl()}/api/property/config-property`
  //   };

  //   axios(option)
  //     .then(e => {
  //       // setGetDropdownData(e?.data)
  //       setGetDropdownData(e?.data)

  //     })
  //     .catch(err => {

  //     });
  // }
  // useEffect(() => {
  //   PropertyDropdowns()
  // }, []);



  
  // const DeleteProperty = () => {
  //   const data = {}
  //   const option = {
  //     method: 'DELETE', headers: {
  //       'access-control-allow-origin': '*',
  //       'content-type': 'application/json',
  //       'Authorization': `Bearer ${getToken()}`
  //     },
  //     data: data,
  //     url: `${ApiUrl()}/api/property/data/${deleteData?.id}`
  //   };
  //   axios(option)
  //     .then(e => {
  //       if (e?.data === "") {
  //         toast.success("Property Deleted Successfull")
  //         GetMyProperties()
  //         setShow(false)
  //       }
  //     })
  //     .catch(err => {
  //       if (err?.request?.status === 401) {
  //         removeUserSession();
  //         navigate('/')
  //         setShow(false)
  //       }
  //     })
  // }
  // add to favourites api
  const AddToFavourites = (item) => {
    const data = {}
    data.property = item?.id;
    data.user = item?.user?.id

    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      data: JSON.stringify(data),
      url: `${ApiUrl()}/api/property/cached-properties`
    };
    axios(option)
      .then(e => {
        if (e?.status === 200) {
          toast.success("Added Property To Favourites")
          navigate('/favourite')
        }
        console.log(e, 'e139');
      })
      .catch(err => {
        console.log(err, 'err142');
      })
  }
  const DeleteConfirmation = (item) => {
    setShow(true);
    setDeleteData(item)
  }

  const FavouritePropertyCheck = () => {
    setLoading(true)
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/api/property/cached-properties-id`
    };
    axios(option)
      .then(e => {
        setFavouriteProperty(e?.data)
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      })
  }

  useEffect(() => {
    FavouritePropertyCheck()
  }, [])

  const allIds = favouriteProperty?.map((i) => i?.property);

  const DeleteFavouriteProperty = (item) => {
    const option = {
      method: 'DELETE', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/api/property/cached-properties/${item?.id}`
    };
    axios(option)
      .then((e) => {
        window?.location?.reload();
      })
      .catch((err) => {

      })
  }
  return (
    <>
      {
        loading ? <Loader /> : null
      }
      {floorAdd ? (

        <>
          <main id="content" className="bg-gray-01 margin-re-top">
            <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
              <div className="row">
                <div className="col-md-12">
                  <div className="card mb-6">
                    <div className="card-body px-6 pt-6 pb-5">
                      <h3 className="card-title mb-0 text-heading fs-22 lh-15">Floor Plan</h3>
                      <div className="col-md-3 col-lg-3 col-xxl-3 px-2 mb-4 mb-md-0">
                        <div className="form-group mb-0">
                          <label htmlFor="category"
                            className="text-heading margin-b-0">Buildings <span className="star-mandatoryfield">*</span></label>
                          <select className="form-control border-0 shadow-none form-control-lg selectpicker"
                            title="Select" data-style="btn-lg py-2 h-52"
                            id="category" name="category" onChange={HandleSelect}>
                            <option>Select Building</option>
                            {
                              floorProperty?.property_building?.map((item) => {
                                return (
                                  <option value={item?.id}>{item?.name}</option>
                                )
                              })
                            }
                          </select>
                        </div>
                      </div>

                      {
                        inputList?.map((x, i) => {
                          return (
                            <div className="row ml-0">
                              {/* building name  */}
                              {/* <div className="form-group mb-0 col-md-3">
                                                                    <label htmlFor="category"
                                                                        className="text-heading">Building Name</label>
                                                                    <input type="text" name="building_name"
                                                                        value={x?.buildingName}
                                                                        className="form-control form-control-lg border-0"
                                                                        placeholder="Enter Building Name"
                                                                        onChange={(e) => HandleInputChange(e, i)} />
                                                                </div> */}
                              {/* no of floors */}
                              <div className="form-group mb-0 col-md-4">
                                <label htmlFor="category"
                                  className="text-heading">Floors Number <span className="star-mandatoryfield">*</span></label>
                                <input type="number" name="floorNo"
                                  className="form-control form-control-lg border-0"
                                  placeholder="Enter Floors"
                                  value={x?.floorNo}
                                  onChange={(e) => HandleInputChange(e, i)} />
                              </div>
                              <div className="form-group mb-0 col-md-4 mt-2">
                                <label htmlFor="role" className="text-heading">Description</label>
                                <input type="text" className="form-control form-control-lg border-0" id="role"
                                  name="description" onChange={(e) => HandleInputChange(e, i)} />
                              </div>

                              <div className="col-md-4 mb-0 img-uploader-cnt-inf">
                                {
                                  imgFile || isMyProfile ? <img src={isMyProfile} alt="image" className="w-100 add-border-other" /> : null
                                }

                                <div className="custom-file mt-4 h-auto">

                                  <input type="file" className="custom-file-input add-pstion-abs" id="customFile"
                                    name="file" accept="image/*" onChange={(e) => { HandleInputChange(e, i); PorfileImgHandler(e) }} />
                                  <label className="btn bg-blue text-white hover-saffron btn-lg btn-block add-pd-label-uploader" htmlFor="customFile">
                                    <span className="d-inline-block mr-1"><i className="fal fa-cloud-upload"></i></span>Upload
                                    Floor Plan</label>
                                </div>
                              </div>

                            </div>
                          )
                        })
                      }


                    </div>
                  </div>
                </div>
              </div>
              <button className="btn btn-lg bg-blue text-white hover-saffron mb-3" onClick={SubmitPlan}>Submit</button>

              {/* <div className="d-flex justify-content-end flex-wrap">
                                    <button className="btn btn-lg bg-hover-white border rounded-lg mb-3">Delete Profile</button> 
                                    {
                                        buttonCheck ? <button className="btn btn-lg btn-primary ml-4 mb-3" onClick={UpdateFields}>Save Profile</button> : <button className="btn btn-lg btn-primary ml-4 mb-3" onClick={SubmitData}>Update Profile</button>
                                    }
                                </div> */}
            </div>
          </main>

        </>
      ) : (
        <>

          <main id="content" className="bg-gray-01 margin-re-top">
            <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
              <div className="d-flex flex-wrap flex-md-nowrap mb-6">
                <div className="mr-0 mr-md-auto">
                  <h2 className="mb-0 text-heading fs-22 lh-15">My Properties
                    <span className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">{getData?.length}</span>
                  </h2>
                  {/* <p>Lorem ipsum dolor sit amet, consec tetur cing elit. Suspe ndisse suscipit</p> */}
                </div>
                <div className="form-inline justify-content-md-end mx-n2">
                  <div className="p-2">

                  </div>

                </div>
              </div>
              {

                getData?.map((item, index) => {
                  const isFavourite = allIds?.includes(item?.id);
                  return (
                    <div className="card mb-6 header-r-logout">
                      <div className="card-body p-6">
                        <div className="col-sm-4">
                          {/* <div onClick={() => FullPropertyPage(item)}> */}
                          <Link to={`/property/details/${item.id}`} >


                            <a href="">
                              <img src={item?.featured_image}
                                alt="Home in Metric Way" />
                            </a>

                          </Link>
                          {/* </div> */}
                          <div className="media-body margin-r-top-5">
                            <a href="" className="text-dark margin-r-top-5">
                              <h5 className="fs-16 mb-1 lh-18"><span className="text-heading font-weight-bold fs-16">Property Name:</span> {item?.name}</h5>
                            </a>
                            <p className="line-hproperty font-weight-500 margin-r-top-5"><span className="text-heading font-weight-bold fs-16">Address:</span> {item?.street_name}</p>
                            <span className="text-heading lh-15 font-weight-bold fs-17 margin-r-top-5">Price:
                              {
                                item.price_from ? <CurrencyFormat value={item.price_from} displayType='text' thousandSeparator={true} prefix={'$'} /> : <CurrencyFormat value={item.lease_rate} displayType='text' thousandSeparator={true} prefix={'$'} />
                              }


                            </span>
                            {/* <span className="text-gray-light">/month</span> */}
                          </div>
                          <p className="margin-r-top-5">Published Date: {moment(item?.created_at).format('DD-MMM-YYYY')}</p>
                          <div className="margin-r-top-5">
                            <a data-bs-toggle="tooltip" title="Edit" className="d-inline-block fs-18 text-muted   hover-saffron-text" onClick={() => EditProperty(item)}><i
                              className="fal fa-pencil-alt"></i></a>
                            <a data-bs-toggle="tooltip" title="Delete" className="d-inline-block fs-18 ml-1 text-muted hover-saffron-text " onClick={() => DeleteConfirmation(item)}><i
                              className="fal fa-trash-alt"></i></a>
                            <a data-bs-toggle="tooltip" title="Favourite Property"
                              className={`d-inline-block fs-18 ${isFavourite ? '' : 'text-muted'} hover-saffron-text ml-1`}
                              onClick={() => {
                                if (isFavourite) {
                                  DeleteFavouriteProperty(item)
                                } else {
                                  AddToFavourites(item)
                                }
                              }}
                              style={{ color: isFavourite ? '#EE9141' : '#ababab' }}><BsFillBookmarkHeartFill /></a>
                          </div>
                          {/* <button type="button" className="btn bg-blue text-white hover-saffron margin-r-top-5 mr-1" onClick={() => UploadMediaData(item)}>Add Media</button>
                      <button type="button" className="btn bg-blue text-white hover-saffron margin-r-top-5 mr-1" onClick={() => UploadFloorPlan(item)}>Add Floor Plan</button>
                      <button type="button" className="btn bg-blue text-white hover-saffron margin-r-top-5 mr-1" onClick={() => AddContacts(item)}>Add Contacts</button> */}

                          <Modal className="modal-add-contact"
                            show={show} >
                            <Modal.Body>
                              <h3 className="submit-property-modal-heading">Are you sure to delete this Property?</h3>
                            </Modal.Body>

                            <Modal.Footer>
                              <Button className="bg-blue text-white hover-saffron border-0">
                              {/* onClick={() => DeleteProperty()} */}
                                Yes
                              </Button>

                              <Button className="bg-blue text-white hover-saffron border-0" onClick={() => OnhideModal()}>
                                No
                              </Button>
                            </Modal.Footer>
                          </Modal>

                        </div>

                      </div>
                    </div>
                  )
                })
              }

              <div className="table-responsive header-user-icon">
                <table className="table table-hover bg-white border rounded-lg">
                  <thead className="thead-sm thead-black">
                    <tr>
                      <th scope="col" className="border-top-0 px-6 pt-5 pb-4" style={{ width: "400px" }}>Listing title</th>
                      <th scope="col" className="border-top-0 pt-5 pb-4">Date Published</th>
                      <th scope="col" className="border-top-0 pt-5 pb-4">Action</th>
                      <th scope="col" className="border-top-0 pt-5 pb-4">Media</th>
                    </tr>
                  </thead>
                  <tbody>
                    {

                      getData?.map((item, index) => {

                        const isFavourite = allIds?.includes(item?.id);
                        // console.log(isFavourite, 'isFavourite');
                        return (
                          <tr className=" shadow-hover-xs-2 bg-hover-white">

                            <td className="align-middle pt-6 pb-4 px-3 table-td">
                              <div className="media">
                                <div className="w-120px mr-4 position-relative">
                                  <Link to={`${item.id}`} >



                                    <a href="">
                                      <img src={item?.featured_image}
                                        alt="Home in Metric Way" className="image_property" />
                                    </a>

                                  </Link>

                                  {/* <div onClick={() => FullPropertyPage(item)}>
                                <a href="">
                                  <img src={item?.featured_image}
                                    alt="Home in Metric Way" className="image_property" />
                                </a>
                              </div> */}
                                  <span className="badge badge-indigo position-absolute pos-fixed-top">{item?.labels?.find(i => i?.id)?.name}</span>
                                </div>
                                <div className="media-body">
                                  <a href="" className="text-dark ">
                                    <h5 className="fs-16 mb-1 lh-18">{item?.name}</h5>
                                  </a>
                                  <p className="line-hproperty font-weight-500">{item?.street_name}</p>
                                  <span className="text-heading lh-15 font-weight-bold fs-17">
                                    {
                                      item.price_from ? <CurrencyFormat value={item.price_from} displayType='text' thousandSeparator={true} prefix={'$'} /> : <CurrencyFormat value={item.lease_rate} displayType='text' thousandSeparator={true} prefix={'$'} />
                                    }


                                  </span>
                                  {/* <span className="text-gray-light">/month</span> */}
                                </div>
                              </div>
                            </td>
                            <td className="align-middle table-td">{moment(item?.created_at).format('DD-MMM-YYYY')}</td>

                            {/* <td className="align-middle">
                                <span
                                  className="badge text-capitalize font-weight-normal fs-12 badge-yellow">{item?.construction_status?.name}</span>
                              </td> */}
                            {/* <td className="align-middle">2049</td> */}
                            <td className="align-middle table-td">
                              <a data-bs-toggle="tooltip" title="Edit" className="d-inline-block fs-18 text-muted   hover-saffron-text" onClick={() => EditProperty(item)}><i
                                className="fal fa-pencil-alt"></i>
                              </a>

                              <a data-bs-toggle="tooltip" title="Delete" className="d-inline-block fs-18 ml-1 text-muted hover-saffron-text " onClick={() => DeleteConfirmation(item)}><i
                                className="fal fa-trash-alt"></i></a>
                              <a data-bs-toggle="tooltip" title="Favourite Property"
                                className={`d-inline-block fs-18 ${isFavourite ? '' : 'text-muted'} hover-saffron-text ml-1`}
                                onClick={() => {
                                  if (isFavourite) {
                                    DeleteFavouriteProperty(item)
                                  } else {
                                    AddToFavourites(item)
                                  }
                                }}
                                style={{ color: isFavourite ? '#EE9141' : '#ababab' }}><BsFillBookmarkHeartFill /></a>
                            </td>

                            <td className="align-middle table-td">

                              <Link
                                to={{
                                  pathname: 'upload',
                                  state: { state: item },
                                }}
                              >
                                <button type="button" className="btn bg-blue text-white hover-saffron mr-1" >Add Media</button>
                              </Link>
                              {/* <Link
                                to={{
                                  pathname: 'floor/add',
                                  state: item,
                                }}
                              > */}
                              <button type="button" className="btn bg-blue text-white hover-saffron mr-1 " onClick={() => UploadFloorPlan(item)} >Add Floor Plan</button>
                              {/* </Link>    */}

                              <Link
                                to={{
                                  pathname: '/property/contact',
                                  state: { state: item },
                                }}
                              >
                                <button type="button" className="btn bg-blue text-white hover-saffron mr-1" >Add Contacts</button>
                              </Link>
                              {/* <Link to="/devimageuploader"> */}
                              {/* <button type="button" className="btn bg-blue text-white hover-saffron" onClick={() => UploadMediaData(item)}>Add Media</button>

                                <button type="button" className="btn bg-blue text-white hover-saffron ml-1" onClick={() => UploadFloorPlan(item)}>Add Floor Plan</button>

                                <button type="button" className="btn bg-blue text-white hover-saffron ml-1" onClick={() => AddContacts(item)}>Add Contacts</button> */}
                              {/* </Link> */}


                            </td>

                          </tr>
                        )
                      })
                    }
                    <Modal className="modal-add-contact"
                      show={show} >
                      <Modal.Body>
                        <h3 className="submit-property-modal-heading">Are you sure to delete this Property?</h3>
                      </Modal.Body>

                      <Modal.Footer>
                        <Button className="bg-blue text-white hover-saffron border-0" >
                        {/* onClick={() => DeleteProperty()} */}
                          Yes
                        </Button>

                        <Button className="bg-blue text-white hover-saffron border-0" onClick={() => OnhideModal()}>
                          No
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </tbody>
                </table>
              </div>
            </div>
          </main>

        </>

      )}


    </>
  )
}
export default UserPropertyPage;