import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../interceptor/axiosInstance';
import './PaymentTable.css';

const CompletedPaymentComponent = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = (page) => {
    setIsLoading(true);
    axiosInstance.get(`${API_URL}/account/to/incentive/transaction/?page=${page}`)
      .then(response => {
        setData(response.data.results);
        setTotalPages(Math.ceil(response.data.count / 10)); // assuming 10 items per page
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className="table-responsive">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <table className="table table-hover table-striped table-bordered creative-table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">From Full Name</th>
                <th scope="col">From Email</th>
                <th scope="col">Status</th>
                <th scope="col">Incentive</th>
                <th scope="col">Completed Time</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1 + (currentPage - 1) * 10}</th>
                    <td>{item.from_user_name}</td>
                    <td>{item.from_user_email}</td>
                    <td>Completed</td>
                    <td>Rs. {item.incentive}</td>
                    <td>{new Date(item.create_at).toLocaleString()}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">No completed payments</td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="pagination-controls">
            <button 
              disabled={currentPage === 1} 
              onClick={() => handlePageChange(currentPage - 1)}
              className="btn btn-primary mr-2"
            >
              Previous
            </button>
            <span>Page {currentPage} of {totalPages}</span>
            <button 
              disabled={currentPage === totalPages} 
              onClick={() => handlePageChange(currentPage + 1)}
              className="btn btn-primary ml-2"
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default CompletedPaymentComponent;
