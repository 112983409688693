import React from 'react'
import { Card, Container, Col } from 'react-bootstrap';
import "../../mlm/Components/GraphPages/TreeStyles.css"
import GraphComponents from '../Components/GraphPages/GraphComponents';


function Graph() {
  return (


    <>
    <center><h1> Graph </h1></center>
          <div className='container'>
    <GraphComponents />
    </div>
        

    </>
  )
}

export default Graph