import axios from "axios";
import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiUrl } from "../../utils/common";
// import BackLineImage from "../../../src/images/bg_circle.png";


const ResetPassWord = () => {
    const navigate = useNavigate()
    const [password,setPassword] = useState();
    const [confirmPassword,setConfirmPassword] = useState()

    const path = window?.location?.search;

    const token = path?.split("=")?.[2]
    const findUid = path?.split("=")?.[1]?.split("?")?.[0] 
    


const Submit = () => {
    const data = {}
    data.password = password
    data.password2 = confirmPassword

    const option = {
        method: 'POST', headers: {
            'access-control-allow-origin': '*',
            'content-type': 'application/json',
        },
        data: JSON.stringify(data),
        url: `${ApiUrl()}/auth/user/reset-password/${findUid}/${token}`
    }

    axios(option)
        .then(e => {    
            const data = JSON.parse(e?.data?.data || '{}');
            if (e?.status === 200) {
                toast.success(e?.data?.sucess)
                navigate('/login')
            }
        })
        .catch(err => {

        })
}

    return (
        <>
            <section className=" modal-opacity-none ">
            {/* style={{ backgroundImage: `url(${BackLineImage})`, backgroundRepeat:'repeat-y', padding:'180px' }} */}
                <div className="container">
                    <div className="text-center mb-5 mr-6">
                    <img style={{width:'300px'}} src="images/dpf-logo.png" /></div>
                    <div className="row">
                        <div className="col-lg-7 mx-auto">
                            <div className="card border-0 shadow-xxs-2 login-register">
                                <div className="card-body p-6">
                                    <h2 className="card-title fs-30 font-weight-600 text-dark lh-16 mb-2">Reset password?</h2>

                                    <form className="form">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="password" className="text-heading">Password</label>
                                                    <input type="password" name="password"
                                                        className="form-control form-control-lg border-0"
                                                        id="password" onChange={(e) => setPassword(e?.target?.value)} value={password} />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="password" className="text-heading">Confirm Password</label>
                                                    <input type="password" name="password"
                                                        className="form-control form-control-lg border-0"
                                                        id="password" onChange={(e) => setConfirmPassword(e?.target?.value)} value={confirmPassword} />
                                                </div>
                                            </div>


                                        </div>
                                        <button type="button" className="btn bg-blue text-white hover-saffron btn-lg rounded" onClick={Submit}>Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ResetPassWord;