import React from "react";
import OBHeader from "./obheader";
import OBSidebar from '../../Components/OBsidebar/obsidebar';
import OBAdminDash from "./obadmindashboard";
const OBDashBaord = () => {
    return (
        <>
            <div className="wrapper dashboard-wrapper">
                <div className="d-flex flex-wrap flex-xl-nowrap">

                    <OBSidebar />


                    <div className="page-content">
                        <OBHeader />
                        <OBAdminDash />

                    </div>

                </div>
            </div>
        </>
    )
}
export default OBDashBaord;