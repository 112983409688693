import React, { useEffect, useState } from 'react';
import axiosInstance from '../../interceptor/axiosInstance';
import { toast } from 'react-toastify';

const AmountTransactions = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [withdrawalDetails, setWithdrawalDetails] = useState({
    payment: '',
    account_number: '',
    ifsc_code: '',
    upi_details: '',
    payment_type: 'account',  // Default to account
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [showModal, setShowModal] = useState(false); // Modal state

  const fetchData = (page) => {
    setIsLoading(true);
    axiosInstance
      .get(`${API_URL}/payment/withdraw/history/?page=${page}`)
      .then((response) => {
        setData(response.data.results);
        setTotalPages(Math.ceil(response.data.count / 10));
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleWithdraw = () => {
    setIsProcessing(true);
    axiosInstance
      .post(`${API_URL}/payment/withdraw/`, withdrawalDetails)
      .then((response) => {
        // Once withdrawal is successful, fetch the updated transaction history
        fetchData(currentPage);
        // Close the modal after success
        setShowModal(false);
        // Optionally clear the form or show a success message
        toast.success('Payment withdrawn successfully');
      })
      .catch((error) => {
        console.error('Error processing withdrawal:', error);
        toast.error('Error processing withdrawal');
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWithdrawalDetails({
      ...withdrawalDetails,
      [name]: value,
    });
  };

  const handlePaymentTypeChange = (e) => {
    const { value } = e.target;
    setWithdrawalDetails({
      ...withdrawalDetails,
      payment_type: value,
    });
  };

  return (
    <>
      <br />
      <div className="container mt-4">
        <h2 className="text-center mb-4">Payment Transaction Details</h2>
        
        <button
          className="btn btn-primary mb-3"
          onClick={() => setShowModal(true)}
        >
          Withdraw Payment
        </button>

        {/* Modal for Withdrawal */}
        <div
          className={`modal fade ${showModal ? 'show' : ''}`}
          tabIndex="-1"
          style={{ display: showModal ? 'block' : 'none' }}
          aria-hidden={!showModal}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Withdraw Payment</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label>Select Payment Type</label>
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="payment_type"
                      value="account"
                      checked={withdrawalDetails.payment_type === 'account'}
                      onChange={handlePaymentTypeChange}
                    />
                    <label className="form-check-label">Account</label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="payment_type"
                      value="upi"
                      checked={withdrawalDetails.payment_type === 'upi'}
                      onChange={handlePaymentTypeChange}
                    />
                    <label className="form-check-label">UPI</label>
                  </div>
                </div>

                <div className="form-group">
                  <label>Payment Amount</label>
                  <input
                    type="text"
                    className="form-control"
                    name="payment"
                    value={withdrawalDetails.payment}
                    onChange={handleChange}
                  />
                </div>

                {withdrawalDetails.payment_type === 'account' && (
                  <>
                    <div className="form-group">
                      <label>Account Number</label>
                      <input
                        type="text"
                        className="form-control"
                        name="account_number"
                        value={withdrawalDetails.account_number}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>IFSC Code</label>
                      <input
                        type="text"
                        className="form-control"
                        name="ifsc_code"
                        value={withdrawalDetails.ifsc_code}
                        onChange={handleChange}
                      />
                    </div>
                  </>
                )}

                {withdrawalDetails.payment_type === 'upi' && (
                  <div className="form-group">
                    <label>UPI Details</label>
                    <input
                      type="text"
                      className="form-control"
                      name="upi_details"
                      value={withdrawalDetails.upi_details}
                      onChange={handleChange}
                    />
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleWithdraw}
                  disabled={isProcessing}
                >
                  {isProcessing ? 'Processing...' : 'Withdraw'}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="table-responsive">
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <>
              <table className="table table-hover table-striped table-bordered creative-table">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Transaction</th>
                    <th scope="col">Payment</th>
                    <th scope="col">Account Number</th>
                    <th scope="col">UPI Details</th>
                    <th scope="col">Payment Status</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Updated At</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((item, index) => (
                      <tr key={item.id}>
                        
                        <th scope="row">
                          {index + 1 + (currentPage - 1) * 10}
                        </th>
                        <td>{item.transaction}</td>
                        <td>Rs. {item.payment}</td>
                        <td>{item.account_number || 'N/A'}</td>
                        <td>{item.upi_details || 'N/A'}</td>
                        <td>{item.payment_status}</td>
                        <td>{new Date(item.create_at).toLocaleString()}</td>
                        <td>{new Date(item.update_at).toLocaleString()}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center">
                        No transactions found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <div className="pagination-controls">
                <button
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                  className="btn btn-primary mr-2"
                >
                  Previous
                </button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                  className="btn btn-primary ml-2"
                >
                  Next
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <br />
    </>
  );
};

export default AmountTransactions;
