import React, { Component, FC, ReactNode, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import "./App.css";
import ForgotPassword from "./Components/Accounts/forgotpassword/forgotpassword";
import LandingPage from "./Pages/landingPage/landing";
import {
  BrowserRouter,
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import { useSelector } from "react-redux";

import DdashBaord from "./Pages/Ddashboard/devdashboard";
import DevMyProfile from "./Pages/Ddashboard/devmyprofile";
import ChangePassword from "./Components/Accounts/changepassword/changepassword";
import SuperAddProperty from "./Pages/SDashboard/superaddproperty";
import MyTeam from "./Pages/Ddashboard/myteam";
/* import Agent-dashboard Components */
import AgentDashBaord from "./Pages/Adashboard/agentdashboard";
import AgentAddProp from "./Pages/Adashboard/AgentAddProp";
import AgentMyProperty from "./Pages/Adashboard/agentmyproperty";
import AgentReviews from "./Pages/Adashboard/agentreviews";
import AgentInvoiceList from "./Pages/Adashboard/agentinvoicelist";
import AgentAddNewInvoice from "./Pages/Adashboard/agentaddnewinvoice";
import AgentEditInvoice from "./Pages/Adashboard/agenteditinvoice";
import AgentPreInvoice from "./Pages/Adashboard/agentpreinvoice";
import AgentMyProfile from "./Pages/Adashboard/agentmyprofile";
import AgentLeads from "./Pages/Adashboard/agentmyleads";
import AgentPropertyLeads from "./Pages/Adashboard/agentpropertyleads";
import AgentPropertyLeadDetails from "./Pages/Adashboard/agentpropleaddetails";
import AgentAreasServiced from "./Pages/Adashboard/agentareasserviced";
import AgentPropertyPreview from "./Pages/Adashboard/agentpropertypreview";
// import AgentMultiImageUploader from './Pages/Ddashboard/multiimguploader';
import AgentMultiImageUploader from "./Pages/Adashboard/agentmultiimguploader";
import AgentFloorPlansList from "./Pages/Adashboard/agentfloorplantlist";
import AgentFloorPlan from "./Pages/Adashboard/agentfloorplan";
import AgentPropAddContact from "./Pages/Adashboard/agentpropaddcontact";
import AgentMyMembers from "./Pages/Adashboard/agentmymembers";
import AgentTeam from "./Pages/Adashboard/agentteam";
import AgentSinglePropPage from "./Pages/Adashboard/agentsingleproppage";
import AgentProfile from "./Components/AgentPage/agentprofile";
import AgentPropSlideShow from "./Pages/Adashboard/agentimageslide";
import AgentMyfavProperty from "./Pages/Adashboard/agentmyfavproperties";
import AgentPropReview from "./Pages/Adashboard/agentpropreview";
import AgentPropertyReviewDetails from "./Pages/Adashboard/agentpropreviewdetails";
import AgentPortfolio from "./Pages/Adashboard/agentPortfolio";
/* import Components for other-business-developer */
// import OBDashBaord from './Pages/OBdashboard/obdashboard';
// import OBMyProfile from './Pages/OBdashboard/obmyprofile';
import DevMyAgent from "./Pages/Ddashboard/devmyagent";
import AccountVerification from "./Pages/AccountVerification";
import DevProfile from "./Pages/Ddashboard/devprofile";
import Login from "./Components/Accounts/login/login";
import SignUp from "./Components/Accounts/signup/signup";
import DevAddProp from "./Pages/Ddashboard/DevAddNewProp";
import ResetPassWord from "./Pages/Resetpassword/reset";
import DevMyProperty from "./Pages/Ddashboard/devmyproperty";
import DevPropertyPreview from "./Pages/Ddashboard/devpropertypreview";
import MultiImageUploader from "./Pages/Ddashboard/multiimguploader";
import FloorPlan from "./Pages/Ddashboard/devfloorplan";
import DevSinglePropertyPage from "./Pages/Ddashboard/devsinglepropertypage";
import FloorPlansList from "./Pages/Ddashboard/floorplanslist";
import AddContact from "./Pages/Ddashboard/addcontacts";
// import AutoCompletePart from './Components/Autocomplete/autocomplete';
import DeveloperProfile from "./Components/DeveloperPage/developerprofile";
import DevReviews from "./Pages/Ddashboard/devmyreviews";
import DevLeads from "./Pages/Ddashboard/devleads";
import DevPropertyLeads from "./Pages/Ddashboard/devpropertyleads";
import DevPropertyLeadDetails from "./Pages/Ddashboard/devpropleaddetails";
import DevMyfavProperty from "./Pages/Ddashboard/devmyfavproperty";
import DevPreInvoice from "./Pages/Ddashboard/devinvoicepre";
import DevPropReview from "./Pages/Ddashboard/devpropreview";
import DevPropertyReviewDetails from "./Pages/Ddashboard/devpropreviewdetails";
import DevPortfolio from "./Pages/Ddashboard/devportfolio";
// finder service
import FinderService from "./Components/FinderService/finderservice";
// subcription package
import SubcriptionPackages from "./PaymentPages/package";
import PaymentComplete from "./PaymentPages/paymentcomplete";
import PaymentFailed from "./PaymentPages/paymentfailed";
// obusers rotues
import OBuserProfile from "./Components/OBuserPages/obuserprofile";
import OBuserReviews from "./Pages/OBdashboard/obuserreviews";
import OBuserLeads from "./Pages/OBdashboard/obuserleads";
import OBDashBaord from "./Pages/OBdashboard/obdashboard";
import OBMyProfile from "./Pages/OBdashboard/obmyprofile";
import OBAreasServiced from "./Pages/OBdashboard/obareasservices";
// finder service detail
import FSUserDetails from "./Components/FinderService/fsuserdetail";
import SinglePropertyDetails from "./Components/SingleProperty/singlepropertydetails";
import PropImgSlideShow from "./Components/SingleProperty/propimageslider";
// stripe creds
import StripSignUp from "./Components/Accounts/stripCred/stripsignup";
import MultiContacts from "./Components/MultiContacts/multicontacts";
import MyPortfolio from "./Pages/OBdashboard/myportfolio";
// import DemoMap from './Components/DemoMap/DemoMap';
import DevMyPackage from "./Pages/Ddashboard/devmypackage";
import AgentMypackage from "./Pages/Adashboard/agemypackage";
import OtherMyPackage from "./Pages/OBdashboard/obmypackage";
import SubPackages from "./PaymentPages/subscribe";
// property list page
import PropertyList from "./Pages/PropertyPages/propertylist";
// import SinglePage from './Components/SingleProperty/singlepage';
import SinglePageGallery from "./Components/SingleProperty/singlepagegallery";
// import ImageGalleryControl from './Components/ImageGallery/imagegallery';
// new property page route
// import TestDevAddProp from './Pages/Ddashboard/testAddNewProp';
import TabsPartProperty from "./Components/Tabs/tabsproperty";

// admin imports
import {
  AdminAddNewInvoice,
  AdminAddProperty,
  AdminAdminReviews,
  AdminEditInvoice,
  AdminInvoiceList,
  AdminMyfavProperty,
  AdminMyPackage,
  AdminMyProfile,
  AdminPreInvoice,
  AdminSaveproperty,
  AdminUsers,
  CouponPage,
  PropertyPage,
  CustomerRequest,
  TaxPage,
  InvoicePage,
  LeadsPage,
  BlogPage,
} from "./Components/AdminPage";
import CreateBlog from "./Components/AdminPage/blog/CreateBlog";
import PlanPages from "./Components/AdminPage/plan/planPages";
import AdminBilling from "./Components/AdminPage/billing/Billing";
import Subscribers from "./Components/AdminPage/Subscribers/Subscribers";
import AdminPageLayout from "./Components/AdminPage/AdminPageLayout";
import AdminDdashBaord from "./Components/AdminPage/AdminDdashBaord";

import AboutUs from "./Pages/About-us/about-us";
import ContactUs from "./Pages/Contact us/Contact-us";
import AdminLogin from "./Components/Accounts/login/AdminLogin";
import PlanCreate from "./Components/AdminPage/plan/PlanCreate";
import CurrencyFormatType from "./Components/currencyformat/format";
import OtherInvoice from "./Pages/OBdashboard/otherInvoice";

// blog inports
import BlogList from "./Pages/Blog/Blog-list";
import BlogSinglePage from "./Pages/Blog/Blog-singlePage";

import { RootState } from "./rootReducer";
import PropertyDetailPage from "./Components/AdminPage/Property/PropertyDetailPage";
import AdminUserProfileDetail from "./Components/AdminPage/users/AdminUserProfileDetail ";
import { UserLayout } from "./Components/UserPage";
import DashBaord from "./Components/UserPage/dashboard";
import UserPropertyPage from "./User/Property/PropertPage";
import UserPropertyCreate from "./User/Property/PropertyCreate";
import UserLead from "./User/Leads/BusinessLead";
import Propertyleads from "./User/Leads/Propertyleads";
import BusinessLead from "./User/Leads/BusinessLead";
import PropertyLeads from "./User/Leads/Propertyleads";
import history from "./utils/history";

import {
  AreasServiced,
  ImageUploader,
  LeadDetails,
  Portfolio,
  Profile,
  ProfileEdit,
  ProfileReview,
  PropertyFavourite,
  PropertyPreview,
  PropertyReview,
  ProprtyAddContact,
  ProprtyFloorPlan,
  ReviewDetails,
  TeamPage,
} from "./User";
import TeamList from "./User/Team/Teampage";
import TeamAdd from "./User/Team/TeamAdd";
import { Col } from "react-bootstrap";
import RouteUrl from "./routes";
import PrivateRoutes from "./routes/PrivateRoutes";
import Auth from "./routes/auth";
import UserInvoicePage from "./User/invoice/invoicePage";
import SuperLandingPage from "./Pages/SuperlandingPage/superLandingPage";
import Graph from "./mlm/Pages/Graph";
import CompletedPayment from "./mlm/Pages/CompletedPayment";
import PendingPayment from "./mlm/Pages/PendingPayment";
import NonWorking from "./mlm/Pages/NonWorking";
import MyLevel from "./mlm/Pages/MyLevel";
import ClubMember from "./mlm/Pages/ClubMember";
import Subscription from "./mlm/Pages/Subscription";
import Lottery from "./mlm/Pages/Lottery";
import Donation from "./mlm/Pages/Donation";
import LearningPage from "./mlm/Pages/LearningPage";
import AllJoinedMember from "./mlm/Pages/AllJoinedMember";
import AmountTransactions from "./mlm/Pages/AmountTransactions";
import MemberPaymentPending from "./mlm/Pages/MemberPaymentPending";
type Props = {
  children?: React.ReactNode;
};

const UserPrivateOutlet: React.FC<Props> = ({ children }) => {
  let is_authenticated = localStorage.getItem("token");

  return is_authenticated ? ( //Check if logged in
    <>{children}</>
  ) : (
    <Navigate to="/login" replace /> //Go back to login if not logged in
  );
};

const PrivateOutlet: React.FC<Props> = ({ children }) => {
  let is_authenticated = useSelector((state: RootState) => {
    return state.authReducer["is_authenticated"];
  });

  return is_authenticated ? ( //Check if logged in
    <>{children}</>
  ) : (
    <Navigate to="/admin/login" replace /> //Go back to login if not logged in
  );
};

export interface IApplicationProps { }

const App: React.FunctionComponent<IApplicationProps> = () => {
  const maintenance = false;
  return (
    <div className="App">
      <ToastContainer theme="colored" />
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="mlm/donation" element={<Donation />} />
          <Route path="mlm/subscription" element={<Subscription />} />
          <Route path="mlm/learning" element={<LearningPage />} />


          <Route
            element={
              <>
                <UserPrivateOutlet />
                <UserLayout />
              </>
            }
          >
            {" "}
            <Route path="/user/invoice" element={<UserInvoicePage />} />
            <Route path="/dashboard" element={<DashBaord />} />


            <Route path="property">
              <Route path="contact" element={<ProprtyAddContact />} />
              {/* <Route path=":id" element={<SinglePropertyDetails />} /> */}
              <Route path="details" element={<UserPropertyCreate />} />
              <Route path="edit" element={<UserPropertyCreate />} />
              <Route path="" element={<UserPropertyPage />} />
              <Route path="create" element={<UserPropertyCreate />} />
              <Route path="upload" element={<ImageUploader />} />
              <Route path="preview" element={<PropertyPreview />} />

              <Route path="floor">
                <Route path="add" element={<ProprtyFloorPlan />} />
              </Route>
            </Route>


            
            <Route path="mlm">
              <Route path="graph" element={<Graph />} />
              {/* <Route path=":id" element={<SinglePropertyDetails />} /> */}
              <Route path="completed-payment" element={<CompletedPayment />} />
              <Route path="pending-payment" element={<PendingPayment />} />
              <Route path="non-working-plan" element={<NonWorking />} />
              <Route path="my-level" element={<MyLevel />} />
              <Route path="club-member" element={<ClubMember />} />
              <Route path="all/joined-member" element={<AllJoinedMember />} />
              <Route path="amount/transaction" element={<AmountTransactions />} />
              <Route path="member/payment/pending" element={<MemberPaymentPending />} />

              <Route path="lottery" element={<Lottery />} />
              
              <Route path="floor">
                <Route path="add" element={<ProprtyFloorPlan />} />
              </Route>
            </Route>




            <Route path="/favourite" element={<PropertyFavourite />} />
            <Route path="lead">
              <Route path="business/" element={<BusinessLead />} />?
              <Route path="property/" element={<PropertyLeads />} />
              <Route path="property/:id" element={<LeadDetails />} />
            </Route>
            <Route path="/team">
              <Route path="" element={<TeamList />} />
              <Route path="create" element={<TeamAdd />} />
            </Route>
            <Route path="review">
              <Route path="profile" element={<ProfileReview />} />
              <Route path="property" element={<PropertyReview />} />
              <Route path="property/detail/" element={<ReviewDetails />} />
            </Route>
            <Route path="/services/areas" element={<AreasServiced />} />
            {/* <Route path="profile"> */}
            {/* <Route path="" element={<Profile />} /> */}
            <Route path="profile/edit" element={<ProfileEdit />} />
            {/*  </Route> */}
            <Route path="/portfolio" element={<Portfolio />} />
            {/* ##################################################### */}
            {/* property list page */}
            <Route path="/devaddproperty" element={<DevAddProp />} />?
            {/* <Route path="/superadmindashboard" element={<S} */}
            {/* Routing for developer dashboard */}
            <Route path="/developerdashboard" element={<DdashBaord />} />
            <Route path="/devmyprofile" element={<DevMyProfile />} />
            <Route path="/MyTeam" element={<MyTeam />} />
            <Route path="/devmyagents" element={<DevMyAgent />} />
            <Route path="/devprofile" element={<DevProfile />} />
            {/* <Route path='/devaddproperty' element={<DevAddProperty />} /> */}
            <Route path="/devmyproperties" element={<DevMyProperty />} />
            <Route
              path="/devpropertypreview"
              element={<DevPropertyPreview />}
            />
            <Route
              path="/devimageuploader"
              element={<MultiImageUploader />}
            />
            <Route path="/devinvoicepreview" element={<DevPreInvoice />} />
            <Route path="/devportfolio" element={<DevPortfolio />} />
            <Route path="/floorplan" element={<FloorPlan />} />
            <Route
              path="/developersingleproperty"
              element={<DevSinglePropertyPage />}
            />
            {/* <Route path='/imageslide' element={<SlideShow />} /> */}
            <Route path="/floorplanlist" element={<FloorPlansList />} />
            <Route path="/propertyaddcontact" element={<AddContact />} />
            {/* <Route path='/autocomplete' element={<AutoCompletePart />} /> */}
            <Route path="/developerprofile" element={<DeveloperProfile />} />
            <Route path="/devreviews" element={<DevReviews />} />
            <Route path="/devleads" element={<DevLeads />} />
            <Route path="/devpropertyleads" element={<DevPropertyLeads />} />
            <Route
              path="/devpropertyleadsDetails"
              element={<DevPropertyLeadDetails />}
            />
            <Route path="/devmypackage" element={<DevMyPackage />} />
            <Route path="/devmyfavourite" element={<DevMyfavProperty />} />
            <Route path="/devpropreviews" element={<DevPropReview />} />
            <Route
              path="/propreviewdeatils"
              element={<DevPropertyReviewDetails />}
            />
            {/* <Route path='/map' element={<SimpleMap />} />  */}
            {/* Routing for agent dashboard */}
            <Route path="/agentdashboard" element={<AgentDashBaord />} />
            {/* <Route path="/agent-add-property" element={<AgentAddProperty />} /> */}
            <Route path="/agent-add-property" element={<AgentAddProp />} />
            <Route path="/agent-properties" element={<AgentMyProperty />} />
            <Route path="/agent-reviews" element={<AgentReviews />} />
            {/* <Route path="/agent-packages" element={<AgentMyPackage />} /> */}
            <Route
              path="/agent-update-profile"
              element={<AgentMyProfile />}
            />
            <Route path="/agent-lead" element={<AgentLeads />} />
            <Route
              path="/agent-property-leads"
              element={<AgentPropertyLeads />}
            />
            <Route
              path="/agent-property-leads-details"
              element={<AgentPropertyLeadDetails />}
            />
            <Route
              path="/agent-areas-serviced"
              element={<AgentAreasServiced />}
            />
            <Route
              path="/agentpropertypreview"
              element={<AgentPropertyPreview />}
            />
            <Route
              path="/agentsingleproperty"
              element={<AgentSinglePropPage />}
            />
            <Route
              path="/agentimageuploader"
              element={<AgentMultiImageUploader />}
            />
            <Route
              path="/agentfloorplanlist"
              element={<AgentFloorPlansList />}
            />
            <Route path="/agentfloorplan" element={<AgentFloorPlan />} />
            <Route
              path="/agentpropertyaddcontact"
              element={<AgentPropAddContact />}
            />
            <Route path="/agentmymembers" element={<AgentMyMembers />} />
            <Route path="/agentteam" element={<AgentTeam />} />
            <Route path="/agentprofile" element={<AgentProfile />} />
            <Route path="/agentimageslide" element={<AgentPropSlideShow />} />
            <Route path="/agentmypackage" element={<AgentMypackage />} />
            <Route
              path="/agentmyfavourite"
              element={<AgentMyfavProperty />}
            />
            <Route path="/agentpropreviews" element={<AgentPropReview />} />
            <Route
              path="/agentpropreviewdetails"
              element={<AgentPropertyReviewDetails />}
            />
            <Route path="/agentportfolio" element={<AgentPortfolio />} />
            {/* OBuser Routes */}
            <Route path="/obuserreviews" element={<OBuserReviews />} />
            <Route path="/obuserleads" element={<OBuserLeads />} />
            <Route path="/obdashboard" element={<OBDashBaord />} />
            <Route path="/other-admin-profile" element={<OBMyProfile />} />
            <Route path="/otheruserprofile" element={<OBuserProfile />} />
            <Route path="/otherservicesareas" element={<OBAreasServiced />} />
            <Route path="/myportfolio" element={<MyPortfolio />} />
            <Route path="/othermypackage" element={<OtherMyPackage />} />
            <Route path="/otherinvoice" element={<OtherInvoice />} />
            {/* finder service */}
            {/* finder service detail */}
            <Route
              path="/propertyimageslides"
              element={<PropImgSlideShow />}
            />
            {/* multi contacts check demo only */}
            <Route path="/multicontacts" element={<MultiContacts />} />
            <Route
              path="/singlepagegallery"
              element={<SinglePageGallery />}
            />
            <Route path="/superaddproperty" element={<SuperAddProperty />} />
            {/* route for new property add page */}
            {/* <Route path="/testaddprop" element={<TestDevAddProp />} /> */}
            <Route path="/tabsproperty" element={<TabsPartProperty />} />
            <Route
              path="/agent-invoice-list"
              element={<AgentInvoiceList />}
            />
            <Route
              path="/agent-add-invoice"
              element={<AgentAddNewInvoice />}
            />
            <Route
              path="/agengt-edit-invoice"
              element={<AgentEditInvoice />}
            />
            <Route
              path="/agent-preview-invoice"
              element={<AgentPreInvoice />}
            />
          </Route>
          {/* -------------------public url-------------------- */}
          {/* login routes */}
          <Route path="profile" element={<Profile />} />
          <Route path="property/:id" element={<SinglePropertyDetails />} />
          <Route
            path="property/details/:id"
            element={<SinglePropertyDetails />}
          />
          <Route path="user/details/:id" element={<FSUserDetails />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="resetpassword" element={<ResetPassWord />} />
          <Route path="changepassword" element={<ChangePassword />} />
          <Route
            path="account-verification/:id"
            element={<AccountVerification />}
          />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="blog-list" element={<BlogList />} />
          <Route path="blog-single/:id/" element={<BlogSinglePage />} />
          <Route path="propertylist" element={<PropertyList />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="finderservice" element={<FinderService />} />
          <Route path="userdetails" element={<FSUserDetails />} />
          <Route
            path="singlepropertydetails"
            element={<SinglePropertyDetails />}
          />

          {/* stripe Creds */}
          <Route path="/create-account" element={<StripSignUp />} />
          <Route path="/subpackages" element={<SubPackages />} />

          <Route path="/currency" element={<CurrencyFormatType />} />
          {/* <Route path="/imagegallery" element={<ImageGalleryControl />} /> */}
          {/* subciption packages */}
          <Route
            path="/subcriptionpackages"
            element={<SubcriptionPackages />}
          />
          <Route path="/paymentcompleted" element={<PaymentComplete />} />
          <Route path="/paymentfailed" element={<PaymentFailed />} />
          {/* -------------------public end-------------------- */}

          {/* Admin seaction and url routing */}

          {/* admin routes */}
          <Route path="/admin/login/" element={<AdminLogin />} />
          <Route
            path="/admin"
            element={
              <>
                <PrivateOutlet />
                <AdminPageLayout />
              </>
            }
          >
            <Route path="" element={<AdminDdashBaord />} />
            <Route path="blog">
              <Route path="createblog" element={<CreateBlog />} />
              <Route path="" element={<BlogPage />} />
              <Route path="delete/:Id" element={<BlogList />} />
              <Route path="edit/:Id" element={<CreateBlog />} />
              <Route path="create" element={<PlanCreate />} />
              <Route path="list" element={<AdminUsers />} />
            </Route>
            <Route path="user">
              <Route path="" element={<AdminUsers />} />
              <Route path=":userId" element={<AdminUserProfileDetail />} />
            </Route>
            <Route path="bills" element={<AdminBilling />} />
            <Route path="invoice">
              <Route path="" element={<InvoicePage />} />
              <Route path="delete/:Id" element={<PlanCreate />} />

              <Route path="edit/:Id" element={<PlanCreate />} />
              <Route path="create" element={<PlanCreate />} />

              <Route path="list" element={<AdminUsers />} />
            </Route>{" "}
            <Route path="plan">
              <Route path="" element={<PlanPages />} />
              <Route path="delete/:Id" element={<PlanCreate />} />

              <Route path="edit/:Id" element={<PlanCreate />} />
              <Route path="create" element={<PlanCreate />} />

              <Route path="tax">
                <Route path="" element={<TaxPage />} />
                <Route path="create" element={<CouponPage />} />
                <Route path=":Id" element={<CouponPage />} />
              </Route>

              {/* InvoicePage */}
              <Route path="coupon">
                <Route path="" element={<CouponPage />} />
                <Route path="create" element={<CouponPage />} />
                <Route path=":couponId" element={<CouponPage />} />
              </Route>
            </Route>
            {/* #############lead url ################## */}
            <Route path="lead">
              <Route path="" element={<LeadsPage />} />
              <Route path=":id" element={<SinglePropertyDetails />} />
            </Route>
            {/* #############lead url ################## */}
            <Route path="properties">
              <Route path="" element={<PropertyPage />} />
              <Route path=":id" element={<SinglePropertyDetails />} />
            </Route>
            <Route path="customer-request" element={<CustomerRequest />} />
            <Route path="subscription" element={<Subscribers />} />
            <Route path="add-new-property" element={<AdminAddProperty />} />
            <Route
              path="favourite-properties"
              element={<AdminMyfavProperty />}
            />
            <Route path="save-properties" element={<AdminSaveproperty />} />
            <Route path="reviews" element={<AdminAdminReviews />} />
            <Route path="listing-invoice" element={<AdminInvoiceList />} />
            <Route path="add-new-invoice" element={<AdminAddNewInvoice />} />
            <Route path="edit-invoice" element={<AdminEditInvoice />} />
            <Route path="preview-invoice" element={<AdminPreInvoice />} />
            <Route path="my-packages" element={<AdminMyPackage />} />
            <Route path="my-profile" element={<AdminMyProfile />} />
          </Route>
        </Routes>
       
      </Router>
    </div>
  );
};

export default App;
