import React, { useEffect, useState } from "react";
import OBHeader from "./obheader";
import OBSidebar from "../../Components/OBsidebar/obsidebar";
import { ApiUrl, GetId, getToken } from "../../utils/common";
import axios from "axios";
import ReactStars from "react-rating-stars-component";
import moment from "moment";
import Loader from "../../Components/Loader";
import Avatar from 'react-avatar';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const OBuserReviews = () => {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [ReviewGet, setReviewsGet] = useState([])
  const [loading, setLoading] = useState(false)
  const Reviewsget = () => {
    setLoading(true)
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/auth/review/user-rating-review/${GetId()}`
    };
    axios(option)
      .then(e => {
        setReviewsGet(e?.data);
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
      })
  }


  useEffect(() => {
    Reviewsget()
  }, [])
  return (
    <>
      {
        loading ? <Loader /> : null
      }
      <div className="wrapper dashboard-wrapper">
        <div className="d-flex flex-wrap  -xl-nowrap">
          <OBSidebar />


          <div className="page-content">
            <OBHeader />
            <main id="content" className="bg-gray-01 margin-re-top">
              <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
                <div className="mb-6">
                  <h2 className="mb-0 text-heading fs-22 lh-15">Reviews
                  <span className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">{ReviewGet?.length}</span>
                  </h2>
                </div>
                <div className="card border-0 mb-4 header-r-logout">
                 
                   {/*  <h3 className="fs-16 lh-2 text-heading mb-0 d-inline-block pr-4 border-bottom border-primary">{ReviewGet?.length} Reviews</h3> */}
                    {
                      ReviewGet?.map((item,key) => {
                        return (

                          <Accordion expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"

                          >
                            <Typography sx={{ width: '25%', flexShrink: 0 }}>
                              <Avatar name={item?.name} round={true} size="50" color="#529BE8" />
                            </Typography>
                            <Typography sx={{ marginTop: '15px', width: '45%', color: 'text.secondary' }}>{item?.name}</Typography>
                            <Typography sx={{ marginTop: '15px', width: '30%', color: 'text.secondary' }}>{moment(item?.date_published).format('MMM-YYYY')}</Typography>
                            
                           
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography className="">
                            <span className="fw-bold fs-18">Comment: </span>{item?.other_comment}<br />
                            <span className="fw-bold fs-18">Email: </span><a href={`mailto:${item?.email}`} className="mb-0 text-heading border-dark hover-saffron-text lh-1 cl-text-hover">{item?.email}</a>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>

                          )
                        })
                      }
                    
                </div>
                <div className="card border-0 mb-4 header-user-icon ">
                  <div className="card-body p-0 p-sm-8 p-4">
                    
                    {
                      ReviewGet?.map((item,key) => {
                        return (

                          <Accordion expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"

                          >
                            <Typography sx={{ width: '10%', flexShrink: 0 }}>
                              <Avatar name={item?.name} round={true} size="50" color="#529BE8" />
                            </Typography>
                            <Typography sx={{ marginTop: '15px', width: '20%', color: 'text.secondary' }}>{item?.name}</Typography>
                            <Typography sx={{ marginTop: '15px', width: '30%', color: 'text.secondary' }}><a href={`mailto:${item?.email}`} className="mb-0 text-heading border-dark hover-saffron-text lh-1 cl-text-hover">{item?.email}</a></Typography>
                            <Typography sx={{ marginTop: '15px', width: '20%', color: 'text.secondary' }}>{moment(item?.date_published).format('DD-MMM-YYYY')}</Typography>
                            <Typography sx={{ marginTop: '10px', width: '20%', color: 'text.secondary' }}> <ReactStars value={item?.rating} edit={false} size={26}></ReactStars></Typography>
                           
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography className="mx-10">
                              {item?.other_comment}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>

                          )
                        })
                      }
                    </div>
                </div>
              </div>
            </main>
          </div>

        </div>
      </div>





    </>
  )
}
export default OBuserReviews;