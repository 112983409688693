import React, { useEffect, useState } from "react";

import axios from "axios";

import { ApiUrl, GetAgentType, GetDeveloperType, GetId, GetOtherBusinessType, getToken, removeUserSession } from "../../../utils/common";
import { Link } from "react-router-dom";
import Userlist from "./list";

const AdminUsers = () => {



  const [InvoceCount, setInvoceCount] = useState([])
  const [InvoceList, setInvoceList] = useState([])
  const [loading, setLoading] = useState(false)

  return (

    <>
      <main id="content" className="bg-gray-01">
        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 invoice-listing">


          <Userlist />

          {/* <div className="mt-6">
            <nav>
              <ul className="pagination rounded-active justify-content-center">
                <li className="page-item"><a className="page-link" href="#"><i className="far fa-angle-double-left"></i></a>
                </li>
                <li className="page-item"><a className="page-link" href="#">1</a></li>
                <li className="page-item active"><a className="page-link" href="#">2</a></li>
                <li className="page-item d-none d-sm-block"><a className="page-link" href="#">3</a></li>
                <li className="page-item">...</li>
                <li className="page-item"><a className="page-link" href="#">6</a></li>
                <li className="page-item"><a className="page-link" href="#"><i
                  className="far fa-angle-double-right"></i></a></li>
              </ul>
            </nav>
            <div className="text-center mt-2">6-10 of 29 Results</div>
          </div> */}
        </div>
      </main>
    </>
  )
}
export default AdminUsers;