
import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

import axios from "axios";

import { ApiUrl, GetAgentType, GetDeveloperType, GetId, GetOtherBusinessType, getToken, removeUserSession } from "../../../utils/common";
import { useFetch } from '../useFetch';


export default function SubscriberList() {




    const [InvoceCount, setInvoceCount] = useState([])
    const [InvoceList, setInvoceList] = useState([])
    const [loading, setLoading] = useState(false)
    const GetInvoiceList = () => {
        setLoading(true);

    }

    // useEffect(() => {
    //     setLoading(true)

    //     const option = {
    //         method: 'GET', headers: {
    //             'access-control-allow-origin': '*',
    //             'content-type': 'application/json',
    //         },
    //         // data: { "data": data },
    //         url: `${ApiUrl()}/auth/subscription/invoice/all`
    //     };
    //     axios(option)
    //         .then(res => {
    //             setInvoceList(res.data)
    //             setRowData(res.data)

    //             setInvoceCount(InvoceList.length())

    //             setLoading(false)
    //         })
    //         .catch(err => {
    //             setLoading(false)
    //             if (err?.request?.status === 401) {
    //             }
    //         })
    // }, [])
    const  url=`${ApiUrl()}/auth/subscription/invoice/all`

    const { data } = useFetch(url)
  
    const gridRef = useRef(); // Optional - for accessing Grid's API
    const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row

    // Each Column Definition results in one Column.
    const [columnDefs, setColumnDefs] = useState([
        { field: 'customer_id', filter: true },
        {
            field: 'period_start', filter: true, filter: 'agDateColumnFilter',
            // add extra parameters for the date filter
            filterParams: {
                // provide comparator function
                comparator: (filterLocalDateAtMidnight, cellValue) => {
                    const dateAsString = cellValue;
                    if (dateAsString == null) {
                        return 0;
                    }

                    // In the example application, dates are stored as dd/mm/yyyy
                    // We create a Date object for comparison against the filter date
                    const dateParts = dateAsString.split('/');
                    const year = Number(dateParts[2]);
                    const month = Number(dateParts[1]) - 1;
                    const day = Number(dateParts[0]);
                    const cellDate = new Date(year, month, day);

                    // Now that both parameters are Date objects, we can compare
                    if (cellDate < filterLocalDateAtMidnight) {
                        return -1;
                    } else if (cellDate > filterLocalDateAtMidnight) {
                        return 1;
                    }
                    return 0;
                }
            }
        },
        { field: 'id', filter: true, editable: true },

        { field: 'status', filter: true },
        { field: 'customer_email', filter: true },
        { field: 'customer_name', filter: true },
        { field: 'amount_paid', filter: true },



    ]);

    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo(() => ({
        sortable: true
    }));

    // Example of consuming Grid Event
    const cellClickedListener = useCallback(event => {
        
    }, []);


    // // Example load data from sever
    // useEffect(() => {
    //   fetch('https://www.ag-grid.com/example-assets/row-data.json')
    //   .then(result => result.json())
    //   .then(rowData => setRowData(rowData))
    // }, []);

    // Example using Grid's API
    const buttonListener = useCallback(e => {
        gridRef.current.api.deselectAll();
    }, []);


    return (

        <div>

            <main id="content" className="bg-gray-01">
                <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 invoice-listing">
                    <div className="ag-theme-alpine " style={{ width: 1200, height: 600 }}>

                        <AgGridReact
                            ref={gridRef} // Ref for accessing Grid's API

                            rowData={rowData} // Row Data for Rows

                            columnDefs={columnDefs} // Column Defs for Columns
                            defaultColDef={defaultColDef} // Default Column Properties

                            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                            rowSelection='multiple' // Options - allows click selection of rows

                            onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                        />
                    </div>
                </div>
            </main>
        </div>
    )
}
