import React, { useEffect, useState } from "react";
import AgentHeader from "./agentheader";
import AgentSidebar from "../../Components/AgentSidebar/agentsidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ApiUrl, getToken } from "../../utils/common";
import axios from "axios";
import Loader from "../../Components/Loader";

const AgentPropReview = () => {
  // all properties list
  const state = useLocation();
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const [PropertyAll, setPropertyAll] = useState([]);
  console.log(PropertyAll, 'PropertyAll');

  // const AllProperties = () => {
  //   setLoading(true)
  //   const option = {
  //     method: 'GET', headers: {
  //       'access-control-allow-origin': '*',
  //       'content-type': 'multipart/form-data',
  //       'Authorization': `Bearer ${getToken()}`
  //     },
  //     url: `${ApiUrl()}/api/property/user-properties`
  //   };
  //   axios(option)
  //     .then(e => {
  //       setLoading(false)
  //       setPropertyAll(e?.data)
  //     })
  //     .catch(err => {
  //       setLoading(false)
  //     })
  // }
  // useEffect(() => {
  //   AllProperties()
  // }, [])

  const ReviewProperty = () => {
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${getToken()}`
      },
      url:`${ApiUrl()}/api/property/user-properties-reviews`
    };
    axios(option)
    .then(e => {
      setPropertyAll(e?.data)
    })
    .catch(err => {

    })
  }

  useEffect(() => {
    ReviewProperty()
  },[])


  const ShowReviews = (item) => {
    navigate('/agentpropreviewdetails', { state: { item, Type: 'No' } })
  }

  return (
    <>
      {
        loading ? <Loader /> : null
      }

      {/*  {
        loading ? <Loader /> : null
      } */}
      <div className="wrapper dashboard-wrapper">
        <div className="d-flex flex-wrap flex-xl-nowrap">
          <AgentSidebar />
          <div className="page-content">
            <AgentHeader />
            <main id="content" className="bg-gray-01 margin-re-top">
              <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
                <div className="d-flex flex-wrap flex-md-nowrap mb-6">
                  <div className="mr-0 mr-md-auto">
                    <h2 className="mt-5 mb-0 text-heading fs-22 lh-15">Property Name<span
                      className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">{PropertyAll?.length}</span>
                    </h2>

                  </div>
                  <div>
                    <Link to="/agent-reviews" className="btn bg-blue text-white hover-saffron btn-lg">
                      <span className="fs-18">My Reviews</span>
                      <span className="d-inline-block ml-1 fs-20 lh-1"></span>
                    </Link>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-hover border rounded-lg mb-6 bg-white">
                    <thead className="thead-sm thead-black">
                      <tr>
                        <th scope="col" className="col-9 border-top-0 px-6 pt-5 pb-4">Property Name</th>
                        <th scope="col" className="col-3 border-top-0 pt-5 pb-4 header-user-icon">Reviews Count</th>
                        <th scope="col" className="col-3 border-top-0 pt-5 pb-4 header-r-logout">Counts</th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        PropertyAll?.map((item) => {
                          return (
                            <tr className="shadow-hover-xs-2 bg-hover-white" onClick={() => ShowReviews(item)}>
                              <td className="align-middle p-6">
                                <div style={{ cursor: 'pointer' }} className="text-dark font-weight-500 hover-saffron-text pt-1">{item?.property_name}</div>
                              </td>
                              <td className="align-middle">{item?.count}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  )
}
export default AgentPropReview;