import React, { useEffect, useState } from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import Pagination from "../pagination/pagination";
import { ApiUrl, getToken } from "../../utils/common";
// import FSImage from "../../../src/images/business-finder.png";
// import BackImage from "../../../src/images/about-shape.png";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { toast } from "react-toastify";
import Select from "react-select";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Slider from "react-slick/lib/slider";
import PaginationDefine from "../pagination/pagination";
const FinderService = () => {
  const state = useLocation()

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [businessType, setBusinessType] = useState();
  const [search, setSearch] = useState(null);
  const [searchData, setSearchData] = useState([])
  // console.log(searchData, 'searchData');
  const [configData, setConfigData] = useState([])
  const [region, setRegion] = useState('')
  const [featuredDeveloper, setFeaturedDeveloper] = useState([])
  const [featuredAgents, setFeaturedAgents] = useState([])
  const [checkSearchData, setCheckSearchData] = useState(true)
  const [otherBusinessType, setOtherBusinessType] = useState();
  // console.log(otherBusinessType,'otherBusinessType');

  // pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(8);
  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const paginate = pageNumber => setCurrentPage(pageNumber);
  const currentPosts = searchData.slice(indexOfFirstPost, indexOfLastPost);



  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  const SearchFinderService = () => {
    if (!businessType) {
      toast.error("Select Business Type");
      return
    } else {
      setLoading(true)
      setCheckSearchData(false)
      const data = (search != null) ? search : "";
      const name = (region.name != null) ? region.name : "";



      const option = {
        method: 'GET', headers: {
          'access-control-allow-origin': '*',
          'content-type': 'application/json',
        },
        data: data,
        url: `${ApiUrl()}/api/filter-location/?status=${businessType}&loc=${name}&q=${data}`
      };
      axios(option)
        .then(e => {
          setSearchData(e?.data)
          setCheckSearchData(false)
          setLoading(false)
        })
        .catch(err => {
          setCheckSearchData(false)
          setLoading(false)
        })
    }


    console.log(businessType)
    // if (otherBusinessType.length) {
    //   otherBusinessType.map((item) => {
    //     const CheckTypeId = item?.id
    //   })

    // }

    const ValidateField = () => {
      // if (!otherBusinessType) {
      //   toast.error("Please Select Other Business Type")
      //   return false
      // }
      return true
    }


    // if (businessType === "Developer") {
    //   setLoading(true)
    //   setCheckSearchData(false)
    //   const data = (search != null) ? search : "";

    //   const option = {
    //     method: 'GET', headers: {
    //       'access-control-allow-origin': '*',
    //       'content-type': 'application/json',
    //     },
    //     data: data,
    //     url: `${ApiUrl()}/api/filter-location/?status=${businessType}`
    //   };
    //   axios(option)
    //     .then(e => {
    //       setSearchData(e?.data)
    //       setCheckSearchData(false)
    //       setLoading(false)
    //     })
    //     .catch(err => {
    //       setCheckSearchData(false)
    //       setLoading(false)
    //     })
    // }
    // else if (businessType === "Agent") {
    //   setLoading(true)
    //   setCheckSearchData(false)
    //   const data = (search != null) ? search : "";

    //   const option = {
    //     method: 'GET', headers: {
    //       'access-control-allow-origin': '*',
    //       'content-type': 'application/json',
    //     },
    //     data: data,
    //     url: `${ApiUrl()}/auth/user/filter-location-agent?first_name=${data}&city__id=${region}`

    //   };
    //   axios(option)
    //     .then(e => {
    //       setSearchData(e?.data)
    //       setCheckSearchData(false)
    //       setLoading(false)
    //     })
    //     .catch(err => {
    //       setCheckSearchData(false)
    //       setLoading(false)
    //     })
    // }
    // else {
    //   if (!ValidateField()) return;
    //   setLoading(true)
    //   const data = (search != null) ? search : "";
    //   setCheckSearchData(false)
    //   const option = {
    //     method: 'GET', headers: {
    //       'access-control-allow-origin': '*',
    //       'content-type': 'application/json',
    //     },
    //     data: data,
    //     /*  url: `${ApiUrl()}/auth/user/filter-location-other?first_name=${data}&city__id=${region}&business_type__in=${otherBusinessType.map((item => item?.id)).join(',')}` */
    //     url: `${ApiUrl()}/auth/user/filter-location-other?first_name=${data}&city__id=${region}.join(',')}`
    //   };
    //   axios(option)
    //     .then(e => {
    //       setSearchData(e?.data)
    //       setCheckSearchData(false)
    //       setLoading(false)
    //     })
    //     .catch(err => {
    //       setCheckSearchData(false)
    //       setLoading(false)
    //     })
    // }
  }
  var settings = {
    dots: true,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  }




  // featured developer api
  const FeaturedDevelopers = () => {
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'multipart/form-data'
      },
      url: `${ApiUrl()}/auth/user/featured-developers`
    };
    axios(option)
      .then(e => {
        setFeaturedDeveloper(e?.data)
      })
      .catch(err => {
      })
  }

  // featured agents api
  const FeaturedAgents = () => {
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'multipart/form-data',
      },
      url: `${ApiUrl()}/auth/user/featured-agents`
    };
    axios(option)
      .then(e => {
        setFeaturedAgents(e?.data)
      })
      .catch(err => {
      })
  }


  const ConfigDropDown = () => {
    setLoading(true)
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'multipart/form-data',
      },
      url: `${ApiUrl()}/api/property/config-property`
    }
    axios(option)
      .then(e => {
        setLoading(false)
        setConfigData(e?.data)
      })
      .catch(err => {
        setLoading(false)
      })

  }

  useEffect(() => {
    FeaturedDevelopers()
    ConfigDropDown()
    FeaturedAgents()
  }, [])

  // search data fullinfo
  const FullInfo = (item) => {
    navigate('/userdetails', { state: item })
  }

  const ResetSearch = () => {
    window.location.reload()
  }
  return (
    <>

      {
        loading ? <Loader /> : null
      }
      <Header />
      <main id="content">
        <div >
        {/* style={{ backgroundImage: `url(${FSImage})` }} */}
          <section className="op-8 bg-business position-relative pb-15 pt-2 page-title  " >
            <div className="container mt-5" >

              <AnimationOnScroll animateIn="animate__zoomIn">
                <h1 className="fs-32 mb-0 text-white font-weight-600 text-center pt-11 mb-5 lh-17  mt-set-finder-s-banner-heading" data-animate="fadeInDown" >Find Developers & Agents & other Businesses that are transforming the Commercial Real Estate.
                </h1>
                <p className="fs-18 text-white text-center fsfinder-text">(Builders/Developers, Agents/Brokers, Architects, Interior Designers, Lawyers, Property Management Companies, Construction Contractors, Planners, Surveyors, Banks/financial institutions/Mortgage brokers/Private lenders, Professional Photograhers)
                </p>
              </AnimationOnScroll>
            </div>
          </section>
        </div>

        <section>
          <div className="container">

            <div className="shadow-sm mt-n8 bg-white px-6 py-3 shadow-sm-2 rounded-lg form-search-02 position-relative z-index-3">
              <form className=" row d-md-flex flex-wrap align-items-center">
                <div className="col-12">
                  <div className="row">
                    <div className="col-10">
                      <div className="row ">
                        <div className="s-m form-group mb-3 mb-md-0 col-3 mx-w-inherit">
                          <label for="language"
                            className="mb-0 lh-1 text-uppercase fs-14 letter-spacing-093">Business Type</label>
                          <select className="form-control border-0 shadow-none form-control-lg selectpicker add-adjustable-heights bg-transparent-to-color"
                            name="language" id="language" data-style="bg-white pl-0 text-dark rounded-0" onChange={(e) => setBusinessType(e?.target?.value)} value={businessType}>
                            <option>Select Business Type</option>
                            <option>Developer</option>
                            <option>Agent</option>
                            <option>Other Business</option>
                          </select>
                        </div>

                        {
                          businessType === "Other Business" ? <div className="s-m form-group mb-3 mb-md-0 col-3 mx-w-inherit">
                            <label for="language"
                              className="mb-0 lh-1 text-uppercase fs-14 letter-spacing-093">Other Business Type</label>
                            <Select
                              className="form-control border-0 shadow-none form-control-lg selectpicker bg-gray add-adjustable-heights multiple-picker-height-auto"
                              options={configData?.other_business_types}
                              isMulti
                              title="Select"
                              data-style="btn-lg py-2 h-52 "
                              onChange={(e) => setOtherBusinessType(e)}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              value={otherBusinessType}
                              classNamePrefix='select-inner'
                            />
                          </div> : null
                        }

                        <div className="s-m form-group mb-3 mb-md-0 col-3 mx-w-inherit">
                          <label for="region"
                            className="mb-0 lh-1 text-uppercase fs-14 letter-spacing-093">Region/City</label>
                          <Select
                            className="form-control border-0 shadow-none form-control-lg selectpicker add-adjustable-heights "
                            options={configData?.city}
                            // isMulti
                            title="Select"
                            data-style="btn-lg py-2 h-52"
                            onChange={(e) => {
                              setRegion(e)
                              console.log(e.name)
                            }}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            value={region}
                            classNamePrefix='select-inner'
                          />
                        </div>
                        <div className="s-m form-group mb-3 mb-md-0 col-6 mx-w-inherit">
                          <label for="search" className="mb-0 lh-1 text-uppercase fs-14 letter-spacing-093">Search</label>

                          <input type="text" id="search" class="form-control form-control-lg border-0" name="search" onChange={(e) => setSearch(e?.target?.value)} value={search} placeholder="Search For Developers, Agents, Businesses..." />
                        </div>
                      </div>
                    </div>

                    <div className="s-m form-group mb-3 mb-md-0 col-2 mx-w-inherit">
                      <button type="button" style={{ marginTop: '27px' }} className="btn mr-1 bg-blue text-white hover-grey width-re-10" onClick={() => SearchFinderService()}>
                        <span className="fs-18  rounded-0"><i
                          className="fal fa-search"></i></span>
                      </button>
                      <a href="/finderservice" style={{ marginTop: '27px', padding: '8px 15px' }} className="btn advanced-search bg-saffron text-white hover-grey width-re-10">
                        <img src="images/reload.png" />
                      </a>
                    </div>


                  </div>
                </div>


              </form>
              {/*  <form className="d-block d-md-none">
                <div className="d-flex align-items-center">
                  <a className="text-body hover-primary d-inline-block fs-24 lh-1 mr-5" data-bs-toggle="collapse"
                    href="#collapseMobileSearch"
                    role="button" >
                    <i className="fal fa-cog"></i>
                  </a>
                  <div className="input-group">
                    <input type="text"
                      className="form-control pl-0 rounded-0 bg-white"
                      placeholder="Search by agent’s name…" name="search" />
                    <div className="input-group-append ml-0">
                      <span className="fs-18 input-group-text bg-white rounded-0"><i
                        className="fal fa-search"></i></span>
                    </div>
                  </div>
                </div>
                <div className="collapse" id="collapseMobileSearch">
                  <div className="card card-body border-0 px-0">
                    <div className="form-group mb-3">
                      <label for="language-01"
                        className="mb-0 lh-1 text-uppercase fs-14 letter-spacing-093">Language</label>
                      <select className="form-control form-control-sm font-weight-600  shadow-0 bg-white selectpicker"
                        name="language"
                        id="language-01" data-style="bg-white pl-0 text-dark rounded-0">
                        <option>Select</option>
                        <option>English</option>
                        <option>France</option>
                      </select>
                    </div>
                    <div className="form-group mb-3">
                      <label for="region-01"
                        className="mb-0 lh-1 text-uppercase fs-14 letter-spacing-093">Region</label>
                      <select className="form-control font-weight-600 pl-0 bg-white selectpicker form-control-sm"
                        name="region"
                        id="region-01" data-style="bg-white pl-0 text-dark rounded-0">
                        <option>Austin</option>
                        <option>Boston</option>
                        <option>Chicago</option>
                        <option>Denver</option>
                        <option>Los Angeles</option>
                        <option>New York</option>
                        <option>San Francisco</option>
                      </select>
                    </div>
                    <button type="submit" className="btn btn-primary btn-lg btn-block">
                      Search
                    </button>
                  </div>
                </div>
              </form> */}
            </div>
          </div>
        </section>
        <section className="pt-12 pb-13">
        {/* style={{ backgroundImage: `url(${BackImage})` }} */}
          <div className="container">
            <div className="row">
              {
                currentPosts?.map((item) => {
                  // console.log(item)
                  return (

                    <div className="col-sm-6 col-md-4 col-lg-3 mb-6">

                      {/* <Link to='/developerprofile'> */}
                      <Link to={`/user/details/${item.user_id_id}`}>
                        <div className="card shadow-hover-xs-4 agent-3 h-350">
                          <div className="card-header text-center py-3 px-3  text-center bg-gray">
                            <a className="d-inline-block mb-2 h-120">
                            {item?.profile_image ?(<>
                              <img src={`https://dollarperfoot.s3.amazonaws.com/${item?.profile_image }`} className="images-data-finder-services" />
                            </>):(<>
                              <img src="images/favicon-icon.png" className="images-data-finder-services" />
                            </>) }
                             </a>
                            <a
                              className="d-block fs-16 lh-2 text-dark mb-0 font-weight-500 ">{item?.first_name} {item?.last_name}</a>
                            <p className="mb-2">{item?.designation}</p>

                          </div>
                          <div className="card-body text-center pt-2  px-0">
                            {/* <a href="" className="text-body">{item?.email}</a> */}
                            <a className="text-heading font-weight-600 d-block mb-3">Office No: {item?.office_no}</a>
                            {
                              item?.mobile_no?.length ? <a className="text-heading font-weight-600 d-block mb-3">Mobile No: {item?.mobile_no}</a> : null
                            }
                          </div>
                        </div>
                      </Link>
                    </div>

                  )
                })
              }
              <PaginationDefine postPerPage={postPerPage} totalPosts={searchData?.length} paginate={paginate} />



            </div>

            {
              checkSearchData ?
                <div>
                  <div className="mb-10">
                    {
                      featuredDeveloper?.length ? <h2 className="text-heading mb-5">Featured Developers</h2> : null
                    }

                    <Slider {...settings} >
                      {
                        featuredDeveloper?.slice(0, 10)?.map((item) => {
                          return (

                            // <div onClick={() => FullInfo(item)}>
                            <AnimationOnScroll animateIn="animate__fadeInUp">
                              <div className="box" data-animate="fadeInUp">
                                <Link to={`/user/details/${item.user_id}`} >

                                  {/* <div onClick={() => FullInfo(item)} style={{ cursor: 'pointer' }}> */}
                                  <div style={{ cursor: 'pointer' }}>

                                    <div className="card shadow-hover-xs-4 agent-3 h-350 ">
                                      <div className="card-header text-center py-6  text-center bg-gray">
                                        <a className="d-inline-block mb-2  h-120">
                                          <img src={item?.developer_profile?.profile_image || "images/favicon-icon.png"} alt="" className="images-data-finder-services" />
                                        </a>
                                        <a
                                          className="d-block fs-16 lh-2 text-dark mb-0 font-weight-500 ">{item?.name}</a>
                                        <p className="mb-2">{item?.developer_profile?.designation}</p>

                                      </div>
                                      <div className="card-body text-center pt-2  px-0">
                                        {/* <a href="" className="text-body">{item?.email}</a> */}
                                        <a className="text-heading font-weight-600 d-block mb-3">Office No: {item?.developer_profile?.office_no}</a>
                                        {
                                          item?.mobile_no?.length ? <a className="text-heading font-weight-600 d-block mb-3">Mobile No: {item?.developer_profile?.mobile_no}</a> : null
                                        }
                                      </div>
                                    </div>

                                  </div>
                                </Link>

                              </div>
                            </AnimationOnScroll>

                          )
                        })
                      }

                    </Slider>


                  </div>

                  {
                    featuredAgents?.length ? <div>
                      <h2 className="text-heading mb-5">Featured Agents</h2>
                      <div className="mb-10">
                        <Slider {...settings} >
                          {
                            featuredAgents?.map((item) => {
                              return (
                                <AnimationOnScroll animateIn="animate__fadeInDown">
                                  <div className="box mb-5" data-animate="fadeInUp">
                                  <Link to={`/user/details/${item.user_id}`} >
                                   {/*  <div onClick={() => FullInfo(item)} style={{ cursor: 'pointer' }}> */}
                                      <div className="card shadow-hover-xs-4 agent-3 h-350">
                                        <div className="card-header text-center py-6  text-center bg-gray">
                                          <a className="d-inline-block mb-2  h-120">
                                            <img src={item?.agent_profile?.profile_image || "images/favicon-icon.png"} alt="" className="images-data-finder-services" />
                                          </a>
                                          <a
                                            className="d-block fs-16 lh-2 text-dark mb-0 font-weight-500 ">{item?.name}</a>
                                          <p className="mb-2">{item?.agent_profile?.designation}</p>

                                        </div>
                                        <div className="card-body text-center pt-2  px-0">
                                          {/* <a href="" className="text-body">{item?.email}</a> */}
                                          <a className="text-heading font-weight-600 d-block mb-3">Office No: {item?.agent_profile?.office_no}</a>
                                          {
                                            item?.mobile_no?.length ? <a className="text-heading font-weight-600 d-block mb-3">Mobile No: {item?.agent_profile?.mobile_no}</a> : null
                                          }
                                        </div>
                                      </div>

                                   {/*  </div> */}
                                  </Link>
                                  </div>
                                </AnimationOnScroll>
                              )
                            })
                          }
                        </Slider>
                      </div>
                    </div> : null
                  }

                </div>
                : null
            }
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}
export default FinderService;