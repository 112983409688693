import React, { useEffect, useState } from 'react'
// import axios from '../../../utils/axios/main'; // importing axios from customAxios
import { Link, useLocation, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
// import { MainApiProtectedVersion } from '../../../utils/axios/requests';
import Loader from '../../Loader';
import { toast } from 'react-toastify';



export default function Userlist() {
  const navigate = useNavigate();
  const state = useLocation() || "";
  const assignedPlanId = state?.state?.item?.id;
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectPlanId, setselectPlanId] = useState()
  /* assign custom plan */
  useEffect(() => {
    if (assignedPlanId) {
      setselectPlanId(assignedPlanId);
    }
  }, [assignedPlanId])

  // const AssignCustomPlan = () => {
  //   if (selectPlanId && selectPlanId !== "null" && selectPlanId !== "undefined") {
  //     const data = {};
  //     data.is_custom_user = true;
  //     data.custom_plan = selectPlanId;
  //     console.log(data)

  //     if (selectedUser.length > 0) {
  //       selectedUser.forEach(async (index) => {
  //         const obj = new MainApiProtectedVersion('', index)
  //         const res = await obj.requstUserUpdateID(data).then((res) => {
  //           const response = res.data
  //           toast.success(" Selected Plan Assign Successfully")
  //         })
  //           .catch(error => {
  //             alert(error)
  //             console.log(error);

  //           })
  //         console.log(index)
  //       });
  //     } else {
  //       toast.warning(" Select user to assign Plan")
  //     }

  //   }
  //   else {
  //     toast.warning(" Select Plan to Assign")
  //   }
  // }
  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: true,

      width: 180,
      valueGetter: (params) =>
        `${params.row.name || ''}`,
    },


    {
      field: 'profile',
      headerName: 'Profile',

      width: 110,
      editable: true,
      valueGetter: (params) =>
        `${params.row.is_developer === true && 'Developer' || params.row.is_agent === true && 'Agent' || params.row.is_otherbusiness === true && 'Other' || params.row.is_superuser === true && 'Admin'}`,
    },

    {
      field: 'email',
      headerName: 'Email',

      width: 200,
      editable: true,
    },



  ];
  const [User, setUserlist] = useState([])
  // useEffect(() => {
  //   async function UserList() {

  //     const obj = new MainApiProtectedVersion()
  //     const res = await obj.requstUserList().then((res) => {
  //       setLoading(false)
  //       setUserlist(res.data.results)

  //     })
  //   }

  //   UserList()

  // }, []);

  return (
    <div>
      {
        loading ? <Loader /> : null
      }

      <Box sx={{ height: 550, width: '100%' }}>
        <DataGrid className='grid-header'
          rows={User}
          columns={columns}
          pageSize={50}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: false }}
          onSelectionModelChange={(ids) => { setSelectedUser(ids) }}
          onRowDoubleClick={(ids => {
            navigate(`${ids.id}`)
          })}
        />
      </Box>
      <button className='btn bg-blue text-white hover-saffron ml-1' type="submit"  >Assign Plan</button>
    </div>
  )
}
