import React, { useEffect, useState } from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import Loader from "../Loader";
import { ApiUrl, GetId, getToken, removeUserSession } from "../../utils/common";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactStars from "react-rating-stars-component";
import Slider from 'react-rangeslider'
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import Avatar from 'react-avatar';
import moment from "moment";
// import BackImage from "../../../src/images/about-shape.png";
import CurrencyFormat from "react-currency-format";
import { Button, Modal } from "react-bootstrap";

import { ImCross } from 'react-icons/im';
import { SignalCellularNullSharp } from "@mui/icons-material";
const DeveloperProfile = () => {
  let count = 0;
  // portfolio states
  const [portFolioData, setPortFolioData] = useState([])
  const [portFolioImg, setPortFolioImg] = useState([])
  const [portFolioVideo, setportFolioVideo] = useState([])
  const [portFolioBroshure, setPortFolioBroshure] = useState([])
  const [portFolioDeleteData, setPortFolioDeleteData] = useState();
  // modal states
  const [show, setShow] = useState(false);
  const OnhideModal = () => setShow(false)

  const [profileData, setProfileData] = useState();

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [userAllProperty, setUserAllProperty] = useState([])

  const [configAllData, setConfigAllData] = useState()
  const [userTeam, setUserTeam] = useState([]);
  const [forSaleFilterData, setForSaleFilterData] = useState([])
  const [forLeaseFilterData, setForLeaseFilterData] = useState([])

  // states user lead module
  const [userLeadName, setUserLeadName] = useState();
  const [userLeadEmail, setUserLeadEmail] = useState();
  const [userLeadPhoneNo, setUserLeadPhoneNo] = useState();
  const [userLeadDescription, setUserLeadDescription] = useState();
  const userIdGet = profileData?.user_id
  // reviews states
  const [reviewStar, setReviewStar] = useState();
  const ratingChanged = (newRating) => {
    setReviewStar(newRating)
  };
  const [reviewName, setReviewName] = useState();
  const [reviewEmail, setReviewEmail] = useState();
  const [reviewDescription, setReviewDescription] = useState()
  const [getReviewData, setGetReviewData] = useState([])
  const [userRatingCount, setUserRatingCount] = useState();

  const [percentageReview, setPercentegeReview] = useState()





  // lead modules validations
  const ValidateLeadInputs = () => {
    if (!userLeadName || userLeadName.trim() === "") {
      toast.error("Please Enter Your Name")
      return false
    }

    if (!userLeadEmail) {
      toast.error("Please Enter Your Email")
      return false;
    }
    return true;
  }
  // get profile api
  const GetProfile = () => {
    setLoading(true);
    const data = "";
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      data: { "data": data },
      url: `${ApiUrl()}/auth/user/developer-profile-get/${GetId()}`
    };

    axios(option)
      .then(e => {
        setLoading(false);
        setProfileData(e?.data?.[0])
      })
      .catch(err => {
        if (err?.request?.status === 401) {
          removeUserSession();
          navigate('/')
        }
        setLoading(false);
      })
  }
  // lead form Submit api
  const SubmitLeadUser = () => {
    if (!ValidateLeadInputs()) return
    const data = {}
    data.name = userLeadName
    data.email = userLeadEmail
    data.phone = userLeadPhoneNo
    data.message = userLeadDescription
    data.user_id = userIdGet

    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      data: JSON.stringify(data),
      url: `${ApiUrl()}/api/lead/user-lead`
    };
    axios(option)
      .then(e => {
        setUserLeadName("")
        setUserLeadEmail("")
        setUserLeadPhoneNo("")
        setUserLeadDescription("")
        if (e?.status === 200) {
          toast.success("Your Message Submit Successfully")
        }
      })
      .catch(err => {
        toast.error(err?.response?.data?.email?.[0])
      })
  }

  // submit review api
  const ValidReviewsFields = () => {
    if (reviewStar == null) {
      toast.error("Please Select Rating Stars")
      return false
    }

    if (!reviewName || reviewName.trim() === "") {
      toast.error("Please Enter Your Name")
      return false
    }

    if (!reviewEmail) {
      toast.error("Please Enter Your Email")
      return false
    }

    if (!reviewDescription) {
      toast.error("Please Enter Your Message")
      return false
    }
    return true
  }

  const SubmitReview = () => {
    if (!ValidReviewsFields()) return;
    const data = {}
    data.rating = reviewStar
    data.name = reviewName
    data.email = reviewEmail
    data.other_comment = reviewDescription
    data.user_id = userIdGet
    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      data: JSON.stringify(data),
      url: `${ApiUrl()}/auth/review/user-rating-review`
    };
    axios(option)
      .then(e => {
        if (e?.status === 200) {
          toast.success("Review Submit Successfully")
          setReviewStar(null)
          setReviewName("")
          setReviewEmail("")
          setReviewDescription("")
        }

      })
      .catch(err => {
        toast.error(err?.response?.data?.email[0])
      })
  }

  const GetUserReview = () => {
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/auth/review/user-rating-review/${userIdGet}`
    };

    axios(option)
      .then(e => {
        setGetReviewData(e?.data)
      })
      .catch(err => {

      })
  }

  // user rating count api
  const UserRatingCount = () => {
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/auth/review/user-rating-count/${userIdGet}`
    };

    axios(option)
      .then(e => {
        setUserRatingCount(e?.data)
      })
      .catch(err => {

      })
  }
  // total averege rating api
  const AverageRating = () => {
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/auth/review/user-rating-star/${userIdGet}`
    };

    axios(option)
      .then(e => {
        setPercentegeReview(e?.data)

      })
      .catch(err => {

      })
  }

  // get user all properties
  const GetUserProperty = () => {
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/api/property/user-properties`
    };
    axios(option)
      .then(e => {
        setUserAllProperty(e?.data)
      })
      .catch(err => {

      })
  }

  // config dropdown api
  const ConfigDropDownData = () => {
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/api/property/config-property`
    };
    axios(option)
      .then(e => {
        setConfigAllData(e?.data)
      })
      .catch(err => {

      })
  }

  // get user team api get
  const GetUserTeam = () => {
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/auth/user/create-multiple-user-profile/${GetId()}`
    }
    axios(option)
      .then(e => {
        setUserTeam(e?.data)
      })
      .catch(err => {

      })
  }

  // for sale property filter api
  const ForSalePropertyFilter = () => {
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/api/property/user-properties-filter?labels__name=For Sale`
    };
    axios(option)
      .then(e => {
        setForSaleFilterData(e?.data)
      })
      .catch(err => {

      })
  }

  // for lease property filter api
  const ForLeasePropertyFilter = () => {
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/api/property/user-properties-filter?labels__name=For Lease`
    };
    axios(option)
      .then(e => {
        setForLeaseFilterData(e?.data)
      })
      .catch(err => {

      })
  }



  const RedirectProperty = (item) => {
    navigate('/developersingleproperty', { state: item })
  }
  const PortFolioGet = () => {
    setLoading(true)
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      url: `${ApiUrl()}/auth/user/business-portfolio/${userIdGet}`
    };
    axios(option)
      .then(e => {
        setLoading(false)
        setPortFolioData(e?.data);
        setPortFolioImg(e?.data?.[1]);
        setportFolioVideo(e?.data?.[2]);
        setPortFolioBroshure(e?.data?.[3]);
      })
      .catch(err => {
        setLoading(false)
      })
  }

  // portfolio delete api

  const PortfolioDelete = () => {
    const option = {
      method: 'DELETE', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/auth/user/business-portfolio/${portFolioDeleteData?.id}`
    };
    axios(option)
      .then(e => {
        if (e?.data?.success === true) {
          setShow(false)
        }
        toast.success(e?.data?.message)
        PortFolioGet()
      })
      .catch(err => {
        if (err?.request?.status === 401) {
          removeUserSession();
          navigate('/')
        }
      })
  }
  useEffect(() => {
    GetProfile();
    if (userIdGet) {
      GetUserReview()
      UserRatingCount()
      AverageRating()
      GetUserProperty()
      ConfigDropDownData()
      GetUserTeam()
      ForSalePropertyFilter()
      ForLeasePropertyFilter()
      PortFolioGet()
    }
  }, [userIdGet])
  const DeleteConfirmation = (item) => {
    setShow(true)
    setPortFolioDeleteData(item)
  }

  const [portfolioImageModal, setPortFolioImageModal] = useState(false)
  const [portfolioImagePath, setPortFolioImagePath] = useState();
  const PortImageShow = (item) => {
    setPortFolioImagePath(item?.media_path)
    setPortFolioImageModal(true)
  }

  return (
    <>

      {
        loading ? <Loader /> : null
      }
      <Header />
      <main id="content ">

        <div className="bg-gray-01" >
        {/* style={{ backgroundImage: `url(${BackImage})` }} */}
          <section className="pb-8">
            <div className="container">
              <div className="row h-100 mt-100">
                <div className="col-lg-8 mt-20">
                  <div className="card border-0 px-6 pt-6 pb-10 add-pb-full">
                    <div className="row h-100">
                      <div className="col-sm-6 mb-6 mb-sm-0 py-5">
                        <img className="rounded-lg border card-img set-height-image-dprofilep" src={profileData?.profile_image || "images/agency-list-01.jpg"}
                          alt="Eco house company" />
                      </div>
                      <div className="col-sm-6 py-5">
                        <div className="card-body p-0">
                          <h2 className="card-title fs-32 lh-15 mb-1 text-dark">
                            {profileData?.first_name} {profileData?.last_name}

                          </h2>
                          <p className="card-text mb-2 text-blue">
                            {profileData?.designation}
                          </p>
                          <ul className="list-inline mb-5">
                            <li className="list-inline-item fs-13 text-heading font-weight-500">{userRatingCount?.round_off}/5</li>

                            <li className="list-inline-item fs-13 text-heading font-weight-500 mr-1">

                              <ul className="list-inline mb-0">
                                <li className="list-inline-item mr-0">
                                  <span className="text-warning fs-12 lh-2"><i
                                    className="fas fa-star"></i></span>
                                </li>
                                <li className="list-inline-item mr-0">
                                  <span className="text-warning fs-12 lh-2"><i
                                    className="fas fa-star"></i></span>
                                </li>
                                <li className="list-inline-item mr-0">
                                  <span className="text-warning fs-12 lh-2"><i
                                    className="fas fa-star"></i></span>
                                </li>
                                <li className="list-inline-item mr-0">
                                  <span className="text-warning fs-12 lh-2"><i
                                    className="fas fa-star"></i></span>
                                </li>
                                <li className="list-inline-item mr-0">
                                  <span className="text-warning fs-12 lh-2"><i
                                    className="fas fa-star"></i></span>
                                </li>
                              </ul>
                            </li>
                            <li className="list-inline-item fs-13 text-gray-light">({getReviewData?.length} reviews)</li>
                          </ul>
                          <hr />
                          <ul className="list-group list-group-no-border">
                            <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                              <span className="col-sm-5 px-1 fw-600 fs-14 text-heading margin-bottom-auto">Company</span>
                              <span className="col-sm-7 px-1 text-heading">{profileData?.company_name}</span>
                            </li>
                            <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                              <span className="col-sm-5 px-1 fw-600 fs-14 text-heading">Office</span>
                              <a href={`tel:${profileData?.office_no}`} className="col-sm-7 px-1 text-heading">{profileData?.office_no}</a>
                            </li>
                            <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                              <span className="col-sm-5 px-1 fw-600 fs-14 text-heading">Mobile</span>
                              <a href={`tel:${profileData?.mobile_no}`} className="col-sm-7 px-1 text-heading">{profileData?.mobile_no}</a>
                            </li>
                            <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                              <span className="col-sm-5 px-1 fw-600 fs-14 text-heading">Email</span>
                              <a href={`mailto:${profileData?.email}`} className="col-sm-7 px-1 text-heading">{profileData?.email}</a>
                            </li>
                            {profileData?.website ?
                            <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                              <span className="col-sm-5 px-1 fw-600 fs-14 text-heading">Web Link</span>
                              <span className="col-sm-7 px-1 text-heading">
                                <a className="text-blue hover-saffron-text create-button-website" target="_blank" href={profileData?.website}>
                                  Web Link
                                </a>
                              </span>
                            </li>:null
                            }
                            <li className="list-group-item d-flex align-items-sm-center lh-214 row  p-0 mb-2">
                              <span className="col-sm-5 px-1 fw-600 fs-14 text-heading margin-bottom-auto">Address</span>
                              <span className="col-sm-7 px-1 text-heading line-height-1-7">{profileData?.street_name}</span>
                            </li>
                            <hr />

                            <li className="list-group-item d-flex align-items-sm-center lh-214 row mb-0 mt-3  p-0 ">

                              <ul className="col-sm-10 list-inline text-gray-lighter m-0 px-1 text-align-end">
                                <li className="list-inline-item m-0">
                                  <a href={profileData?.twitter_url || "#"}
                                    className="w-32px h-32 rounded hover-saffron bg-blue  text-white  d-flex align-items-center justify-content-center border ">
                                    <BsTwitter />
                                  </a>
                                </li>
                                <li className="list-inline-item mr-0 ml-2">
                                  <a href={profileData?.facebook_url || "#"}
                                    className="w-32px h-32 rounded hover-saffron bg-blue  text-white  d-flex align-items-center justify-content-center border ">
                                    <FaFacebookF />
                                  </a>
                                </li>
                                <li className="list-inline-item mr-0 ml-2">
                                  <a href={profileData?.instagram_url || "#"}
                                    className="w-32px h-32 rounded hover-saffron bg-blue  text-white  d-flex align-items-center justify-content-center border ">
                                    <BsInstagram /></a>
                                </li>

                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mt-20">
                  <div className="card border-0">
                    <div className="card-body p-6">
                      <h3 className="card-title fs-16 text-dark mb-6">
                        Contact {profileData?.company_name}
                      </h3>
                      <form>
                        <div className="form-group mb-2 position-add-validate-symbol">
                          <input type="text" className="form-control form-control-lg border-0"
                            placeholder="Your Name" name="name" onChange={(e) => setUserLeadName(e?.target?.value)} value={userLeadName} />
                          <span className="asterisk_input"></span>
                        </div>
                        <div className="form-group mb-2 position-add-validate-symbol">
                          <input type="email" className="form-control form-control-lg border-0"
                            placeholder="Your Email" name="email" onChange={(e) => setUserLeadEmail(e?.target?.value)} value={userLeadEmail} />
                          <span className="asterisk_input"></span>
                        </div>
                        <div className="form-group mb-2">
                          <CurrencyFormat className="form-control form-control-lg border-0"
                            placeholder="Your phone" name="phone" format="+1 (###) ###-####" mask="_" onChange={(e) => setUserLeadPhoneNo(e?.target?.value)} value={userLeadPhoneNo} />
                        </div>
                        <div className="form-group mb-4">
                          <textarea className="form-control form-control-lg border-0" rows="7"
                            placeholder="Message" name="message" onChange={(e) => setUserLeadDescription(e?.target?.value)} value={userLeadDescription}></textarea>
                        </div>
                        <button type="button" className="btn bg-blue text-white hover-saffron btn-lg btn-block" onClick={SubmitLeadUser}>Send Message</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>



          {/* error */}


          <section className="pb-11">
            <div className="container">
              <div className="collapse-tabs mb-10">
                <ul className="nav nav-tabs text-uppercase d-md-inline-flex agent-details-tabs" role="tablist">
                  <li className="nav-item">

                    <a href="#overview" className=" text-blue nav-link active  fs-13 padding-r-5"
                      data-bs-toggle="tab" role="tab">
                      Overview
                    </a>
                  </li>
                  <li className="nav-item ml-0">
                    <a href="#listing" className="nav-link text-blue fs-13 padding-r-5"
                      data-bs-toggle="tab" role="tab">
                      Listings
                    </a>
                  </li>
                  <li className="nav-item ml-0">
                    <a href="#agents" className="nav-link text-blue fs-13 padding-r-5"
                      data-bs-toggle="tab" role="tab">
                      Team
                    </a>
                  </li>
                  <li className="nav-item ml-0">
                    <a href="#reviews" className="nav-link text-blue fs-13 padding-r-5"
                      data-bs-toggle="tab" role="tab">
                      Reviews
                    </a>
                  </li>
                </ul>
                <div className="tab-content shadow-none py-7 px-6 bg-white">
                  <div className="tab-pane tab-pane-parent fade show active" id="overview"
                    role="tabpanel">
                    <div className="card border-0 bg-transparent">
                      <div className="card-body p-0">
                        <h2 className="card-title fs-22 lh-15 mb-1 text-dark">
                          About {profileData?.company_name}
                        </h2>
                        <p className="card-text lh-214 mb-3">{profileData?.description}</p>
                      </div>
                    </div>
                    {
                            portFolioImg?.length ? <h2 className="card-title fs-22 lh-15 mb-2 text-dark">
                              Portfolio
                            </h2> : null
                          }
                          <div className="row">
                      {
                        portFolioImg?.map((item) => {
                          return (
                            <div className="col-md-4 col-xxl-4 mb-3">
                              <div className="card border py-2 px-2 bg-transparent">
                                <div className="card-body p-0">

                                  <div className=" hover-change-image bg-hover-overlay rounded-lg card-img-top" onClick={() => PortImageShow(item)} style={{ cursor: 'pointer' }}>
                                    <img src={item?.media_path}
                                      alt="Home in Metric Way" className="images-portfolio-reveal" />
                                  </div>
                                  <div className="sqfeet-ul-myfavourite mt-5">
                                    <p className="fs-17 fm-nunito lh-214">{item?.description}</p>
                                  </div>
                                  <button className="btn align-items-center justify-content-center bg-blue text-white hover-saffron " onClick={() => DeleteConfirmation(item)}>Delete</button>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }

                      <Modal className="modal-add-contact"
                        show={show} >
                        <Modal.Body>
                          <h3 className="submit-property-modal-heading">Are you sure to delete this media?</h3>
                        </Modal.Body>

                        <Modal.Footer>
                          <Button className="bg-blue text-white hover-saffron" onClick={() => PortfolioDelete()}>
                            Yes
                          </Button>

                          <Button className="bg-blue text-white hover-saffron" onClick={() => OnhideModal()}>
                            No
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      {/* <ImageGallery /> */}
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-6 col-xxl-6 mb-3">
                          {
                            portFolioVideo?.length ? <h2 className="card-title fs-22 lh-15 mb-2 mt-2 text-dark">
                              Video
                            </h2> : null
                          }



                          {
                            portFolioVideo?.map((item) => {
                              return (



                                <div className="card border py-2 px-2 bg-transparent">
                                  <div className="card-body p-0">

                                    {/* <div className=" hover-change-image bg-hover-overlay rounded-lg card-img-top"> */}

                                      <iframe className="iframe-port-div"  src={item?.media_path} title="YouTube video player" frameborder="0" allow="" allowfullscreen></iframe>
                                   {/*  </div> */}
                                    <div className="sqfeet-ul-myfavourite mt-5">
                                      <p className="fs-17 fm-nunito lh-214">{item?.description}</p>
                                    </div>
                                    <button className="btn align-items-center justify-content-center bg-blue text-white hover-saffron " onClick={() => DeleteConfirmation(item)}>Delete</button>
                                  </div>
                                </div>

                              )
                            })
                          }
                        </div>
                        <Modal className="modal-add-contact"
                          show={show} >
                          <Modal.Body>
                            <h3 className="submit-property-modal-heading">Are you sure to delete this media?</h3>
                          </Modal.Body>

                          <Modal.Footer>
                            <Button className="bg-blue text-white hover-saffron" onClick={() => PortfolioDelete()}>
                              Yes
                            </Button>

                            <Button className="bg-blue text-white hover-saffron" onClick={() => OnhideModal()}>
                              No
                            </Button>
                          </Modal.Footer>
                        </Modal>

                        <div className="col-md-6 col-xxl-6 mb-3">
                          {
                            portFolioBroshure?.length ? <h2 className="card-title fs-22 lh-15 mb-2 mt-2 text-dark">
                              Brochure
                            </h2> : null
                          }


                          {
                            portFolioBroshure?.map((item) => {
                              return (

                                <div className="card border py-2 px-2 bg-transparent">
                                  <div className="card-body p-0">

                                   {/*  <div className=" hover-change-image bg-hover-overlay rounded-lg card-img-top"> */}

                                      <iframe className="iframe-port-div" src={item?.media_path} title="YouTube video player" frameborder="0" allow="" allowfullscreen></iframe>
                                   {/*  </div> */}
                                    <div className="sqfeet-ul-myfavourite mt-5">
                                      <p className="fs-17 fm-nunito lh-214">{item?.description}</p>
                                    </div>
                                    <button className="btn align-items-center justify-content-center bg-blue text-white hover-saffron " onClick={() => DeleteConfirmation(item)}>Delete</button>
                                  </div>
                                </div>

                              )
                            })
                          }
                        </div>
                        <Modal className="modal-add-contact"
                          show={show} >
                          <Modal.Body>
                            <h3 className="submit-property-modal-heading">Are you sure to delete this media?</h3>
                          </Modal.Body>

                          <Modal.Footer>
                            <Button className="bg-blue text-white hover-saffron" onClick={() => PortfolioDelete()}>
                              Yes
                            </Button>

                            <Button className="bg-blue text-white hover-saffron" onClick={() => OnhideModal()}>
                              No
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane tab-pane-parent fade" id="listing" role="tabpanel">
                    <div className="card border-0 bg-transparent">
                      <div className="card-body p-0">
                        <h2 className="card-title fs-22 lh-15 mb-3 text-dark">
                          Our Listing
                        </h2>
                        <ul className="nav nav-pills text-capitalize nav-pills-01 mx-n5"
                          role="tablist">
                          <li className="nav-item px-3 px-sm-6 py-1 padding-r-4">
                            <a href="#all"
                              className="nav-link active shadow-none nested-nav-link bg-transparent p-0 letter-spacing-1 custom-link"
                              data-bs-toggle="pill" role="tab">
                              All ({userAllProperty?.length})
                            </a>
                          </li>
                          <li className="nav-item ml-0 px-3 px-sm-6 py-1 padding-r-4">
                            <a href="#sale"
                              className="nav-link shadow-none nested-nav-link bg-transparent p-0 letter-spacing-1 custom-link"
                              data-bs-toggle="pill" role="tab">
                              For Sale ({forSaleFilterData?.length})
                            </a>
                          </li>
                          <li className="nav-item ml-0 px-3 px-sm-6 py-1 padding-r-4">
                            <a href="#rent"
                              className="nav-link shadow-none nested-nav-link bg-transparent p-0 letter-spacing-1 custom-link"
                              data-bs-toggle="pill" role="tab">
                              For Lease ({forLeaseFilterData?.length})
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content shadow-none pt-5 pb-0 px-0 bg-white">
                          <div className="tab-pane nested-tab-pane fade show active" id="all"
                            role="tabpanel">
                            <div className="row">

                              {
                                userAllProperty.map((item) => {


                                  return (
                                    <div className="col-md-6 col-lg-4 mb-7">
                                      <div onClick={() => RedirectProperty(item)}>
                                        <div className="card border-0">
                                          <div className="hover-change-image bg-hover-overlay rounded-lg card-img-top height-add-property">
                                            <img src={item?.featured_image || "images/properties-grid-35.jpg"}

                                              alt="Home in Metric Way" />
                                            <div className="card-img-overlay d-flex flex-column">
                                              <div className="mb-auto">
                                                <span className="badge badge-orange">{item?.labels?.find(i => i?.id)?.name}</span>

                                              </div>

                                            </div>
                                          </div>
                                          <div className="card-body pt-3 px-0 pb-1">
                                            <h2 className="fs-16 mb-1"><a

                                              className="text-dark ">{item?.name}</a>
                                            </h2>
                                            <p className="font-weight-500 text-gray-light mb-0">
                                              {item?.street_name}
                                            </p>
                                            <ul className="list-inline d-flex mb-0 flex-wrap mr-n5">
                                              <li className="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                                data-toggle="tooltip" title="Size">
                                                <img src="images/area.png" className="icon icon-bedroom fs-18 text-primary mr-1 width-32" />
                                                {item?.size} Sq.Ft
                                              </li>
                                            </ul>
                                          </div>
                                          <div className="card-footer bg-transparent px-0 pb-0 pt-2">
                                            <div className="profile-property-price">
                                              {item?.labels?.find(i => i?.id)?.name === "For Lease" ? (
                                                <p className="fs-17 font-weight-bold text-heading mb-0"><CurrencyFormat value={item?.lease_rate} displayType='text' thousandSeparator={true} prefix={'$'} /><span
                                                  className="text-gray-light font-weight-500 fs-14"> / Sq.Ft</span></p>


                                              ) : (
                                                <p className="fs-17 font-weight-bold text-heading mb-0"><CurrencyFormat value={item?.price_per_sqft} displayType='text' thousandSeparator={true} prefix={'$'} /><span
                                                  className="text-gray-light font-weight-500 fs-14"> / Sq.Ft</span></p>

                                              )}

                                            </div>
                                            <ul className="list-inline mb-0">
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>



                          <div className="tab-pane nested-tab-pane fade" id="sale"
                            role="tabpanel">
                            <div className="row">
                              {
                                forSaleFilterData?.map((item) => {
                                  return (
                                    <div className="col-md-6 col-lg-4 mb-7">
                                      <div onClick={() => RedirectProperty(item)} style={{ cursor: 'pointer' }}>
                                        <div className="card border-0">
                                          <div className="hover-change-image bg-hover-overlay rounded-lg card-img-top height-add-property">
                                            <img src={item?.featured_image}

                                              alt="Home in Metric Way" />
                                            <div className="card-img-overlay d-flex flex-column">
                                              <div className="mb-auto">
                                                <span className="badge badge-orange">{item?.labels?.find(i => i?.id)?.name}</span>
                                              </div>

                                            </div>
                                          </div>
                                          <div className="card-body pt-3 px-0 pb-1">
                                            <h2 className="fs-16 mb-1"><a

                                              className="text-dark ">{item?.name}</a>
                                            </h2>
                                            <p className="font-weight-500 text-gray-light mb-0">
                                              {item?.street_name}</p>
                                            <ul className="list-inline d-flex mb-0 flex-wrap mr-n5">
                                              <li className="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                                data-toggle="tooltip" title="Size">
                                                <img src="images/area.png" className="icon icon-bedroom fs-18 text-primary mr-1 width-32" />
                                                {item?.size} Sq.Ft
                                              </li>
                                            </ul>
                                          </div>
                                          <div className="card-footer bg-transparent px-0 pb-0 pt-2">
                                            <div className="profile-property-price">
                                              <p className="fs-17 font-weight-bold text-heading mb-0"><CurrencyFormat value={item?.price_per_sqft} displayType='text' thousandSeparator={true} prefix={'$'} /><span
                                                className="text-gray-light font-weight-500 fs-14"> / Sq.Ft</span></p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                              }

                            </div>
                          </div>
                          <div className="tab-pane nested-tab-pane fade" id="rent"
                            role="tabpanel">
                            <div className="row">
                              {
                                forLeaseFilterData?.map((item) => {
                                  return (
                                    <div className="col-md-6 col-lg-4 mb-7">
                                      <div onClick={() => RedirectProperty(item)} style={{ cursor: 'pointer' }}>
                                        <div className="card border-0">
                                          <div className="hover-change-image bg-hover-overlay rounded-lg card-img-top height-add-property">
                                            <img src={item?.featured_image}

                                              alt="Villa on Hollywood Boulevard" />
                                            <div className="card-img-overlay d-flex flex-column">
                                              <div className="mb-auto">
                                                <span className="badge badge-orange">{item?.labels?.find(i => i?.id)?.name}</span>
                                              </div>

                                            </div>
                                          </div>
                                          <div className="card-body pt-3 px-0 pb-1">
                                            <h2 className="fs-16 mb-1"><a

                                              className="text-dark ">{item?.name}</a>
                                            </h2>
                                            <p className="font-weight-500 text-gray-light mb-0">
                                              {item?.street_name}</p>
                                            <ul className="list-inline d-flex mb-0 flex-wrap mr-n5">
                                              <li className="list-inline-item text-gray font-weight-500 fs-13 d-flex align-items-center mr-5"
                                                data-toggle="tooltip" title="Size">
                                                <img src="images/area.png" className="icon icon-bedroom fs-18 text-primary mr-1 width-32" />
                                                {item?.size} Sq.Ft
                                              </li>
                                            </ul>
                                          </div>
                                          <div className="card-footer bg-transparent px-0 pb-0 pt-2">
                                            <div className="profile-property-price">
                                              <p className="fs-17 font-weight-bold text-heading mb-0"><CurrencyFormat value={item?.lease_rate} displayType='text' thousandSeparator={true} prefix={'$'} /><span
                                                className="text-gray-light font-weight-500 fs-14"> /Sq.Ft</span></p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane tab-pane-parent fade" id="agents" role="tabpanel">
                    <div className="card border-0 bg-transparent">
                      <div className="card-body p-0">
                        <h2 className="card-title fs-22 lh-15 mb-6 text-dark">
                          Our Team
                        </h2>
                        <div className="d-flex flex-wrap listing-agents">
                          {
                            userTeam?.map((item) => {
                              return (
                                <div className="card border-0 px-7 mb-8">
                                  <div className="card-body p-0 text-center">
                                    <a href=""
                                      className="d-block text-center">
                                      <img src={item?.profile_image}
                                        className="mb-2 image-team-user-profile"
                                        alt="Oliver Beddows" />
                                    </a>
                                    <a href=""
                                      className="card-title d-block text-dark fs-16 font-weight-500 lh-2  mb-0">
                                      {item?.first_name} {item?.last_name}
                                    </a>
                                    <p className="card-text">
                                      {item?.designation}
                                    </p>
                                  </div>
                                </div>
                              )
                            })
                          }



                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="tab-pane tab-pane-parent fade" id="reviews" role="tabpanel">
                    <div className="card border-0 bg-transparent">
                      <div className="card-body p-0">
                        <h2 className="fs-22 text-heading lh-15 mb-6">Agency Rating & Reviews </h2>
                        <div className="row mb-6">
                          <div className="col-sm-4 mb-6 mb-sm-0">
                            <h5 className="fs-16 lh-2 text-heading mb-6">
                              Avarage User Rating
                            </h5>
                            <p className="fs-40 text-heading font-weight-bold mb-6 lh-1">{userRatingCount?.round_off}<span
                              className="fs-18 text-gray-light font-weight-normal">/5</span></p>

                            {
                              userRatingCount?.round_off ?
                              <ul className="list-inline">
                            <ReactStars value={userRatingCount?.round_off} edit={false} size={40} isHalf={true} a11y={true} />
                            </ul>:<ReactStars value="0" edit={false} size={40} />
                            }

                          </div>
                          <div className="col-sm-8 pt-3">
                            <h5 className="fs-16 lh-2 text-heading mb-5 mb-0">
                              Rating Breakdown
                            </h5>
                            {/* first rating */}
                            <div className="d-flex align-items-center ">
                              <ul className="list-inline d-flex px-1 mb-0">
                                <ReactStars value={5} edit={false} size={20} classNames="average-rating-stars-property"></ReactStars>
                              </ul>

                              <div className="d-block w-100 px-1">
                                <Slider min={0} max={100} value={percentageReview?.five_star_avg}>

                                </Slider>
                              </div>
                              <div className="text-muted px-1">{percentageReview?.five_star_avg}%</div>
                            </div>

                            {/* second rating */}
                            <div className="d-flex align-items-center ">
                              <ul className="list-inline d-flex px-1 mb-0">
                                <ReactStars value={4} edit={false} size={20} classNames="average-rating-stars-property"></ReactStars>
                              </ul>

                              <div className="d-block w-100 px-1">
                                <Slider min={0} max={100} value={percentageReview?.four_star_avg}>

                                </Slider>
                              </div>
                              <div className="text-muted px-1">{percentageReview?.four_star_avg}%</div>
                            </div>

                            {/* third rating */}
                            <div className="d-flex align-items-center ">
                              <ul className="list-inline d-flex px-1 mb-0">
                                <ReactStars value={3} edit={false} size={20} classNames="average-rating-stars-property"></ReactStars>
                              </ul>

                              <div className="d-block w-100 px-1">
                                <Slider min={0} max={100} value={percentageReview?.three_star_avg}>

                                </Slider>
                              </div>
                              <div className="text-muted px-1">{percentageReview?.three_star_avg}%</div>
                            </div>

                            {/* fourth rating */}
                            <div className="d-flex align-items-center ">
                              <ul className="list-inline d-flex px-1 mb-0">
                                <ReactStars value={2} edit={false} size={20} classNames="average-rating-stars-property"></ReactStars>
                              </ul>

                              <div className="d-block w-100 px-1">
                                <Slider min={0} max={100} value={percentageReview?.two_star_avg}>

                                </Slider>
                              </div>
                              <div className="text-muted px-1">{percentageReview?.two_star_avg}%</div>
                            </div>

                            {/* fifth rating */}
                            <div className="d-flex align-items-center ">
                              <ul className="list-inline d-flex px-1 mb-0">
                                <ReactStars value={1} edit={false} size={20} classNames="average-rating-stars-property"></ReactStars>
                              </ul>

                              <div className="d-block w-100 px-1">

                                <Slider min={0} max={100} value={percentageReview?.one_star_avg}>

                                </Slider>
                              </div>
                              <div className="text-muted px-1">{percentageReview?.one_star_avg}%</div>
                            </div>
                          </div>
                        </div>

                        <h3 className="fs-16 lh-2 text-heading mb-6">Reviews</h3>
                        {/* get reviews */}
                        {
                          getReviewData?.map((item) => {
                            return (
                              <div className="row border-bottom mb-6 pb-6 mb-6">
                                <div className="col-md-3 mb-4 mb-md-0">
                                  <div className="media">
                                    <div className=" h-70 mr-2">
                                      <Avatar name={item?.name} round={true} size="50" color="#529BE8" />
                                    </div>
                                    <div className="media-body mt-2">
                                      <p className="fs-16 font-weight-500 text-heading mb-0 lh-15 add-mleft-for-avatar">
                                        {item?.name}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-9">
                                  <div className="d-flex mb-1">
                                    <ul className="list-inline mb-2 mb-lg-0">
                                      <li className="list-inline-item fs-13 text-heading font-weight-500 mr-1">
                                        <ReactStars value={item?.rating} edit={false} size={26}></ReactStars>

                                      </li>
                                    </ul>
                                    <p className="ml-auto mb-0 text-muted">
                                      {moment(item?.created_at).format('DD-MM-YYYY')}
                                    </p>
                                  </div>
                                  <p className="mb-0">{item?.other_comment}</p>
                                </div>
                              </div>
                            )
                          })
                        }
                        <h3 className="fs-16 lh-2 text-heading mb-4">Write A Review</h3>
                        <form>
                          <div className="form-group mb-4 d-flex justify-content-start">
                            <div className="rate-input">
                              {/* review Stars */}
                              <ReactStars
                                count={5}
                                onChange={ratingChanged}
                                size={36}
                                activeColor="#f6b500"
                              />,
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group mb-4">
                                <input placeholder="Your Name"
                                  className="form-control form-control-lg border-0"
                                  type="text" name="name" onChange={(e) => setReviewName(e?.target?.value)} value={reviewName} />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group mb-4">
                                <input type="email" placeholder="Email" name="email"
                                  className="form-control form-control-lg border-0" onChange={(e) => setReviewEmail(e?.target?.value)} value={reviewEmail} />
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-6">
                            <textarea className="form-control border-0" placeholder="Your Review" name="message"
                              rows="5" onChange={(e) => setReviewDescription(e?.target?.value)} value={reviewDescription} ></textarea>
                          </div>
                          <button type="button" className="btn btn-lg bg-blue text-white hover-saffron px-9" onClick={SubmitReview}>Submit</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
       {/*  <div className="bottom-bar-action py-2 px-4 bg-gray-01 position-fixed fixed-bottom d-block d-sm-none">
          <div className="container">
            <div className="row no-gutters mx-n2 mxw-571 mx-auto">
              <div className="col-6 px-2">
                <a href="#modal-messenger" data-bs-toggle="modal"
                  className="btn btn-primary btn-lg btn-block fs-14 px-1 py-3 h-auto lh-13">Send Message</a>
              </div>
              <div className="col-6 px-2">
                <a href="tel:(+84)2388-969-888" className="btn btn-primary btn-lg btn-block fs-14 px-1 py-3 h-auto lh-13">Call</a>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="modal-messenger" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header border-0 pb-0">
                <h4 className="modal-title text-heading" id="exampleModalLabel">Contact Form</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body pb-6">
                <div className="form-group mb-2">
                  <input type="text" className="form-control form-control-lg border-0"
                    placeholder="First Name, Last Name" />
                </div>
                <div className="form-group mb-2">
                  <input type="email" className="form-control form-control-lg border-0" placeholder="Your Email" />
                </div>
                <div className="form-group mb-2">
                  <input type="tel" className="form-control form-control-lg border-0" placeholder="Your phone" />
                </div>
                <div className="form-group mb-2">
                  <textarea className="form-control border-0"
                    rows="4">Hello, I'm interested in Villa Called Archangel</textarea>
                </div>
                <div className="form-group form-check mb-4">
                  <input type="checkbox" className="form-check-input" id="exampleCheck3" />
                  <label className="form-check-label fs-13" htmlFor="exampleCheck3">Egestas fringilla phasellus faucibus
                    scelerisque eleifend donec.</label>
                </div>
                <button type="submit" className="btn btn-primary btn-lg btn-block rounded">Request Info</button>
              </div>
            </div>
          </div>
        </div> */}
      </main >

      <Modal show={portfolioImageModal} className="modal-add-contact property-big-img-modal">
        <div>
          {/* <Modal.Header> */}
          <div className='cross-icon-modal-close'>
            <ImCross onClick={() => setPortFolioImageModal(false)} className="icon-cross-modal" />
          </div>
          {/* </Modal.Header> */}
          <Modal.Body>
            <img src={portfolioImagePath} alt="image" className='image-modal-property-page'></img>

          </Modal.Body>
        </div>
      </Modal>
      <Footer />
    </>
  )
}
export default DeveloperProfile;