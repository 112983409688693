import React from 'react'
import ClubMemberComponent from '../Components/ClubMemberPages/ClubMemberComponent'

function ClubMember() {
  return (
    <>
    <ClubMemberComponent />
    </>
  )
}

export default ClubMember