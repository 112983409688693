import React, { useState, useEffect } from 'react'
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';
import { toast } from "react-toastify";

export default function CouponList(props) {
  const [show, setShow] = useState(false);
  // const [loading, setloading] = useState(false)
  const [coupon, setcoupon] = useState([])
  console.log(coupon, 'couponList')
  const loading = props.toChild

  // async function SubmitDelete(id) {
  //   const obj = new MainApiProtectedVersion('', id)  //get data by id
  //   const res = await obj.requstCouponDelete().then((res) => {

  //     setShow(true)
  //     toast.success("Coupon Deleted Successfull")


  //   })
  //     .catch(error => {
  //       alert(error)
  //     })
  // }

  // useEffect(() => {
  //   // setloading(false)
  //   async function GetCouponList() {
  //     const obj = new MainApiProtectedVersion('')//get all data without Filter
  //     const listData = await obj.requstSubscriptionCouponGet().then((listData) => {
  //       setcoupon(listData.data)

  //     })
  //       .catch(error => {
  //         alert("There are no Coupon List")
  //       })
  //   }
  //   GetCouponList()
  //   /*   axios.get('/auth/subscription/coupon/').then(response => {
  //       setcoupon(response.data)
  
  //     }
  //     )
  //       .catch(error => {
  //         alert(error)
  //       })
  
  //  */
  // }, [loading, show])


  return (
    <div>
      {coupon ? (<>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>id</th>
              <th>First Name</th>
              <th>duration</th>
              <th>percent_off</th>
              <th>Action</th>

            </tr>
          </thead>
          {coupon.map((item, key) => {


            return (<>

              <tbody>

                <tr key={key}>
                  <td>{item.id}</td>
                  <td>{item.display_name}</td>
                  <td>{item.duration}</td>
                  <td>{item.percent_off}</td>
                  <button type="submit" >   delete</button>
                  {/* onClick={() => SubmitDelete(item.id)} */}

                </tr>
              </tbody>


            </>)
          })}

        </Table>

      </>) : (<>

        not yet coupon
      </>)}


    </div>
  )
}
