import React, { useState, useEffect } from 'react';
import Tree from 'react-d3-tree';
import './TreeStyles.css';
import axiosInstance from '../../../interceptor/axiosInstance';

const GraphComponents = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const [treeData, setTreeData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`${BASE_URL}/account/user/tree/`);
        setTreeData(transformToTree(response.data));
      } catch (error) {
        console.error('Error fetching tree data:', error);
      }
    };

    fetchData();
  }, [BASE_URL]);

  const transformToTree = (user) => {
    if (!user) return null;

    return {
      name: `${user.first_name}`,
      attributes: {
        Refer: `${user.unique_code}`,
        Email: `${user.email}`
      },
      children: [
        user.branch_A_user && transformToTree(user.branch_A_user),
        user.branch_B_user && transformToTree(user.branch_B_user),
        user.branch_C_user && transformToTree(user.branch_C_user),
      ].filter(Boolean),
    };
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Copied to clipboard: ' + text);
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };

  const renderCustomNode = ({ nodeData }) => {
    return (
      <div className="custom-node">
        <h4 className="node-title">{nodeData.name}</h4>
        <span 
          className="node-title refer-text" 
          onClick={() => copyToClipboard(nodeData.attributes.Refer)} 
          style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
        >
          {nodeData.attributes.Refer}
        </span>
        <div className="node-branches">
          {nodeData.children?.length ? (
            <span className="branch-info">Branches: {nodeData.children.length}</span>
          ) : (
            <span className="branch-info no-branch">No Branches</span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="tree-container">
      {treeData ? (
        <Tree 
          data={treeData} 
          className="tree" 
          orientation="vertical" 
          renderCustomNode={renderCustomNode}
          nodeSize={{ x: 280, y: 150 }} // Adjust spacing here
        />
      ) : (
        <p>Loading tree data...</p>
      )}
    </div>
  );
};

export default GraphComponents;
