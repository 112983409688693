import React, { useEffect, useState } from 'react';
import axios from 'axios';
import axiosInstance from '../../../interceptor/axiosInstance';


function PendingPaymentComponent() {
  const API_URL = process.env.REACT_APP_API_URL;

  const [paymentStatus, setPaymentStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      try {
        const response = await axiosInstance.get(`${API_URL}/account/payment/status/`);
        setPaymentStatus(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentStatus();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching data: {error.message}</p>;

  return (
    <div className="table-responsive">
      <table className="table table-hover table-striped table-bordered creative-table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Full Name</th>
            <th scope="col">Email</th>
            <th scope="col">500 Status</th>
            <th scope="col">3100 status</th>
            <th scope="col">Status</th>
            <th scope="col">Joined Date</th>
          </tr>
        </thead>
        <tbody>
          {paymentStatus.map((user, index) => (
            <tr key={user.id}>
              <th scope="row">{index + 1}</th>
              <td>{user.first_name || 'N/A'}</td>
              <td>{user.email}</td>
              <td>{user.is_500_amount ? 'Completed' : 'Pending'}</td>
              <td>{user.is_3100_amount ? 'Completed' : 'Pending'}</td>
              <td>{user.is_branch ? 'Completed' : 'Pending'}</td>
              <td>{new Date(user.joined_date).toLocaleDateString()}</td>
              </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PendingPaymentComponent;
