import React from "react";
import Header from "../../Components/header/header";
import Footer from "../../Components/footer/footer";
import AgentSinglePropertyPage from "../../Components/SingleProperty/agentsinglepropertyypage";
const AgentSinglePropPage =()=>{
    return(
        <>
            <Header />
            <AgentSinglePropertyPage />
            <Footer />
        </>
    )
}
export default AgentSinglePropPage;