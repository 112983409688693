import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axiosInstance from '../../interceptor/axiosInstance';

const DashBaord = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [dashboardData, setDashboardData] = useState({
    amount: 0,
    total_pending_payment: 0,
    total_completed_payment: 0,
    total_joined_user: 0,
  });

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axiosInstance.get(`${API_URL}/account/dashboard/count/`);
        setDashboardData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchDashboardData();
  }, []);

  
  return (
    <>
      <main id="content" className="bg-gray-01 margin-re-top-80">
        <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
         

        <div className='row'>
  <div className='col-lg-12'>
    <div className="row">
      <div className="col-lg-6 mb-6">
        <div className="card round-30">
          <Link to="/mlm/amount/transaction">
            <div className="card-body row align-items-center px-6 py-7">
              <div className="col-5">
                <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-light badge-circle">
                  <i className="bi bi-building"></i> {/* Optional: Use an icon library like Bootstrap Icons */}
                </span>
              </div>
              <div className="col-7 text-center">
                <p className="text-blue fs-42 lh-12 mb-0 counterup" data-start="0" data-end="29" data-decimals="0" data-duration="0" data-separator="">{dashboardData.amount}</p>
                <p className='text-blue'>My Total Earning</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="col-lg-6 mb-6">
        <div className="card round-30">
          <Link to="/mlm/completed-payment">
            <div className="card-body row align-items-center px-6 py-7">
              <div className="col-5">
                <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-green badge-circle">
                  <i className="bi bi-person-fill"></i> {/* Another example icon */}
                </span>
              </div>
              <div className="col-7 text-center">
                <p className="text-blue fs-42 lh-12 mb-0 counterup" data-start="0" data-end="1730" data-decimals="0" data-duration="0" data-separator="">{dashboardData.total_completed_payment}</p>
                <p className='text-blue'>Payment Completed Member</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>

    <div className="row">
      <div className="col-lg-6 mb-6">
        <div className="card round-30">
          <Link to="/mlm/member/payment/pending">
            <div className="card-body row align-items-center px-6 py-7">
              <div className="col-4">
                <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-yellow badge-circle">
                  <i className="bi bi-star-fill"></i> {/* Icon for property rating */}
                </span>
              </div>
              <div className="col-8 text-center">
                <p className="text-blue fs-42 lh-12 mb-0 counterup" data-start="0" data-end="329" data-decimals="0" data-duration="0" data-separator="">{dashboardData.total_pending_payment}</p>
                <p className='text-blue'>Payment Pending Member</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="col-lg-6 mb-6">
        <div className="card round-30">
          <Link to="/mlm/all/joined-member">
            <div className="card-body row align-items-center px-6 py-7">
              <div className="col-5">
                <span className="w-83px h-83 d-flex align-items-center justify-content-center fs-36 badge badge-pink badge-circle">
                  <i className="bi bi-people-fill"></i> {/* Icon for leads */}
                </span>
              </div>
              <div className="col-7 text-center">
                <p className="text-blue fs-42 lh-12 mb-0 counterup" data-start="0" data-end="914" data-decimals="0" data-duration="0" data-separator="">{dashboardData.total_joined_user}</p>
                <p className='text-blue'>My Member</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>






          <div className="row">
        <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
          <a href="mlm/graph/"  className="card h-100 text-white" style={{ background: 'linear-gradient(135deg, #ff7e5f, #feb47b)' }}>
            <div className="card-body">
              <h5 className="card-title">Working Graph</h5>
              <p className="card-text">Check your Graph Details</p>
            </div>
          </a>
        </div>

        <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
          <a href="mlm/pending-payment"  className="card h-100 text-white" style={{ background: 'linear-gradient(135deg, #6a11cb, #2575fc)' }}>
            <div className="card-body">
              <h5 className="card-title">Payment Status</h5>
              <p className="card-text">Check Your Payment Status</p>
            </div>
          </a>
        </div>

        <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
            <a href="mlm/non-working-plan/"  className="card h-100 text-white" style={{ background: 'linear-gradient(135deg, #34e89e, #0f3443)' }}>
            <div className="card-body">
              <h5 className="card-title">Non Working Rewards</h5>
              <p className="card-text">check your Nonworking Details</p>
            </div>
          </a>
        </div>

        <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
        <a href="mlm/my-level/" className="card h-100 text-white" style={{ background: 'linear-gradient(135deg, #f7971e, #ffd200)' }}>
            <div className="card-body">
              <h5 className="card-title">Member Ship Rewards</h5>
              <p className="card-text">Check your Working Level</p>
            </div>
          </a>
        </div>

        <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
          <a href="mlm/lottery/"  className="card h-100 text-white" style={{ background: 'linear-gradient(135deg, #00c6ff, #0072ff)' }}>
            <div className="card-body">
              <h5 className="card-title">Crowd Funding</h5>
              <p className="card-text">Donation to Earning</p>
            </div>
          </a>
        </div>

        <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
          <a href="mlm/donation/" className="card h-100 text-white" style={{ background: 'linear-gradient(135deg, #ff6a00, #ee0979)' }}>
            <div className="card-body">
              <h5 className="card-title">Donation</h5>
              <p className="card-text">Company Donation</p>
            </div>
          </a>
        </div>
      </div>


        </div>

      </main>
    </>
  )
}
export default DashBaord;