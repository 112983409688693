import React from 'react'
import Header from '../../Components/header/header'
import Footer from '../../Components/footer/footer'
import LearningComponents from '../Components/LearningComponents'

function LearningPage() {


  return (
    <>
    <Header />
    <hr/>
    < LearningComponents />
    <Footer />  
    </>
  )
}

export default LearningPage