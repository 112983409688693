import React, { useEffect, useState } from "react";
import AgentHeader from "./agentheader";
import { Link } from 'react-router-dom';
import { ApiUrl, getToken } from "../../utils/common";
import axios from "axios";
import Loader from "../../Components/Loader";
import AgentSidebar from "../../Components/AgentSidebar/agentsidebar";
import moment from "moment";
import Avatar from 'react-avatar';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const AgentLeads = () => {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [userLeads, setUserLeads] = useState([])
  const [loading, setLoading] = useState(false)

  // const DateGet = userLeads?.find(i => i?.id)?.date_published?.split('T')?.[0];
  // const TimeGet = userLeads?.find(i => i?.id)?.date_published?.split('T')?.[1]?.split('.')?.[0];
  // const LeadDate = moment(DateGet).format("DD-MM-YYYY");
  // getUser Leads API
  const GetUserLeads = () => {
    setLoading(true)
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/api/lead/user-lead`
    };
    axios(option)
      .then(e => {
        setUserLeads(e?.data)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
      })
  }
  useEffect(() => {
    GetUserLeads()
  }, [])

  return (
    <>
      {
        loading ? <Loader /> : null
      }
      <div className="wrapper dashboard-wrapper">
        <div className="d-flex flex-wrap flex-xl-nowrap">
          <AgentSidebar />
          <div className="page-content">
            <AgentHeader />
            <main id="content" className="bg-gray-01 margin-re-top">
              <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
                <div className="d-flex flex-wrap flex-md-nowrap mb-6">
                  <div className="mr-0 mr-md-auto">
                    <h2 className="mt-5 mb-0 text-heading fs-22 lh-15">Business Leads<span
                      className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">{userLeads?.length}</span></h2>
                  </div>
                  <div>
                    <Link to="/agent-property-leads" className="btn bg-blue text-white hover-saffron btn-lg">
                      <span>Property Leads</span>
                      <span className="d-inline-block ml-1 fs-20 lh-1"></span>
                    </Link>
                  </div>
                </div>
                <div className="card border-0 mb-4 header-r-logout">
                {/* <div className="card-body p-0 p-sm-8 p-4 "> */}
                    {/* lead */}
                    {
                      userLeads.map((item,key) => {
                        return (
                          <Accordion expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header">
                            <Typography sx={{ width: '25%', flexShrink: 0 }}>
                              <Avatar name={item?.name} round={true} size="50" color="#529BE8" />
                            </Typography>
                            <Typography sx={{ marginTop: '15px', width: '75%', color: 'text.secondary' }}>{item?.name}</Typography>
                            {/* <Typography sx={{ marginTop: '15px', width: '30%', color: 'text.secondary' }}><a href={`mailto:${item?.email}`} className="mb-0 text-heading border-dark hover-saffron-text lh-1 cl-text-hover">{item?.email}</a></Typography>
                            <Typography sx={{ marginTop: '10px', width: '20%', color: 'text.secondary' }}> {item?.phone}</Typography> */}
                            {/* <Typography sx={{ marginTop: '15px', width: '20%', color: 'text.secondary' }}>{moment(item?.date_published).format('DD-MMM-YYYY')}</Typography> */}
                            
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography className="
                            ">
                            <span className="fw-bold">Published Date:</span>{moment(item?.date_published).format('DD-MMM-YYYY')}<br />
                            <span className="fw-bold">Message: </span>{item?.message}<br />
                            <span className="fw-bold">Email: </span><a href={`mailto:${item?.email}`} className="mb-0 text-heading border-dark hover-saffron-text lh-1 cl-text-hover">{item?.email}</a><br />
                            <span className="fw-bold">Phone Number:</span>{item?.phone}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        )
                      })
                    }
                 {/*  </div> */}
                  </div>
                  <div className="card border-0 mb-4 header-user-icon">
                  <div className="card-body p-0 p-sm-8 p-4 ">
                    {/* lead */}
                    {
                      userLeads.map((item,key) => {
                        return (
                          <Accordion expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header">
                            <Typography sx={{ width: '10%', flexShrink: 0 }}>
                              <Avatar name={item?.name} round={true} size="50" color="#529BE8" />
                            </Typography>
                            <Typography sx={{ marginTop: '15px', width: '25%', color: 'text.secondary' }}>{item?.name}</Typography>
                            <Typography sx={{ marginTop: '15px', width: '30%', color: 'text.secondary' }}><a href={`mailto:${item?.email}`} className="mb-0 text-heading border-dark hover-saffron-text lh-1 cl-text-hover">{item?.email}</a></Typography>
                            <Typography sx={{ marginTop: '10px', width: '20%', color: 'text.secondary' }}> {item?.phone}</Typography>
                            <Typography sx={{ marginTop: '15px', width: '20%', color: 'text.secondary' }}>{moment(item?.date_published).format('DD-MMM-YYYY')}</Typography>
                            
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography className="mx-10">
                            {item?.message}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </main>
          </div>

        </div>
      </div>

    </>
  )
}
export default AgentLeads;