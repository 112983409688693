import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';

const SignUp = () => {
    const API_URL = process.env.REACT_APP_API_URL
    const [rIsShowPassword, setRIsShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(`${API_URL}/account/register/`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      if (response.status === 201 || response.status === 200) {
        alert('Registration successful! Redirecting to login page...');
        navigate('/login');
      } else {
        alert('Registration failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error('Error response:', error.response);
        alert(`Error: ${error.response.data}`);
      } else {
        // Network or other errors
        alert('An error occurred. Please try again later.');
      }
    }
  };

    return (
        <>
            <div className="form-body">
                <div className="website-logo">
                    <Link to="/">
                        <div className="logo">
                            <img className="logo-size" src="images/dpf-logo.png" alt="HomeID" style={{ width: '150px' }} />
                        </div>
                    </Link>
                </div>
                <div className="img-holder">
                    <div className="bg" style={{ backgroundImage: "url(images/skyline-2.png)" }}></div>
                    <div className="info-holder">

                    </div>
                </div>
                <div className="form-holder">
                    <div className="form-content form-reg-content">
                        <div className="form-items">
                            <h3 className="ti-fs t-aling-c"><span className="pl-name">Sign Up</span></h3>

                            <form onSubmit={handleSubmit(onSubmit)}>
      {/* Full name */}
      <div className="form-group mb-4">
        <label htmlFor="full-name" className="sr-only">Full name</label>
        <div className="input-group input-group-lg">
          <div className="input-group-prepend ">
            <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
              <i className="far fa-address-card"></i>
            </span>
          </div>
          <input 
            type="text" 
            className="form-control border-0 shadow-none fs-13"
            id="full-name" 
            placeholder="Full name" 
            {...register("name", { required: true })}
          />
          {errors.name && <p className="text-danger">Full name is required</p>}
        </div>
      </div>

      {/* Email */}
      <div className="form-group mb-4">
        <label htmlFor="username01" className="sr-only">Email</label>
        <div className="input-group input-group-lg">
          <div className="input-group-prepend ">
            <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
              <i className="far fa-user"></i>
            </span>
          </div>
          <input 
            type="email" 
            className="form-control border-0 shadow-none fs-13"
            id="username01" 
            placeholder="Your email" 
            {...register("email", { required: true })}
          />
          {errors.email && <p className="text-danger">Email is required</p>}
        </div>
      </div>

      {/* Referral code */}
      <div className="form-group mb-4">
        <label htmlFor="referral-code" className="sr-only">Referral Code</label>
        <div className="input-group input-group-lg">
          <div className="input-group-prepend ">
            <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
              <i className="far fa-address-card"></i>
            </span>
          </div>
          <input 
            type="text" 
            className="form-control border-0 shadow-none fs-13"
            id="referral-code" 
            placeholder="Referral Code" 
            {...register("unique_code")}
          />
        </div>
      </div>

      {/* Password */}
      <div className="form-group mb-4">
        <label htmlFor="password01" className="sr-only">Password</label>
        <div className="input-group input-group-lg">
          <div className="input-group-prepend ">
            <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
              <i className="far fa-lock"></i>
            </span>
          </div>
          <input 
            type={rIsShowPassword ? "text" : "password"} 
            className="form-control border-0 shadow-none fs-13"
            id="password01" 
            placeholder="Password" 
            {...register("password", { required: true })}
          />
          <div className="input-group-append">
            <span className="input-group-text bg-gray-01 border-0 text-body fs-18">
              <i className="far fa-eye-slash" onClick={() => setRIsShowPassword(!rIsShowPassword)}></i>
            </span>
          </div>
          {errors.password && <p className="text-danger">Password is required</p>}
        </div>
        <p className="form-text">Password must be at least 8 characters long.</p>
      </div>

      {/* Confirm Password */}
      <div className="form-group mb-4">
        <label htmlFor="password02" className="sr-only">Confirm Password</label>
        <div className="input-group input-group-lg">
          <div className="input-group-prepend ">
            <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
              <i className="far fa-lock"></i>
            </span>
          </div>
          <input 
            type={showConfirmPassword ? "text" : "password"} 
            className="form-control border-0 shadow-none fs-13"
            id="password02" 
            placeholder="Confirm Password" 
            {...register("re_password", { required: true, validate: (value) => value === watch('password') || 'Passwords do not match' })}
          />
          <div className="input-group-append">
            <span className="input-group-text bg-gray-01 border-0 text-body fs-18">
              <i className="far fa-eye-slash" onClick={() => setShowConfirmPassword(!showConfirmPassword)}></i>
            </span>
          </div>
          {errors.re_password && <p className="text-danger">{errors.re_password.message}</p>}
        </div>
      </div>

      <button type="submit" className="btn text-white bg-blue hover-saffron btn-lg btn-block">Sign up</button>
    </form>          

                            <p className="lg-bo">Already have an account? <a href="/login" className="text-blue hover-saffron-text"><u>Log
                                in</u></a></p>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SignUp;