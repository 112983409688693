import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css/animate.min.css";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import 'leaflet/dist/leaflet.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import searchMap from './Components/Map/searchMap';
import { withScriptjs } from 'react-google-maps';
import "react-image-gallery/styles/css/image-gallery.css";
import {Provider} from 'react-redux';
import {store} from './store';


// const MapLoader = withScriptjs(searchMap)

const root = ReactDOM.createRoot(document.getElementById('root'));

// return (
//   <MapLoader
//   googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAqVBbzZmrRV7LzReXe_bvQ4I-PuzP1GQ0&libraries=places"
//   />
// )
root.render(
  // <React.StrictMode>
    <Provider store={store}>


      <App />
    </Provider>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
