
import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../Components/header/header";
import Footer from "../Components/footer/footer";
import { toast } from "react-toastify";
import Planlist from "./foreach";
import { AddId, AgentType, ApiUrl, DeveloperId,GetId, DeveloperType, GetAgentType, GetDeveloperType, GetOtherBusinessType, GetPlanId, GetPubliShableKey, GetSessionId, getToken, OtherBusinessType, PlanId, PubliShableKey, SessionId, setUserSession } from "../utils/common";
import Loader from "../Components/Loader";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import { ImCross } from "react-icons/im";
import { BiErrorCircle } from 'react-icons/bi';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
// import { MainApiProtectedVersion } from "../utils/axios/requests";



const SubPackages = () => {

  const developer = GetDeveloperType();
  const getagent = GetAgentType();
  const getotherbusiness = GetOtherBusinessType();

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [getPlans, setGetPlans] = useState([]);
  // modal input fields
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // login states
  const [loginCheck, setLoginCheck] = useState(false);
  const [loginUserName, setLoginUserName] = useState('');
  const [loginPassWord, setLoginPassword] = useState('');
  const [loginIsShowPassword, setLoginIsShowPassword] = useState(false)

  const [fullName, setFullName] = useState();
  const [userName, setUserName] = useState();
  const [passWord, setPassWord] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [checkPassword, setCheckPassword] = useState(false)
  const [checkConfirmPassword, setCheckConfirmPassword] = useState(false)
  const [checkType, setCheckType] = useState();

  const [dev, setDev] = useState([])
  const [agent, setAgent] = useState([])
  const [other, setother] = useState([])

  // modal failed time
  const [failedShow, setFailedShow] = useState(false)
  const handleFailedShow = () => setFailedShow(true)
  const [CustomUser, setCustomUser] = useState(false)


  // // get subscription packages
  // console.log('dev     ', dev)
  // console.log('agent     ', agent)
  // console.log('other     ', other)
  const id= localStorage.getItem('id') || null;

  // useEffect(() => {
  //   if(id){
      
  //     async function UserList() {
  //       const obj = new MainApiProtectedVersion('',id)
  //       const res = await obj.requstUserID().then((res) => {
  //         // console.log(res.data.data[0]);
  
  //         setCustomUser(res.data.data[0])
          
  
  //       })
  //         .catch(error => {
  //           alert(error)
  //         })
  //     }
  
  //     UserList()
  //   }

  // }, []);

  const SelectedPlan = (item) => {
    setLoading(true)
    const Data = {};
    Data.plan_id = item?.id
    console.log('====================================');
    console.log(Data);
    console.log('====================================');
    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      data: JSON.stringify(Data),
      url: `${ApiUrl()}/auth/subscription/create_checkout_session`
    };
    axios(option)
      .then(e => {
        console.log(e, 'e81');
        setLoading(false)
        if (e?.data?.status === 401) {
          handleFailedShow()
        }
        else if (e?.data?.status == 200) {
          window.open(e.data.session.url, '_blank')
        }
      })
      .catch(err => {
        setLoading(false)
        if (err?.response?.data?.detail) {
          toast.error("Sign up or Login with your existing credentials")
          handleShow()
        }
      })
  }

  const ValidateInput = () => {
    if (!fullName || fullName?.trim() === '') {
      toast.error("Please Enter Your Name")
      return false;
    }

    if (!userName || userName.trim() === '') {
      toast.error("Please Enter Username")
      return false;
    }

    if (!passWord) {
      toast.error("Please Enter Password")
      return false;
    }
    if (!confirmPassword) {
      toast.error("Please Enter Confirm PassWord")
      return false
    }
    return true;
  }

  // signup api
  const UserSignUp = () => {
    if (!ValidateInput()) return;
    const Data = {}
    Data.name = fullName;
    Data.email = userName;
    Data.password = passWord;
    Data.re_password = confirmPassword;
    Data.is_developer = Boolean(checkType?.subscription_plan === "developer_builder");
    Data.is_agent = Boolean(checkType?.subscription_plan === "broker_agent");
    Data.is_otherbusiness = Boolean(checkType?.subscription_plan === "other_business");
    Data.is_google_login = false
    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      data: JSON.stringify(Data),
      url: `${ApiUrl()}/auth/subscription/register`
    };
    axios(option)
      .then(e => {
        AddId(e?.data?.data?.id)
        DeveloperType(e?.data?.data?.is_developer);
        AgentType(e?.data?.data?.is_agent);
        OtherBusinessType(e?.data?.data?.is_otherbusiness);

        if (e?.data?.status === 201) {
          toast.success(e?.data?.success)
          Login()
          handleClose()
        }

        // Login();
      })
      .catch(err => {
        toast.error(err?.response?.data?.email?.[0])
      })
  }


  // token generate api
  const Login = () => {
    const data = {};
    data.email = userName;
    data.password = passWord;

    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      data: JSON.stringify(data),
      url: `${ApiUrl()}/api/token/`

    };
    axios(option)
      .then(e => {
        setUserSession(e?.data?.access)
        LoginApi();
      })
      .catch(err => {
        toast.error(err?.response?.data?.detail)
      })
  }

  // login api
  const LoginApi = () => {
    setLoading(true)
    const data = {}
    data.email = userName;
    data.password = passWord;
    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      data: JSON.stringify(data),
      url: `${ApiUrl()}/auth/user/login`
    };
    axios(option)
      .then(e => {

        setLoading(false)
        handleClose()
        const data = JSON.parse(e?.data?.data || '{}');
        SelectedPlan()
      })
      .catch(err => {
        setLoading(false)
        toast.error(err?.response?.data?.email[0] || err?.response?.data?.error)
      })
  }

  const BackToHomapage = () => {
    navigate('/')
  };

  const ValidateLoginFields = () => {
    if (!loginUserName || loginUserName?.trim() === '') {
      toast.error("Please Enter Your UserName")
      return false;
    }

    if (!loginPassWord) {
      toast.error("Please Enter Your PassWord")
      return false;
    }
    return true
  }

  const tokenLogin = () => {
    if (!ValidateLoginFields()) return;
    const data = {};
    data.email = loginUserName;
    data.password = loginPassWord;

    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
      },
      data: JSON.stringify(data),
      url: `${ApiUrl()}/api/token/`
    };
    axios(option)
      .then(e => {
        setUserSession(e?.data?.access);
        LoginAPiModal();
      })
      .catch(err => {
        toast.error(err?.response?.data?.detail)
        console.log(err, '259');
      })
  }

  const LoginAPiModal = () => {

    const data = {}
    data.email = loginUserName;
    data.password = loginPassWord;
    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      data: JSON.stringify(data),
      url: `${ApiUrl()}/auth/user/login`
    };
    axios(option)
      .then(e => {
        toast.success(e?.data?.success);
        setShow(false)
      })
      .catch(err => {

      })

  }



  // async function SubScriptionPackage() {
  //   setLoading(true);
  //   const obj = new MainApiProtectedVersion();
  //   const res = await obj.requstSubscriptionPlan()
  //   if (res.status = "200") {
  //     // setDev(res.data.plans[1]['developer_builder'])
  //     setGetPlans(res.data?.plans)

  //     res.data.plans.map((value, index, array) => {
  //       if (value.developer_builder) {
  //         setDev(value.developer_builder)
  //       } else if (value.broker_agent) {
  //         setAgent(value.broker_agent)
  //         //  block of code to be executed if the condition1 is false and condition2 is true
  //       } else {
  //         setother(value.other_business)

  //         //  block of code to be executed if the condition1 is false and condition2 is false
  //       }

  //     })

  //     // setAgent(res.data?.plans[2]['broker_agent'])
  //     // setother(res.data?.plans[0]['other_business'])
  //     setLoading(false)
  //   }
  //   else {
  //     console.log('error')

  //   }
  // }



  // useEffect(() => {
  //   window?.scrollTo(0, 0);
  //   SubScriptionPackage()
  // }, []);


  // const TabsCheck = () => {
  //   if (developer) {
  //     setCurrentTab(1);
  //   }
  //   else if (getagent) {
  //     setCurrentTab(2);
  //   }
  //   else if (getotherbusiness) {
  //     setCurrentTab(3)
  //   }
  // }
  // useEffect(() => {
  //   TabsCheck()
  // }, [developer, getagent, getotherbusiness])

  return (
    (
      <>

        {
          loading ? <Loader /> : null
        }

        <Header />
        <main id="content ">
          <section className="pb-4 mt-100 ">

          </section>
          <section className="pack-table">
            <div className="container">
              <h1 className="fs-30 lh-1 mb-0 text-heading font-weight-600 mb-6 ">Subscription Packages</h1>
              <h4 className="mb-5 fs-18 lh-15 text-heading">Choose the package that’s right for your business </h4>
              <div className="ul-icons">

                <ul>
                  <li className="contact-item" >
                    <Link className="contact-link  hover-saffron" to="/contact-us"
                    >Custom Plan</Link>
                  </li>
                </ul>

                {
                  developer || getagent || getotherbusiness ?
                    <Tabs
                      className=""
                    >
                      {
                        developer === 'true' ? <Tab eventKey={1} title="Developer" className="">
                          <Planlist data={dev} SelectedPlan={SelectedPlan} setCheckType={setCheckType} CustomUser={CustomUser.is_custom_user} />
                        </Tab> : getagent === 'true' ? <Tab eventKey={2} title="Agent">
                          <Planlist data={agent} SelectedPlan={SelectedPlan} setCheckType={setCheckType} CustomUser={CustomUser.is_custom_user}/>
                        </Tab> : getotherbusiness === 'true' ? <Tab eventKey={3} title="Other Business">
                          <Planlist data={other} SelectedPlan={SelectedPlan} setCheckType={setCheckType} CustomUser={CustomUser.is_custom_user}/>
                        </Tab> : null
                      }
                    </Tabs>
                    :

                    <Tabs
                      defaultActiveKey="1"
                      id="uncontrolled-tab-example"
                      className="sub-tab"
                    >
                      <Tab eventKey="1" title="Developer" className="">
                        <Planlist data={dev} SelectedPlan={SelectedPlan} setCheckType={setCheckType}  CustomUser={CustomUser.is_custom_user}/>
                      </Tab>
                      <Tab eventKey="2" title="Agent">
                        <Planlist data={agent} SelectedPlan={SelectedPlan} setCheckType={setCheckType} CustomUser={CustomUser.is_custom_user}/>
                      </Tab>
                      <Tab eventKey="3" title="Other Business" >
                        <Planlist data={other} SelectedPlan={SelectedPlan} setCheckType={setCheckType} CustomUser={CustomUser.is_custom_user} />
                      </Tab>
                    </Tabs>
                }



              </div>


              <div className="card-body px-sm-6 shadow-xxs-2 mb-5 padding-both">
                <div className="tab-content pt-1 pb-0 px-0 shadow-none">
                  <Modal show={show} onHide={handleClose} animation={false} className="modal-add-contact">
                    <Modal.Header>
                      <ImCross onClick={handleClose} className="button-cross-modal-add-contact" />
                    </Modal.Header>
                    <Modal.Body>

                      {
                        loginCheck ? <div className="tab-content shadow-none p-0" id="myTabContent">
                          <div className="tab-pane fade show active" id="login" role="tabpanel"
                            aria-labelledby="login-tab">
                            <form className="form">
                              {/* fullname input */}


                              <div className="form-group mb-4">
                                {/* username input */}
                                <label htmlFor="username" className="sr-only">Username</label>
                                <div className="input-group input-group-lg">
                                  <div className="input-group-prepend ">
                                    <span className="input-group-text bg-gray-01 border-0 text-muted fs-18"
                                      id="inputGroup-sizing-lg">
                                      <i className="far fa-user"></i></span>
                                  </div>
                                  <input type="text" className="form-control border-0 shadow-none fs-13"
                                    id="username" name="username"
                                    placeholder="Username / Your email" onChange={(e) => setLoginUserName(e?.target?.value)} value={loginUserName} />
                                </div>
                              </div>
                              <div className="form-group mb-4">
                                {/* password input */}
                                <label htmlFor="password" className="sr-only">Password</label>
                                <div className="input-group input-group-lg">
                                  <div className="input-group-prepend ">
                                    <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                                      <i className="far fa-lock"></i>
                                    </span>
                                  </div>
                                  <input className="form-control border-0 shadow-none fs-13"
                                    id="password" name="password"
                                    placeholder="Password" type={loginIsShowPassword ? 'text' : 'password'} onChange={(e) => setLoginPassword(e?.target?.value)} value={loginPassWord} />
                                  <div className="input-group-append">
                                    <span className="input-group-text bg-gray-01 border-0 text-body fs-18">
                                      <i className="far fa-eye-slash" onClick={() => setLoginIsShowPassword(!loginIsShowPassword)}></i>
                                    </span>
                                  </div>
                                </div>
                              </div>

                              {/* forgot password field */}
                              <div className="d-flex mb-4">
                                <Link to="/forgotpassword" className="d-inline-block ml-auto text-orange fs-15">
                                  Forgot Password?
                                </Link>
                              </div>
                              <button type="button" className="btn bg-blue text-white hover-saffron btn-lg btn-block" onClick={tokenLogin}>Log in</button>
                              {/* <a className="form-text">Create Your Account<span onClick={() => setSignUpCheck(true)} style={{cursor:'pointer', color:'#529BE8'}}>SignUp</span></a> */}
                            </form>

                          </div>
                        </div> : <div className="col-md-12">
                          {/* fullname input */}
                          <div className="form-group mb-4">
                            <label htmlFor="full-name" className="sr-only">Full name</label>
                            <div className="input-group input-group-lg">
                              <div className="input-group-prepend ">
                                <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                                  <i className="far fa-address-card"></i></span>
                              </div>
                              <input type="text" className="form-control border-0 shadow-none fs-13"
                                id="full-name" name="full-name"
                                placeholder="Full name" onChange={(e) => setFullName(e?.target?.value)} value={fullName} />
                            </div>
                          </div>

                          {/* username input */}
                          <div className="form-group mb-4">
                            <label htmlFor="username01" className="sr-only">Username</label>
                            <div className="input-group input-group-lg">
                              <div className="input-group-prepend ">
                                <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                                  <i className="far fa-user"></i></span>
                              </div>
                              <input type="text" className="form-control border-0 shadow-none fs-13"
                                id="username01" name="username01"
                                placeholder="Username / Your email" onChange={(e) => setUserName(e?.target?.value)} value={userName} />
                            </div>
                          </div>

                          {/* password inpu  */}
                          <div className="form-group mb-4">
                            <label htmlFor="password01" className="sr-only">Password</label>
                            <div className="input-group input-group-lg">
                              <div className="input-group-prepend ">
                                <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                                  <i className="far fa-lock"></i>
                                </span>
                              </div>
                              <input type={checkPassword ? "text" : "password"} className="form-control border-0 shadow-none fs-13"
                                id="password01" name="password01"
                                placeholder="Password" onChange={(e) => setPassWord(e?.target?.value)} value={passWord} />
                              <div className="input-group-append">
                                <span className="input-group-text bg-gray-01 border-0 text-body fs-18">
                                  <i className="far fa-eye-slash" onClick={() => setCheckPassword(!checkPassword)}></i>
                                </span>
                              </div>
                            </div>
                            {/* <p className="form-text">Password must be at least 8 characters in length</p> */}
                          </div>


                          {/* confirm password */}
                          <div className="form-group mb-4">
                            <label htmlFor="password02" className="sr-only">Confirm Password</label>
                            <div className="input-group input-group-lg">
                              <div className="input-group-prepend ">
                                <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                                  <i className="far fa-lock"></i>
                                </span>
                              </div>
                              <input type={checkConfirmPassword ? "text" : "password"} className="form-control border-0 shadow-none fs-13"
                                id="password02" name="password02"
                                placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e?.target?.value)} value={confirmPassword} />
                              <div className="input-group-append">
                                <span className="input-group-text bg-gray-01 border-0 text-body fs-18">
                                  <i className="far fa-eye-slash" onClick={() => setCheckConfirmPassword(!checkConfirmPassword)}></i>
                                </span>
                              </div>
                            </div>
                            <p className="form-text">it should be same as above password</p>
                            {/* <a className="nav-item col-sm-3 ml-0 nav-link pr-6 py-4 pl-9 active fs-18" id="login-tab"
                          data-bs-toggle="tab"
                          href="#login"
                          role="tab"
                          aria-controls="login" aria-selected="true">Login</a> */}
                          </div>

                          {/* <a id="login-tab" data-bs-toggle="tab" href="#login" role="tab" aria-controls="login" aria-selected="true" className="form-text">Already You have account Please Login</a> */}

                          <button type="button" className="btn bg-blue text-white hover-saffron btn-lg btn-block" onClick={UserSignUp}>Sign up</button>
                          <a className="form-text">Already have an account? <span onClick={() => setLoginCheck(true)} style={{ cursor: 'pointer', color: '#3DA5B1', textDecoration: 'underline' }}>Sign In</span></a>
                        </div>
                      }

                    </Modal.Body>
                  </Modal>


                  {/* failed time modal */}
                  <Modal show={failedShow} animation={false} className="modal-add-contact">
                    <Modal.Body>
                      <div className="col-md-12">
                        <div className="error-message-img-section"><BiErrorCircle className="error-img-stripe-page" /></div>
                        <h4 className="message-failed-time">Thanks for registering with us. Your payment cannot be completed at this time. We will get back to you shortly.</h4>
                        <button type="button" className="btn bg-blue text-white hover-saffron btn-sub" onClick={BackToHomapage}>Back</button>
                      </div>
                    </Modal.Body>
                  </Modal>



                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </>
    )
  )
}
export default SubPackages;