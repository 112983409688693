import React, { useState } from 'react';
import './DonationPage.css'; // Make sure to create this CSS file for styles

const DonationComponent = () => {
  const [amount, setAmount] = useState('');
  const [customAmount, setCustomAmount] = useState('');
  const [selectedAmount, setSelectedAmount] = useState(null);

  const handleAmountClick = (value) => {
    setAmount(value);
    setSelectedAmount(value);
    setCustomAmount('');
  };

  const handleCustomAmountChange = (e) => {
    setCustomAmount(e.target.value);
    setSelectedAmount(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const finalAmount = customAmount || amount;
    alert(`Thank you for your donation of Rs.${finalAmount}`);
  };

  return (
    <div className="donation-container margin-top-109">
      <header className="donation-header">
        <h1>Make a Difference Today</h1>
        <h1>Donate to Company</h1>
        <p>Your support helps us create a better future!</p>
      </header>

      <section className="donation-amount-section">
        <h2>Select an Amount</h2>
        <div className="donation-amounts">
          {[10, 25, 50, 100].map((value) => (
            <button
              key={value}
              className={`donation-btn ${selectedAmount === value ? 'selected' : ''}`}
              onClick={() => handleAmountClick(value)}
            >
              Rs. {value}
            </button>
          ))}
        </div>
        <div className="custom-amount-container">
          <h3>Or enter a custom amount:</h3>
          <input
            type="number"
            value={customAmount}
            onChange={handleCustomAmountChange}
            placeholder="Enter amount"
          />
        </div>
      </section>

      <section className="donation-goal-section">

        <h2>Our Donation Goal</h2>
        <p>We aim to raise Rs. 10,000 this month. Every contribution counts!</p>
        <div className="progress-bar">
          <div className="progress" style={{ width: '60%' }}></div>
        </div>
        <p>60% of our goal reached!</p>
      </section>

      <form onSubmit={handleSubmit} className="donation-form">
        <button type="submit" className="donate-now-btn">
          Donate Now
        </button>
      </form>

      <footer className="donation-footer">
        <h3>Other Ways to Help</h3>
        <p>Share your donation on social media to inspire others to join the cause!</p>
      </footer>
    </div>
  );
};

export default DonationComponent;
