import React, { useEffect, useState } from "react";
import OBHeader from "./obheader";
import OBSidebar from "../../Components/OBsidebar/obsidebar";
import { Link } from "react-router-dom";
import axios from "axios";
import { ApiUrl, GetId, getToken } from "../../utils/common";
import Loader from "../../Components/Loader";
const OBAreasServiced = () => {

  const [profileData, setProfileData] = useState([]);
  const [loading, setLoading] = useState(false)
  const GetProfiles = () => {
    setLoading(true)
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/auth/user/otherbusiness-profile-get/${GetId()}`
    };
    axios(option)
      .then(e => {
        setLoading(false)
        setProfileData(e?.data?.[0])
      })
      .catch(err => {
        setLoading(false)
      })
  }
  useEffect(() => {
    GetProfiles();
  }, [])
  return (
    <>

      {
        loading ? <Loader /> : null
      }
      <div className="wrapper dashboard-wrapper">
        <div className="d-flex flex-wrap flex-xl-nowrap">
          <OBSidebar />
          <div className="page-content">
            <OBHeader />
            <main id="content" className="bg-gray-01 margin-re-top">
              <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
                <div className="d-flex flex-wrap flex-md-nowrap mb-6">
                  <div className="mr-0 mr-md-auto">
                    <h2 className="mt-5 mb-0 text-heading fs-22 lh-15">Areas Serviced{/* <span
                      className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">{profileData?.area_serviced?.length}</span> */}
                    </h2>

                  </div>
                  {/* <div>
                    <Link to="/agent-update-profile" className="btn btn-primary btn-lg">
                      <span>Edit Profile</span>
                      <span className="d-inline-block ml-1 fs-20 lh-1"></span>
                    </Link>
                  </div> */}
                </div>
                <div className="table-responsive">
                  <table className="table table-hover border rounded-lg mb-6 bg-white">
                    <thead className="thead-sm thead-black">
                      <tr>
                        <th scope="col" className="col-9 border-top-0 px-6 pt-5 pb-4">Areas</th>
                        <th scope="col" className="col-3 border-top-0 pt-5 pb-4">Country</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        profileData?.area_serviced?.map((item) => {
                          return (
                            <tr className="shadow-hover-xs-2 bg-hover-white">
                              <td className="align-middle p-6">
                                <a 
                                  className="text-dark font-weight-500 hover-primary pt-1">{item?.name}</a>
                              </td>
                              <td className="align-middle">{item?.country?.name}</td>
                            </tr>
                          )
                        })
                      }

                    </tbody>
                  </table>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  )
}
export default OBAreasServiced;