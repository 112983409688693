import React, { useState, useEffect } from "react";

const CountdownTimer = ({ startTime, endTime ,onStatusChange}) => {
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [status, setStatus] = useState("waiting"); // 'waiting', 'active', 'ended'

  useEffect(() => {
    const start = new Date(startTime).getTime();
    const end = new Date(endTime).getTime();

    const interval = setInterval(() => {
        const now = Date.now();
  
        if (now < start) {
          setStatus((prevStatus) => {
            if (prevStatus !== "waiting") onStatusChange("waiting");
            return "waiting";
          });
          setTimeRemaining(start - now);
        } else if (now >= start && now < end) {
          setStatus((prevStatus) => {
            if (prevStatus !== "active") onStatusChange("active");
            return "active";
          });
          setTimeRemaining(end - now);
        } else {
          setStatus((prevStatus) => {
            if (prevStatus !== "ended") onStatusChange("ended");
            return "ended";
          });
          clearInterval(interval);
          setTimeRemaining(0);
        }
      }, 1000);
  
      return () => clearInterval(interval); // Cleanup on component unmount
    }, [startTime, endTime, onStatusChange]);

  const formatTime = (ms) => {
    if (ms <= 0) return "0m 0s";
    const totalSeconds = Math.floor(ms / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}m ${seconds}s`;
  };

  return (
    <div className="text-center">
      {status === "waiting" && <p className="fs-22 fw-600">COUNTDOWN HAS NOT STARTED YET.</p>}
      {status === "active" && ( <>
        <p className="fs-22 fw-600 m-0" style={{color:"green"}}>TIME REMAINING </p>
        <span className="fs-32 fw-600">{formatTime(timeRemaining)}</span>
        </>
      )}
      {status === "ended" && <p className="fs-22 fw-600" style={{color:"red"}}>COUNTDOWN ENDED !.</p>}
    </div>
  );
};

export default CountdownTimer;
