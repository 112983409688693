import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AgentSidebar from "../../Components/AgentSidebar/agentsidebar";
import { ApiUrl, getToken, GetDeveloperId, GetId } from "../../utils/common";
import AgentHeader from "./agentheader";
import { useLocation } from 'react-router-dom';
import CurrencyFormat from "react-currency-format";


function AgentTeam() {
    const { state } = useLocation();
    const buttonCheck = (state?.id)

    const navigate = useNavigate();
    // fields
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [designation, setDesignation] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [mobile, setMobile] = useState('');

    const ValidateTeamFields = () => {

        if (!firstName || firstName.trim() === "") {
            toast.error("Please Enter Your First Name")
            return false;
        }
        if (!designation || designation.trim() === "") {
            toast.error("Please Enter Designation")
            return false
        }
        if (!email || email.trim() === "") {
            toast.error("Please Enter Your Email")
            return false;
        }

        if (!phone) {
            toast.error("Please Enter Office Contact Number")
            return false;
        }
        if (!imgFile || !isMyProfile) {
            toast.error("Please Select image")
            return false;
        }
        return true
    }

    const SubmitData = () => {
        if (!ValidateTeamFields()) return;

        const data = {}
        const formData = new FormData()
        formData.append('first_name', firstName)
        formData.append('last_name', lastName)
        formData.append('designation', designation)
        formData.append('office_no', phone)
        formData.append('mobile_no', mobile || '')
        formData.append('email', email)
        if (imgFile) {
            formData.append('profile_image', imgFile);
        }
        formData.append('developer_id', `${GetId()}`);
        const option = {
            method: 'POST', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${getToken()}`
            },
            data: formData,
            url: `${ApiUrl()}/auth/user/create-multiple-user-profile`

        };
        axios(option)
            .then(e => {
                const data = JSON.parse(e?.data?.data || '{}');
                navigate('/agentmymembers')
            })
            .catch(err => {
            })
        // data.user = Id
    }

    // const UpdateFields = () => {
    //     const data = {};
    //     const formData = new FormData();
    //     formData.append('first_name', firstName);
    //     formData.append('last_name', lastName);
    //     formData.append('designation', designation);
    //     formData.append('email', email);
    //     formData.append('office_no', phone);
    //     formData.append('mobile_no', mobile);
    //     formData.append('developer_id', `${GetId()}`)
    //     if (imgFile) {
    //         formData.append('profile_image', imgFile);
    //     }
    //     const option = {
    //         method: 'PUT', headers: {
    //             'access-control-allow-origin': '*',
    //             'content-type': 'multipart/form-data',
    //             'Authorization': `Bearer ${getToken()}`
    //         },
    //         data: formData,
    //         url: `${ApiUrl()}/auth/user/create-multiple-user-profile/${GetId()}`
    //     };

    //     axios(option)
    //         .then(e => {
    //             const data = JSON.parse(e?.data?.data || '{}');
    //             navigate('/agentmymembers');
    //         })
    //         .catch(err => {

    //         })
    // }

    const [imgFile, setImageFile] = useState();
    const [isMyProfile, setIsMyProfile] = useState('');

    const PorfileImgHandler = (e) => {
        const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(2);
        if (size > 2) {
            toast.error("Please select image size less than 2 MB")
            return;
        }
        setIsMyProfile(URL.createObjectURL(e?.target?.files[0]));
        setImageFile(e?.target?.files[0]);
    }
    return (
        <>

            
<div className="wrapper dashboard-wrapper">
        <div className="d-flex flex-wrap flex-xl-nowrap">
          <AgentSidebar />
          <div className="page-content">
            <AgentHeader />
                        <main id="content" className="bg-gray-01 margin-re-top">
                            <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card mb-6">
                                            <div className="card-body px-6 pt-6 pb-5">
                                                <h3 className="card-title mb-0 text-heading fs-22 lh-15">Contact information </h3>

                                                <div className="form-row mx-n4">
                                                    <div className="form-group col-md-6 px-4">
                                                        <label htmlFor="firstName" className="text-heading margin-b-0">First name <span className="star-mandatoryfield">*</span></label>
                                                        <input type="text" className="form-control form-control-lg border-0" id="firstName"
                                                            name="firstname" onChange={(e) => setFirstName(e?.target?.value)} value={firstName} />
                                                    </div>
                                                    <div className="form-group col-md-6 px-4">
                                                        <label htmlFor="lastName" className="text-heading">Last name</label>
                                                        <input type="text" className="form-control form-control-lg border-0" id="lastName"
                                                            name="lastname" onChange={(e) => setLastName(e?.target?.value)} value={lastName} />
                                                    </div>
                                                </div>


                                                <div className="form-group">
                                                    <label htmlFor="role" className="text-heading margin-b-0">Role/Designation <span className="star-mandatoryfield">*</span></label>
                                                    <input type="text" className="form-control form-control-lg border-0" id="role"
                                                        name="role" onChange={(e) => setDesignation(e?.target?.value)} value={designation} />
                                                </div>


                                                <div className="form-group">
                                                    <label htmlFor="email" className="text-heading margin-b-0">Email <span className="star-mandatoryfield">*</span></label>
                                                    <input type="email" className="form-control form-control-lg border-0" id="email"
                                                        name="email" onChange={(e) => setEmail(e?.target?.value)} value={email} />
                                                </div>



                                                <div className="form-row mx-n4">
                                                    <div className="form-group col-md-6 px-4">
                                                        <label htmlFor="phone" className="text-heading margin-b-0">Phone <span className="star-mandatoryfield">*</span></label>
                                                        <CurrencyFormat className="form-control form-control-lg border-0" id="phone"
                                                            name="phone" format="+1 (###) ###-####" mask="_" onChange={(e) => setPhone(e?.target?.value)} value={phone}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-6 px-4 mt-2">
                                                        <label htmlFor="mobile" className="text-heading margin-b-0">Mobile</label>
                                                        <CurrencyFormat className="form-control form-control-lg border-0" id="mobile"
                                                            name="mobile" format="+1 (###) ###-####" mask="_" onChange={(e) => setMobile(e?.target?.value)} value={mobile} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-8 col-xl-12 col-xxl-5 img-uploader-cnt-inf">
                                                    {
                                                        imgFile || isMyProfile ? <img src={isMyProfile} alt="image" className="w-100 add-border-other" /> : null
                                                    }

                                                    <div className="custom-file mt-4 h-auto">

                                                        <input type="file" className="custom-file-input add-pstion-abs" id="customFile" name="file" accept="image/*" onChange={PorfileImgHandler} />
                                                        <label className="btn bg-blue text-white hover-saffron btn-lg btn-block add-pd-label-uploader" htmlFor="customFile">
                                                            <span className="d-inline-block mr-1"><i className="fal fa-cloud-upload"></i></span>Upload
                                                            profile image</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end flex-wrap">
                                    {/* <button className="btn btn-lg bg-hover-white border rounded-lg mb-3">Delete Profile</button> */}
                                    <button className="btn btn-lg bg-blue text-white hover-saffron ml-4 mb-3" onClick={SubmitData}>Save Profile</button>
                                </div>
                            </div>
                        </main>
                    </div>

                </div>
            </div>
        </>
    )
}
export default AgentTeam;