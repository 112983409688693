import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BsFillBookmarkHeartFill } from "react-icons/bs";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DevSidebar from '../../Components/DevSidebar/devsidebar';
import Loader from "../../Components/Loader";
import { ApiUrl, getToken, removeUserSession } from "../../utils/common";
import DevHeader from './devheader';
import CurrencyFormat from "react-currency-format";
// import { MainApiProtectedVersion } from "../../utils/axios/requests";

const DevMyProperty = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [getData, setGetData] = useState([]);
  const [favouriteProperty, setFavouriteProperty] = useState([])
  const [getDropwonData, setGetDropdownData] = useState();
  const [deleteData, setDeleteData] = useState()
  // modal state
  const [show, setShow] = useState(false);
  const OnhideModal = () => setShow(false)
  // get all properties
  // console.log("############################",deleteData);


  // async function GetMyProperties() {
  //   setLoading(true);
  //   const obj = new MainApiProtectedVersion();
  //   const res = await obj.requstGetProperty()
  //   console.log(res)
  //   if (res.status = "200") {
  //     setLoading(false);
  //     setGetData(res.data)
  //     toast.warning("You can add media, floor plans and contacts");

  //   }
  //   else {
  //     setLoading(false);

  //   }
  // }

  // useEffect(() => {
  //   GetMyProperties()
  // }, [])


  const state = useLocation();
  const UploadMediaData = (item) => {
    navigate('/devimageuploader', { state: { item } })
  }

  const UploadFloorPlan = (item) => {
    navigate('/floorplan', { state: { item } })
  }

  const EditProperty = (item) => {
    // navigate('/devaddproperty', { state: { item, getDropwonData: getDropwonData } })
    navigate('/devaddproperty', { state: { item, getDropwonData: getDropwonData } })

  }

  const AddContacts = (item) => {
    navigate('/propertyaddcontact', { state: { item } })
  }

  const FullPropertyPage = (item) => {
    navigate('/developersingleproperty', { state: item })
  }

  // async function PropertyDropdowns() {
  //   setLoading(true);
  //   const obj = new MainApiProtectedVersion();
  //   const res = await obj.requstConfigProperty()
  //   if (res.status = "200") {
  //     console.log(res.data)
  //     // setLoading(false);
  //     setGetDropdownData(res.data)
  //   }
  //   else {
  //     console.log('erro')

  //   }
  // }
  // const PropertyDropdowns = () => {
  //   const data = {}
  //   const option = {
  //     method: 'GET', headers: {
  //       'access-control-allow-origin': '*',
  //       'content-type': 'application/json',
  //     },
  //     url: `${ApiUrl()}/api/property/config-property`
  //   };

  //   axios(option)
  //     .then(e => {
  //       // setGetDropdownData(e?.data)
  //       setGetDropdownData(e?.data)

  //     })
  //     .catch(err => {

  //     });
  // }
  // useEffect(() => {
  //   PropertyDropdowns()
  // }, []);



  // const DeleteProperty = () => {
  //   const data = {}
  //   const option = {
  //     method: 'DELETE', headers: {
  //       'access-control-allow-origin': '*',
  //       'content-type': 'application/json',
  //       'Authorization': `Bearer ${getToken()}`
  //     },
  //     data: data,
  //     url: `${ApiUrl()}/api/property/data/${deleteData?.id}`
  //   };
  //   axios(option)
  //     .then(e => {
  //       if (e?.data === "") {
  //         toast.success("Property Deleted Successfull")
  //         GetMyProperties()
  //         setShow(false)
  //       }
  //     })
  //     .catch(err => {
  //       if (err?.request?.status === 401) {
  //         removeUserSession();
  //         navigate('/')
  //         setShow(false)
  //       }
  //     })
  // }
  // add to favourites api
  const AddToFavourites = (item) => {
    const data = {}
    data.property = item?.id;
    data.user = item?.user?.id

    const option = {
      method: 'POST', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      data: JSON.stringify(data),
      url: `${ApiUrl()}/api/property/cached-properties`
    };
    axios(option)
      .then(e => {
        if (e?.status === 200) {
          toast.success("Added Property To Favourites")
          navigate('/devmyfavourite')
        }
        console.log(e, 'e139');
      })
      .catch(err => {
        console.log(err, 'err142');
      })
  }
  const DeleteConfirmation = (item) => {
    setShow(true);
    setDeleteData(item)
  }

  const FavouritePropertyCheck = () => {
    setLoading(true)
    const option = {
      method: 'GET', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/api/property/cached-properties-id`
    };
    axios(option)
      .then(e => {
        setFavouriteProperty(e?.data)
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      })
  }

  useEffect(() => {
    FavouritePropertyCheck()
  }, [])

  const allIds = favouriteProperty?.map((i) => i?.property);

  const DeleteFavouriteProperty = (item) => {
    const option = {
      method: 'DELETE', headers: {
        'access-control-allow-origin': '*',
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${getToken()}`
      },
      url: `${ApiUrl()}/api/property/cached-properties/${item?.id}`
    };
    axios(option)
      .then((e) => {
        window?.location?.reload();
      })
      .catch((err) => {

      })
  }
  return (
    <>
      {
        loading ? <Loader /> : null
      }
      <div className="wrapper dashboard-wrapper">
        <div className="d-flex flex-wrap flex-xl-nowrap">

          <DevSidebar />
          <div className="page-content">
            <DevHeader />

            <main id="content" className="bg-gray-01 margin-re-top">
              <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10">
                <div className="d-flex flex-wrap flex-md-nowrap mb-6">
                  <div className="mr-0 mr-md-auto">
                    <h2 className="mb-0 text-heading fs-22 lh-15">My Properties
                      <span className="badge badge-white badge-pill text-blue fs-18 font-weight-bold ml-2">{getData?.length}</span>
                    </h2>
                    {/* <p>Lorem ipsum dolor sit amet, consec tetur cing elit. Suspe ndisse suscipit</p> */}
                  </div>
                  <div className="form-inline justify-content-md-end mx-n2">
                    <div className="p-2">

                    </div>

                  </div>
                </div>
                {

                  getData?.map((item, index) => {
                    const isFavourite = allIds?.includes(item?.id);
                    console.log(isFavourite, 'isFavourite');
                    return (
                      <div className="card mb-6 header-r-logout">
                        <div className="card-body p-6">
                          <div className="col-sm-4">
                            <div onClick={() => FullPropertyPage(item)}>
                              <a href="">
                                <img src={item?.featured_image}
                                  alt="Home in Metric Way" />
                              </a>
                            </div>
                            <div className="media-body margin-r-top-5">
                              <a href="" className="text-dark margin-r-top-5">
                                <h5 className="fs-16 mb-1 lh-18"><span className="text-heading font-weight-bold fs-16">Property Name:</span> {item?.name}</h5>
                              </a>
                              <p className="line-hproperty font-weight-500 margin-r-top-5"><span className="text-heading font-weight-bold fs-16">Address:</span> {item?.street_name}</p>
                              <span className="text-heading lh-15 font-weight-bold fs-17 margin-r-top-5">Price:
                                {
                                  item.price_from ? <CurrencyFormat value={item.price_from} displayType='text' thousandSeparator={true} prefix={'$'} /> : <CurrencyFormat value={item.lease_rate} displayType='text' thousandSeparator={true} prefix={'$'} />
                                }


                              </span>
                              {/* <span className="text-gray-light">/month</span> */}
                            </div>
                            <p className="margin-r-top-5">Published Date: {moment(item?.created_at).format('DD-MMM-YYYY')}</p>
                            <div className="margin-r-top-5">
                              <a data-bs-toggle="tooltip" title="Edit" className="d-inline-block fs-18 text-muted   hover-saffron-text" onClick={() => EditProperty(item)}><i
                                className="fal fa-pencil-alt"></i></a>
                              <a data-bs-toggle="tooltip" title="Delete" className="d-inline-block fs-18 ml-1 text-muted hover-saffron-text " onClick={() => DeleteConfirmation(item)}><i
                                className="fal fa-trash-alt"></i></a>
                              <a data-bs-toggle="tooltip" title="Favourite Property"
                                className={`d-inline-block fs-18 ${isFavourite ? '' : 'text-muted'} hover-saffron-text ml-1`}
                                onClick={() => {
                                  if (isFavourite) {
                                    DeleteFavouriteProperty(item)
                                  } else {
                                    AddToFavourites(item)
                                  }
                                }}
                                style={{ color: isFavourite ? '#EE9141' : '#ababab' }}><BsFillBookmarkHeartFill /></a>
                            </div>
                            <button type="button" className="btn bg-blue text-white hover-saffron margin-r-top-5 mr-1" onClick={() => UploadMediaData(item)}>Add Media</button>
                            <button type="button" className="btn bg-blue text-white hover-saffron margin-r-top-5 mr-1" onClick={() => UploadFloorPlan(item)}>Add Floor Plan</button>
                            <button type="button" className="btn bg-blue text-white hover-saffron margin-r-top-5 mr-1" onClick={() => AddContacts(item)}>Add Contacts</button>

                            <Modal className="modal-add-contact"
                              show={show} >
                              <Modal.Body>
                                <h3 className="submit-property-modal-heading">Are you sure to delete this Property?</h3>
                              </Modal.Body>

                              <Modal.Footer>
                                <Button className="bg-blue text-white hover-saffron border-0">
                                {/* onClick={() => DeleteProperty()} */}
                                  Yes
                                </Button>

                                <Button className="bg-blue text-white hover-saffron border-0" onClick={() => OnhideModal()}>
                                  No
                                </Button>
                              </Modal.Footer>
                            </Modal>

                          </div>

                        </div>
                      </div>
                    )
                  })
                }
                <div className="table-responsive header-user-icon">
                  <table className="table table-hover bg-white border rounded-lg">
                    <thead className="thead-sm thead-black">
                      <tr>
                        <th scope="col" className="border-top-0 px-6 pt-5 pb-4" style={{ width: "400px" }}>Listing title</th>
                        <th scope="col" className="border-top-0 pt-5 pb-4">Date Published</th>
                        <th scope="col" className="border-top-0 pt-5 pb-4">Action</th>
                        <th scope="col" className="border-top-0 pt-5 pb-4">Media</th>
                      </tr>
                    </thead>
                    <tbody>
                      {

                        getData?.map((item, index) => {
                          const isFavourite = allIds?.includes(item?.id);
                          console.log(isFavourite, 'isFavourite');
                          return (
                            <tr className=" shadow-hover-xs-2 bg-hover-white">

                              <td className="align-middle pt-6 pb-4 px-3 table-td">
                                <div className="media">
                                  <div className="w-120px mr-4 position-relative">
                                    <div onClick={() => FullPropertyPage(item)}>
                                      <a href="">
                                        <img src={item?.featured_image}
                                          alt="Home in Metric Way" className="image_property" />
                                      </a>
                                    </div>
                                    <span className="badge badge-indigo position-absolute pos-fixed-top">{item?.labels?.find(i => i?.id)?.name}</span>
                                  </div>
                                  <div className="media-body">
                                    <a href="" className="text-dark ">
                                      <h5 className="fs-16 mb-1 lh-18">{item?.name}</h5>
                                    </a>
                                    <p className="line-hproperty font-weight-500">{item?.street_name}</p>
                                    <span className="text-heading lh-15 font-weight-bold fs-17">
                                      {
                                        item.price_from ? <CurrencyFormat value={item.price_from} displayType='text' thousandSeparator={true} prefix={'$'} /> : <CurrencyFormat value={item.lease_rate} displayType='text' thousandSeparator={true} prefix={'$'} />
                                      }


                                    </span>
                                    {/* <span className="text-gray-light">/month</span> */}
                                  </div>
                                </div>
                              </td>
                              <td className="align-middle table-td">{moment(item?.created_at).format('DD-MMM-YYYY')}</td>

                              {/* <td className="align-middle">
                                <span
                                  className="badge text-capitalize font-weight-normal fs-12 badge-yellow">{item?.construction_status?.name}</span>
                              </td> */}
                              {/* <td className="align-middle">2049</td> */}
                              <td className="align-middle table-td">
                                <a data-bs-toggle="tooltip" title="Edit" className="d-inline-block fs-18 text-muted   hover-saffron-text" onClick={() => EditProperty(item)}><i
                                  className="fal fa-pencil-alt"></i></a>
                                <a data-bs-toggle="tooltip" title="Delete" className="d-inline-block fs-18 ml-1 text-muted hover-saffron-text " onClick={() => DeleteConfirmation(item)}><i
                                  className="fal fa-trash-alt"></i></a>
                                <a data-bs-toggle="tooltip" title="Favourite Property"
                                  className={`d-inline-block fs-18 ${isFavourite ? '' : 'text-muted'} hover-saffron-text ml-1`}
                                  onClick={() => {
                                    if (isFavourite) {
                                      DeleteFavouriteProperty(item)
                                    } else {
                                      AddToFavourites(item)
                                    }
                                  }}
                                  style={{ color: isFavourite ? '#EE9141' : '#ababab' }}><BsFillBookmarkHeartFill /></a>
                              </td>

                              <td className="align-middle table-td">
                                {/* <Link to="/devimageuploader"> */}
                                <button type="button" className="btn bg-blue text-white hover-saffron" onClick={() => UploadMediaData(item)}>Add Media</button>

                                <button type="button" className="btn bg-blue text-white hover-saffron ml-1" onClick={() => UploadFloorPlan(item)}>Add Floor Plan</button>

                                <button type="button" className="btn bg-blue text-white hover-saffron ml-1" onClick={() => AddContacts(item)}>Add Contacts</button>
                                {/* </Link> */}


                              </td>

                            </tr>
                          )
                        })
                      }
                      <Modal className="modal-add-contact"
                        show={show} >
                        <Modal.Body>
                          <h3 className="submit-property-modal-heading">Are you sure to delete this Property?</h3>
                        </Modal.Body>

                        <Modal.Footer>
                          <Button className="bg-blue text-white hover-saffron border-0">
                          {/* onClick={() => DeleteProperty()} */}
                            Yes
                          </Button>

                          <Button className="bg-blue text-white hover-saffron border-0" onClick={() => OnhideModal()}>
                            No
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </tbody>
                  </table>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>


    </>
  )
}
export default DevMyProperty;