import React from 'react'
import PendingPaymentComponent from '../Components/CompletedPayment/PendingPaymentComponent';

function PendingPayment() {
 
  return (
    <div className="container  margin-re-top-80">
      <h2 className="text-center mt-10 mb-4">Pending Payment</h2>
      <PendingPaymentComponent />
    </div>
  );
};

export default PendingPayment