import React, { useEffect, useState } from "react";
import Header from "../../Components/header/header";
import Footer from "../../Components/footer/footer";
import { Link } from 'react-router-dom';
import { AnimationOnScroll } from "react-animation-on-scroll";
// import BackLineImage from "../../../src/images/bg_circle.png";
import { ApiUrl } from "../../utils/common";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const AboutUs = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
     // search api
 const Search = () => {
    setLoading(true)
    const data = {}
    // data.lat = Latitude;
    // data.lng = Longitude;
    //  data.location = searchAddress?.formatted_address;
    const option = {
        method: 'POST', headers: {
            'access-control-allow-origin': '*',
            'content-type': 'application/json',
        },
        // data: data,
        url: `${ApiUrl()}/api/property/advance-filter-property`
    };
    axios(option)
        .then(e => {
            setLoading(false)
            // setHomeSearchData(e?.data)
            // console.log(homeSearchData,'homeSearchData237');
            Redirect(e?.data)
        })
        .catch(err => {
            setLoading(false)
        })
}

useEffect(() => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
},[]);

const Redirect = (e) => {
    // console.log(homeSearchData,'homeSearchData245');
    navigate('/propertylist', {state: {footerType:"AllProperty"}})
}
    return (
        <>
            <Header />
            <main id="content " className="mt-100">
                <section className="mb-10">
                {/* style={{ backgroundImage: `url(${BackLineImage})` }} */}
                    <div className="container container-xxl">
                        <div className=" py-11 ">
                            <div className="row">

                                <div className="col-md-6 px-8">
                                    <AnimationOnScroll animateIn="animate__fadeInLeft">
                                        <p className="letter-spacing-263 text-uppercase text-blue mb-3 font-weight-500">About Us</p>
                                        <h2 className=" text-heading mb-3 font-32 ">The investment Service Provide <br />investment Your valuable time and amount then get back extra.</h2>
                                        <p className=" text-black fs-17 fm-nunito text-justify">AimTime Business is a pioneering online platform dedicated to providing individuals with the opportunity to achieve financial independence through passive income. Our innovative approach combines the simplicity of investment with the excitement of a Crowd Funding, offering a unique and rewarding experience.</p>
                                        <p className=" text-black fs-17 fm-nunito text-justify">Ready to start earning passive income? Join AimTime Business and take the first step towards financial freedom. Our dedicated support team is always available to assist you and answer your questions.</p>

                                        {/* <Link to="/propertylist" className="btn text-white bg-blue hover-saffron fs-20" onClick={() => Search()}>Search Properties</Link> */}
                                    </AnimationOnScroll>
                                </div>

                                <div className="col-6 ">
                                    <AnimationOnScroll animateIn="animate__fadeInRight">
                                        <div className="about-img-wrap">
                                            <img className="about-one bg-dark" src="images/about-new.png" />
                                           {/*  <img className="about-two" src="images/uncleji.png" /> */}
                                        </div>
                                    </AnimationOnScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="bg-patten-03 bg-gray-01 pb-13" data-animated-id="3">
                    <div className="container">
                        <div className="card border-0 mt-n13 z-index-3 mb-12">
                            <div className="card-body p-6 px-lg-14 py-lg-10">
                                <p className=" text-uppercase text-blue mb-5 fw-600 text-center fs-22">WHO WE ARE</p>
                                <h2 className="text-heading mb-4 fs-20  text-center lh-22 px-6">Our vision is to create a world where everyone has the power to achieve their financial goals without the constraints of traditional employment. We strive to be the leading platform for passive income, empowering individuals to live their dreams and build a better future.</h2>
<p className="text-center text-black fs-16 fm-nunito">
Our mission is to provide a secure, transparent, and user-friendly platform for individuals to invest and earn passive income. We are committed to fostering a community of like-minded individuals who share our passion for financial freedom. Through our innovative Crowd Funding and exceptional customer support, we aim to exceed the expectations of our members and deliver unparalleled value.</p>
                            </div>
                        </div>
                        {/* <h2 className="text-dark lh-1625 text-center mb-2 fs-22 fs-md-32">Our services</h2>
                        <p className=" text-black fs-16 text-center fm-nunito">We are the only platform for Commercial New Development Projects in Canada & USA. Developers, agents, and other Professionals can list their properties that focus just on  commercial new developments and can have a targeted reach out to the interested buyers.</p>
                            <AnimationOnScroll animateIn="animate__fadeInUp">
                        <div className="row mt-8">
                            <div className="col-md-3 mb-6 mb-lg-0">
                                <div className="card shadow-2 px-3 py-3 h-100 border-0">
                                    <div className="card-img-top d-flex align-items-end justify-content-center">
                                        <span className="text-primary fs-90 lh-1"><img src="images/dev.png" alt="otherbusiness" /></span>
                                    </div>
                                    <div className="card-body px-0 pt-6 pb-0 ">
                                        <h4 className="card-title fs-18 lh-17 text-center text-dark mb-2">Developers</h4>
                                       
                                        <ul>
                                            <li>List New & Upcoming Commercial Projects</li>
                                            <li>Get matched buyers </li>
                                            <li>Get targeted leads</li>
                                            <li>Showcase your team and exclusive agents</li>
                                            <li>Showcase Developer Profile to the audience</li>
                                        </ul>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-3 mb-6 mb-lg-0">
                                <div className="card shadow-2 px-3 py-3 h-100 border-0">
                                    <div className="card-img-top d-flex align-items-end justify-content-center">
                                        <span className="text-primary fs-90 lh-1"><img src="images/agent.png" alt="otherbusiness" /></span>
                                    </div>
                                    <div className="card-body px-0 pt-6 pb-0 ">
                                        <h4 className="card-title fs-18 lh-17 text-center text-dark mb-2">Agents</h4>
                                        
                                        <ul>
                                            <li>List Commercial Projects</li>
                                            <li>Showcase your Service Portfolio </li>
                                            <li>Get matched buyers </li>
                                            <li>Get targeted leads</li>
                                            <li>Be the first to get notified on new commercial projects</li>
                                        </ul>
                                    </div>
                                </div></div>
                                <div className="col-md-3 mb-6 mb-lg-0">
                                <div className="card shadow-2 px-3 py-3 h-100 border-0">
                                    <div className="card-img-top d-flex align-items-end justify-content-center">
                                        <span className="text-primary fs-90 lh-1"><img src="images/ob.png" alt="otherbusiness" /></span>
                                    </div>
                                    <div className="card-body px-0 pt-6 pb-0 ">
                                        <h4 className="card-title fs-18 lh-17 text-center text-dark mb-2">Professionals</h4>
                                        
                                        <ul>
                                            <li>Showcase your Service Portfolio</li>
                                            <li>Get matched buyers </li>
                                            <li>Get targeted leads</li>
                                            <li>Increase your reach out on Dollar Per Foot</li>
                                            <li>Be the first to get notified on new commercial projects</li>
                                        </ul>
                                    </div>
                                </div></div>
                                <div className="col-md-3 mb-6 mb-lg-0">
                                <div className="card shadow-2 px-3 py-3 h-100 border-0">
                                    <div className="card-img-top d-flex align-items-end justify-content-center">
                                        <span className="text-primary fs-90 lh-1"><img src="images/buyers.png" alt="otherbusiness" /></span>
                                    </div>
                                    <div className="card-body px-0 pt-6 pb-0 ">
                                        <h4 className="card-title fs-18 lh-17 text-center text-dark mb-2">Buyers</h4>
                                        
                                        <ul>
                                            <li>Be the first to get notified on new commercial projects</li>
                                            <li>Never miss an upcoming commercial opportunity</li>
                                            <li>Maximize your return by booking at the right time at the right price through Dollar Per Foot</li>
                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="mt-10 text-center">
                        <Link to="/subpackages" className="btn fs-22 text-white bg-blue hover-saffron">Sign in for Subscription Plans</Link>
                        </div>
                        </AnimationOnScroll> */}
                    </div>
                </section>
               

            </main>
            <Footer />
        </>
    )
}
export default AboutUs;



