// import { render } from "@testing-library/react";
import React, { useEffect, useState } from "react";
import { ImCross } from 'react-icons/im';
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader";
import axios from "axios";
import { ApiUrl, getToken, removeUserSession } from "../../utils/common";
import AgentHeader from "./agentheader";
import AgentSidebar from "../../Components/AgentSidebar/agentsidebar";

const AgentMultiImageUploader = () => {

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }
    const { state } = useLocation()
    const PropertyId = state?.item?.id;
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState([]);
    const [imageData, setImageData] = useState([])
    const [selectedVideo, SetSelectedVideo] = useState('');

    const [videoPreview, setVideoPreview] = useState()
    //const sVideo = selectedVideo;
    const [mediaType, setMediaType] = useState();
    const [getDropwonData, setGetDropdownData] = useState();
    const [loading, setLoading] = useState(false)
    const ImageHandler = (e) => {
        const size = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(2);
        if (size > 2) {
            toast.error("Please select image size less than 2 MB")
            return;
        }
        if (e?.target?.files) {
            const fileArray = Array.from(e?.target?.files).map((file) => URL.createObjectURL(file))
            const fileImage = Array.from(e?.target?.files).map((file) => file)


            if (fileArray?.length > 20) {
                toast.error("You Can Maximum 20 Images Send")
                return;
            }

            setSelectedImage((prevImages) => prevImages.concat(fileArray))
            setImageData((prevImages) => prevImages.concat(fileImage))
            Array.from(e?.target?.files).map(
                (file) => URL.revokeObjectURL(file)

            )
        }
    }
    const VideoHandler = (e) => {
        const videoSize = parseFloat(e?.target?.files[0]?.size / (1024 * 1024)).toFixed(20);
        if (videoSize > 20) {
            toast.error("Please select Video size less than 20 MB")
            return;
        }
        SetSelectedVideo(URL.createObjectURL(e?.target?.files[0]))
        setVideoPreview(e?.target?.files[0])
    }
    const renderPhotos = (source) => {

        return source.map((photo) => {
            return (
                <>
                    <div className="previewimgmuploader">
                        <img src={photo} key={photo} />

                        <span className="services-add-preview"
                            onClick={() => onRemove(photo)}><ImCross className="cross-icon-service" /></span>
                    </div>
                </>
            )
        })
    }

    const onRemove = (val) => {
        let arr = [...selectedImage]

        const Index = arr.indexOf(val);
        if (Index > -1) {
            arr.splice(Index, 1);
        }
        setSelectedImage(arr)
    }

    const OnVideoRemove = (val) => {
        SetSelectedVideo()
    }
    // images post API
    const ImageSubmit = (PropertyId) => {
        imageData?.map((item) => {
            const data = {}
            const formData = new FormData()
            formData.append('media_type', 1)

            // formData.append('media_path', fileArray)
            formData.append('property_id', PropertyId);
            if (selectedImage) {
                formData.append('media_path', item)
            }
            const option = {
                method: 'POST', headers: {
                    'access-control-allow-origin': '*',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${getToken()}`
                },
                data: formData,
                url: `${ApiUrl()}/api/property/property-media/${state?.item?.id}`
            };
            axios(option)
                .then(e => {
                    toast.success("Images Added Sucessfully")
                })
                .catch(err => {
                    if (err?.request?.status === 401) {
                        removeUserSession();
                        navigate('/')
                    }
                })
            // navigate('/agentsingleproperty', { state: `${state?.item?.id}` })
            navigate('/agent-properties')
        })
    }

    const VideoSubmit = () => {
        const formData = new FormData();
        formData.append('media_type', 2);
        formData.append('property_id', PropertyId);
        if (selectedVideo) {
            formData.append('media_path', videoPreview);
        }

        const option = {
            method: 'POST', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${getToken()}`
            },
            data: formData,
            url: `${ApiUrl()}/api/property/property-media/${state?.item?.id}`
        };
        axios(option)
            .then(e => {
                toast.success("Video Added Succesfully")
            })
            .catch(err => {
                if (err?.request?.status === 401) {
                    removeUserSession();
                    navigate('/')
                }
            })
        // navigate('/agentsingleproperty', { state: `${state?.item?.id}` })
        navigate('/agent-properties')
    }
    return (
        <>
            {
                loading ? <Loader /> : null
            }
            <div className="wrapper dashboard-wrapper">
                <div className="d-flex flex-wrap flex-xl-nowrap">

                    <AgentSidebar />


                    <div className="page-content">
                        <AgentHeader />

                        <div className="agent-row margin-re-top">
                            <div className="container">
                                <h1 style={{ width: '100%', margin: '0 auto', textAlign: 'center', marginBottom: '20px' }}>Upload Property Images</h1>

                                <div className="col-lg-12">
                                <div className="card mb-6 header-r-logout">
                                        <div className="col-sm-4">
                                            <h2 className="upload-h2-re">upload images</h2>
                                            <div className="edit-prfl btn-sub">Upload Images
                                                <input type="file" className="input-file-profile" multiple accept="image/*" onChange={ImageHandler}></input></div>
                                            <p>Photos must be JPEG or PNG format and Max Size 2MB</p>
                                        </div>
                                    </div>
                                    <div className="card mb-6 header-r-logout">
                                        <div className="col-sm-4 mt-2">
                                            <h2 className="upload-h2-re">upload video</h2>
                                            <div className="edit-prfl btn-sub">Upload Video
                                                <input type="file" name="name" className="input-file-profile" accept="video/*" onChange={VideoHandler} onClick={togglePopup}></input></div>

                                            <p>Video must be mp4 format and Max Size
                                                20MB</p>
                                        </div>
                                    </div>

                                    <div className="card mb-6 header-user-icon">
                                        <div className="card-body p-6">
                                            <div className="row">
                                                <div class="col-lg-6">
                                                    {/* <label htmlFor="type"
                                                        className="text-heading">Media Type</label>
                                                    <select className="form-control border-0 shadow-none form-control-lg selectpicker"
                                                        title="Select" data-style="btn-lg py-2 h-52"
                                                        id="type" name="type" onChange={(e) => setMediaType(e?.target?.value)} value={mediaType}>
                                                        {
                                                            getDropwonData?.mediaType?.map((item, index) => {
                                                                return (
                                                                    <option value={item?.id}>{item?.name}</option>
                                                                )

                                                            })
                                                        }
                                                    </select> */}
                                                    <div class="card mb-6">
                                                        <div class="card-body p-6">
                                                            <h3 class="card-title mb-0 text-heading fs-22 lh-15">Upload Images
                                                                for your property</h3>
                                                            <div class="dropzone upload-file text-center py-5">
                                                                <div class="dz-default dz-message">
                                                                    <span class="upload-icon lh-1 d-inline-block mb-4"><i class="fal fa-cloud-upload-alt"></i></span>
                                                                    <p class="text-heading fs-22 lh-15 mb-4">Browse Property Gallery Images</p>
                                                                    <div className="edit-prfl btn-sub">Upload Images
                                                                        <input type="file" className="input-file-profile" multiple accept="image/*" onChange={ImageHandler}></input></div>
                                                                    <p>Photos must be JPEG or PNG format and Max Size 2MB</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="card mb-6">
                                                        <div class="card-body p-6">
                                                            <h3 class="card-title mb-0 text-heading fs-22 lh-15">Upload Video
                                                                for your property</h3>
                                                            {/* <p class="card-text mb-5">Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit</p> */}
                                                            <div class="dropzone upload-file text-center py-5">
                                                                <div class="dz-default dz-message">


                                                                    <span class="upload-icon lh-1 d-inline-block mb-4"><i
                                                                        class="fal fa-cloud-upload-alt"></i></span>
                                                                    <p class="text-heading fs-22 lh-15 mb-4">Browse Your Video Content
                                                                    </p>
                                                                    <div className="edit-prfl btn-sub">Upload Video
                                                                        <input type="file" name="name" className="input-file-profile" accept="video/*" onChange={VideoHandler} onClick={togglePopup}></input></div>

                                                                    <p>Video must be mp4 format and Max Size
                                                                        20MB</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="finalresult">

                                    {

                                        selectedImage ?


                                            renderPhotos(selectedImage)
                                            : null
                                    }

                                </div>
                                <div className="finalresult">

                                    {
                                        selectedVideo ?
                                            <div className="previewimgmuploader">
                                                <iframe src={selectedVideo} style={{ width: '250px', height: '200px' }}></iframe>
                                                <span className="services-add-preview" onClick={() => OnVideoRemove(selectedVideo)}><ImCross className="cross-icon-service" /></span>
                                            </div>
                                            : null
                                    }

                                </div>

                                <button type="button" className="btn bg-blue text-white hover-saffron btn-sub" onClick={() => {
                                    if (selectedImage?.length || selectedVideo?.length) {
                                        ImageSubmit(PropertyId);
                                        VideoSubmit();
                                    } else {
                                        toast.error("Please Selet Media")
                                    }
                                }}>Submit</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default AgentMultiImageUploader;