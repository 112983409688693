import React from 'react'
import LotteryComponent from '../Components/LotteryPage/LotteryComponent'
import WinningListCard from '../Components/LotteryPage/WinningListCard'

function Lottery() {
  return (
    <>
    
    <LotteryComponent />
    <br />

    <WinningListCard />
    </>
  )
}

export default Lottery