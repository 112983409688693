import React from 'react'
import CompletedPaymentComponent from '../Components/CompletedPayment/CompletedPaymentComponent';

function CompletedPayment() {
  return (
    <>
    <br />
    <div className="container mt-4">
      <h2 className="text-center mb-4">Completed Payment</h2>
      <CompletedPaymentComponent />
    </div>
    </>
  );
};

export default CompletedPayment